import {
   createSlice
} from "@reduxjs/toolkit";

export const videoSlice = createSlice({
   name: 'video',
   initialState: {
      resizable: false, // Static value (cannot be changed) that determines if jitsi element should be resizable or not.
      show: true,
      showBeforeFullscreen: true,
      hovering: false,
      fullscreen: false,
      // width: window ? window.innerWidth * 0.4 : 500,
      width: 550,
      minWidth: 200,
      maxWidth: 800,
      resize: false,
      initialX: null,
      jitsi: null,
      showParticipants: false,
      shareScreenMode: false,
      jitsiKey: 0,
   },
   reducers: {
      updateShow: (state, action) => {
         return {
            ...state,
            show: action.payload,
         }
      },
      updateShowBeforeFullscreen: (state, action) => {
         return {
            ...state,
            showBeforeFullscreen: action.payload,
         }
      },
      updateHovering: (state, action) => {
         return {
            ...state,
            hovering: action.payload,
         }
      },
      updateFullscreen: (state, action) => {
         return {
            ...state,
            fullscreen: action.payload,
         }
      },
      updateWidth: (state, action) => {
         return {
            ...state,
            width: state.width + (() => {
               const mousemoveX = action.payload;
               if (mousemoveX < 0) return Math.abs(mousemoveX);
               return -Math.abs(mousemoveX)
            })(),
         }
      },
      updateResize: (state, action) => {
         return {
            ...state,
            resize: action.payload,
         }
      },
      updateInitialX: (state, action) => {
         return {
            ...state,
            initialX: action.payload,
         }
      },
      updateJitsi: (state, action) => {
         return {
            ...state,
            jitsi: action.payload,
         }
      },
      updateShowParticipants: (state, action) => {
         return {
            ...state,
            showParticipants: action.payload,
         }
      },
      updateShareScreenMode: (state, action) => {
         return {
            ...state,
            shareScreenMode: action.payload,
         }
      },
      updateJitsiKey:(state, action)=> {
         return {
            ...state,
            jitsiKey: state.jitsiKey + 1,
         }
      }
   }
})

export const {
   updateShow,
   updateHovering,
   updateFullscreen,
   updateWidth,
   updateResize,
   updateInitialX,
   updateJitsi,
   updateShowParticipants,
   updateShareScreenMode,
   updateJitsiKey,
   updateShowBeforeFullscreen,
} = videoSlice.actions
export default videoSlice.reducer;