/**
 * How we display the name of the 3Data space users are using.
 */
import { useRoom } from 'net/rooms';
import { TopAppBar, TopAppBarRow, TopAppBarSection, TopAppBarTitle, TopAppBarFixedAdjust } from '@rmwc/top-app-bar';
import Logo from "./Logo";
import { useDispatch, useSelector } from 'react-redux';
import { updateRoom } from "app/room";

export default function PreviewHeader(props) {
   const ENV = useSelector(state => state.env);
   const room = useRoom(props.roomId);
   const dispatch = useDispatch();

   if (room.isLoading || room.isError) {
      document.title = `3Data | ${ENV.VERSION}`;
      return (
         <>
         </>
      )
   }
   document.title = `${room.data.name} | ${ENV.VERSION}`;
   dispatch(updateRoom(room.data));
   return (
      <>
         <TopAppBar dense>
            <TopAppBarRow>
               <TopAppBarSection>
                  <TopAppBarTitle>
                     <Logo />
                  </TopAppBarTitle>
               </TopAppBarSection>
            </TopAppBarRow>
         </TopAppBar>
         <TopAppBarFixedAdjust />
      </>
   );
}

