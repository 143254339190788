import { Draggable } from "react-beautiful-dnd";

export default function PlaceholderTile(props){

  const getStyle = (snapshot, draggableStyle) =>  {
    let styleWithProps;
    if(!snapshot.isDragging){
      styleWithProps = Object.assign({}, {}, {})
    }
    else{
      styleWithProps = Object.assign({}, draggableStyle, {})
    }
    if(snapshot.isDropAnimating){
      styleWithProps.transitionDuration = '0.001s'
    }
    styleWithProps.height = "97%"
    return styleWithProps
  }
  return (
    <Draggable draggableId={`${props.id}-${props.index}`} index={props.index} isDragDisabled={true} key={`${props.id}-${props.index}`}>
      {(provided, snapshot) => (
        <div  
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className="placeTile"
          style={getStyle(snapshot, provided.draggableProps.style)}
        >
          <div className="placeholder-text">
            <h1>Drag Data Tile Here</h1>
          </div>
        </div>
      )}
    </Draggable>
  );
}