import {TextField} from '@rmwc/textfield'
import GuestJoinSpaceButton from 'buttons/GuestJoinSpaceButton';
import { useLayoutEffect, useRef, useState } from 'react';

export default function GuestLogin(props){

  return (
    <>
      <div className="LoginWindow"> 
        <div className="LoginFormField">
          <GuestJoinSpaceButton
            setValidInput={props.setValidInput}
            roomId={props.roomId}
            audioPreMuted={props.audioPreMuted}
            videoPreMuted={props.videoPreMuted}
            validInput={props.validInput}
            videoInit={props.videoInit}
            userInput={props.nameInput}
            setLoading={props.setLoading}
            setAdmitMessage={props.setAdmitMessage}
          />
          { props.audioPreMuted && props.videoPreMuted && 
            <p className="deviceError guest">Please enable Camera or Microphone to Join Space.</p>
          }
        </div>
      </div>
    </>
  );
};