import { createSlice } from "@reduxjs/toolkit";

const initalWidth = window ? window.innerWidth * 0.3 : 400;
const maxWidth = window ? window.innerWidth / 2 : 800;

export const mmSlice = createSlice({
   name: 'mattermost',
   initialState: {
      show: false,
      minWidth: initalWidth,
      maxWidth: maxWidth,
      width: initalWidth,
      resize: false,
      initMouseX: null,
      // initMouseY: null,
   },
   reducers: {
      openMM: (state, action) => {
         return {
            ...state,
            show: action.payload
         }
      },
      updateWidth: (state, action) => {
         console.log(action);
         return {
            ...state,
            width: state.width + (() => {
               const mousemoveX = action.payload;
               if (mousemoveX < 0) return Math.abs(mousemoveX);
               return -Math.abs(mousemoveX)
            })(),
         }
      },
      setWidth: (state, action) => {
         return {
            ...state,
            width: action.payload,
         }
      },
      setResize: (state, action) => {
         const payload = action.payload;
         const resize = payload.resize;
         const event = payload.event;
         const clientX = event?.clientX;
         const initMouseX = resize ? clientX : null;
         return {
            ...state,
            resize,
            initMouseX,
         }
      },
      setInitMousePos: (state, action) => {
         return {
            ...state,
            initMousePos: action.payload,
         }
      },
   }
})

export const { openMM, updateWidth, setResize, setInitMousePos, setWidth, } = mmSlice.actions
export default mmSlice.reducer