import { SocketContext } from 'context/socket';
import { getMeetingMembers } from 'js/webex-controls';
import { useContext } from 'react';
import { useRef, useState, memo, useEffect } from 'react';
import RemoteVideo from './RemoteVideo';
import SelfVideo from './SelfVideo';
import { useSelector } from "react-redux";
// import { fetchExternalApi } from '@jitsi/web-sdk';
import { JitsiMeeting } from '@jitsi/react-sdk';
// import { fetchExternalApi } from '@jitsi/react-sdk';
import { useDispatch } from 'react-redux';
import {
   updateShow,
   updateHovering,
   updateFullscreen,
   updateWidth as updateVideoWidth,
   updateResize as updateVideoResize,
   updateInitialX,
   updateJitsi,
   updateShareScreenMode,
   updateJitsiKey,
   updateShowParticipants,
} from 'app/video';
import { setResize, updateWidth as updateChatWidth } from 'app/mattermost';
import jitsi_config from "./jitsi_config.js";
import jitsi_interface_config from "./jitsi_interface_config.js";


export default function JitsiVideo(props) {
   const dispatch = useDispatch();
   const socket = useContext(SocketContext);
   const user = useSelector(state => state.user)
   const showVid = useSelector(state => state.video.show);
   const shareScreenMode = useSelector(state => state.video.shareScreenMode);
   // const videoStyle = props.videoStyle;
   // const jitsiKey = useSelector(state => state.video.jitsiKey);
   const jitsiDomain = window._env_.REACT_APP_JITSI_DOMAIN;

   const videoState = useSelector(state => state.video);
   const videoResizable = useSelector(state => state.resizable);
   const {
      show,
      hovering,
      fullscreen,
      width: videoWidth,
      minWidth: videoMinWidth,
      maxWidth: videoMaxWidth,
      resize,
   } = videoState;

   // const email = user.name + "@3data.io"
   // const displayName = user.name
   // const roomName = window.sessionStorage.getItem('userId')
   // // const jwt = "somejwt"
   // const userInfo = { email, displayName }

   function onMouseDown(event) {
      const x = event.clientX;
      dispatch(updateVideoResize(true));
      dispatch(updateInitialX(x));
      console.log(`Video resize ${resize ? 'enabled' : 'disabled'}`);
   }

   function onMouseUp(event) {
      dispatch(updateVideoResize(false));
      dispatch(setResize({ resize: false, }));
      dispatch(updateInitialX(null));
      console.log(`Video resize ${resize ? 'enabled' : 'disabled'}`);

   }

   function onMouseMove(event) {
      const videoResize = resize;
      const videoShow = show;
      (videoResize) && (console.warn('Video resizing'));

      //@@ Chat resizing @@//

      //@@ Video resizing @@//
      if (videoResize && videoShow) {
         const mx = event.movementX;
         console.log('video resizing!', mx);

         //@@ If min width reached @@//
         if (mx > 0 && videoWidth <= videoMinWidth) {
            // onMouseUp();
            // dispatch(updateVideoResize(false));
            dispatch(setResize({ resize: false, }));
            return;
         };

         //@@ If max width reached @@//
         if (mx < 0 && videoWidth >= videoMaxWidth) {
            // onMouseUp();
            // dispatch(updateVideoResize(false));
            dispatch(setResize({ resize: false, }));
            return;
         }
         dispatch(updateVideoWidth(mx));
      }

   }

   const getLargeTileRect = () => {
      const largeTile = document?.querySelector('.largeTileClass');
      if (!largeTile) return;
      const rect = largeTile.getBoundingClientRect();
      return rect;
   }

   const [style, setStyle] = useState({})

   const email = user.name + "@3data.io"
   // const displayName = user.name || guestDisplayName;
   // const displayName = user.name;
   // const displayName = user.guest ? user.displayName : user.name;
   const guestName = window.sessionStorage.getItem('guestName');
   const displayName = user.guest ? guestName : user.name;
   // const roomName = window.sessionStorage.getItem('userId')
   const pathArray = window.location.pathname.split('/');
   const roomID = pathArray[2];
   const roomName = roomID
   // const jwt = "somejwt"
   const userInfo = { email, displayName };

   // console.warn({ userInfo });

   function jitsiOnApiReady(api) {
      window.jitsi = api;
      // if (window.jitsi) return; // Just to prevent multiple calls.

      // https://jitsi.github.io/handbook/docs/dev-guide/dev-guide-iframe-commands/
      // api.executeCommand('toggleLobby', false);
      // api.executeCommand('toggleTileView');

      api.executeCommand('setTileView', true);
      //!! Temp fix, retain jitsi tile view upon loading for couple of seconds because it keeps exiting tile view mode !!//
      window.retainTileViewOnLoadTemp = true;
      window.setTimeout(() => window.retainTileViewOnLoadTemp = false, 5000);

      // api.executeCommand('setTileView', true);
      // https://jitsi.github.io/handbook/docs/dev-guide/dev-guide-iframe-events#screensharingstatuschanged
      api.addListener('screenSharingStatusChanged', event => {
         socket.emit('video-share-screen', {
            userInfo, event,
         });
      });
      api.addListener('participantsPaneToggled', event => {
         const open = event.open;
         dispatch(updateShowParticipants(open));
      });
      api.addListener('tileViewChanged', event => {
         const enabled = event.enabled;

         if (window.retainTileViewOnLoadTemp && !enabled) {

            api.executeCommand('setTileView', true);
         }
      });
   }

   const queryParameters = new URLSearchParams(window.location.search)
   const videoParam = queryParameters.get("video");
   const videoEnabled = videoParam === 'true';
   const audioParam = queryParameters.get("audio")
   const audioEnabled = audioParam === 'true';

   function forceUpdateJitsiKey(timeout = 100) {
      if (!videoResizable) return;
      if (window.forceUpdateJitsiKeyTimeoutId) {
         window.clearTimeout(window.forceUpdateJitsiKeyTimeoutId);
      }

      const forceUpdateJitsiKeyTimeoutId = setTimeout(() => {
         dispatch(updateJitsiKey());
      }, timeout);

      window.forceUpdateJitsiKeyTimeoutId = forceUpdateJitsiKeyTimeoutId;
   }

   useEffect(() => {
      window.addEventListener('resize', forceUpdateJitsiKey);

      socket.on('video-share-screen', (payload) => {
         console.log('video-share-screen', payload);
         const event = payload.event;
         // const userInfo = payload.userInfo;

         const isSharing = event.on;
         if (shareScreenMode === isSharing) return;

         dispatch(updateShareScreenMode(isSharing));
         // const displayName = userInfo.displayName;
         // const email = userInfo.email;
         forceUpdateJitsiKey();
      });
   }, [shareScreenMode]);



   return (
      <div
         id='webexVideoTile'
         style={{
            display: (() => {
               if (shareScreenMode) return 'block';
               if (showVid) return 'block';
               return 'none';
            })(),
            height: (() => {
               if (!shareScreenMode) return '100%';
               const rect = getLargeTileRect();
               const { height } = rect;
               return height + 'px';
            })(),
            width: (() => {
               if (!shareScreenMode) return videoWidth + 'px';
               const rect = getLargeTileRect();
               const { width } = rect;
               return width + 'px';
            })(),
            position: (() => {
               if (!shareScreenMode) return 'relative';
               return 'absolute';
            })(),
            left: (() => {
               if (!shareScreenMode) return '0';
               const rect = getLargeTileRect();
               const { left } = rect;
               return left + 'px';
            })(),
            right: (() => {
               if (!shareScreenMode) return '0';
               const rect = getLargeTileRect();
               const { right } = rect;
               return right + 'px';
            })(),
         }}
      >
         <div
            id="webex_video_resize_bar"
            style={{
               display: videoResizable ? 'block' : 'none',
               position: 'absolute',
               height: '100%',
               width: '6px',
               marginLeft: '-3px',
               left: 0,
               top: 0,
               zIndex: 1,
               cursor: 'e-resize',
            }}
         // onMouseDown={onMouseDown}
         // onMouseUp={onMouseUp}
         ></div>
         <JitsiMeeting
            domain={jitsiDomain}
            roomName={roomName}
            configOverwrite={{
               // ...jitsi_config,
               apiLogLevels: [
                  'error',
               ],
               logging: {
                  defaultLogLevel: 'error',
               },
               hideConferenceSubject: true,
               prejoinConfig: {
                  enabled: false,
               },
               watermark: false,
               disableTileView: true,
               startWithVideoMuted: !videoEnabled,
               startWithAudioMuted: !audioEnabled,
               toolbarButtons: [
                  'camera',
                  //    'chat',
                  'closedcaptions',
                  'desktop',
                  'download',
                  //    'embedmeeting',
                  'etherpad',
                  //    'feedback',
                  //    'filmstrip',
                  'fullscreen',
                  //    'hangup',
                  // 'help',
                  'highlight',
                  // 'invite',
                  //    'linktosalesforce',
                  //    'livestreaming',
                  'microphone',
                  'noisesuppression',
                  'participants-pane',
                  // 'profile',
                  'raisehand',
                  'recording',
                  //    'security',
                  'select-background',
                  'settings',
                  //    'shareaudio',
                  //    'sharedvideo',
                  'shortcuts',
                  'stats',
                  'tileview',
                  'toggle-camera',
                  'videoquality',
                  // 'whiteboard',
                  '__end',
               ],
            }}
            interfaceConfigOverwrite={{
               // ...jitsi_interface_config,
               TILE_VIEW_MAX_COLUMNS: 1,
               SHOW_JITSI_WATERMARK: false,
            }}
            userInfo={userInfo}
            // onMouseDown={onMouseDown}
            // onMouseUp={onMouseUp}
            prejoinPageEnabled={false}
            style={{
               width: '100%',
               height: '100%'
            }}
            onApiReady={jitsiOnApiReady}
         ></JitsiMeeting>

         <div
            id="video_resize_overlay"
            style={{
               'display': resize ? 'block' : 'none',
               'opacity': 0.1,
               // position: 'absolute',
               position: 'fixed',
               top: 0,
               left: 0,
               height: '100%',
               width: '100%',
               background: 'grey',
               zIndex: 100,
            }}
         // onMouseUpCapture={onMouseUp}
         // onMouseMoveCapture={onMouseMove}
         ></div>
      </div >
   );
}

export const JitsiVideoMemo = memo(JitsiVideo);