import { useContext } from "react"
import React from 'react'
import { SocketContext } from "context/socket"
import { useParams } from "react-router-dom"
import { useSelector } from "react-redux"
import { updatePdfPage } from "net/tiles"

export default function PortableDocumentControls(props){
  const params = useParams()
  const space = useSelector(state => state.space)
  const pdfPageChange = 'pdf-page-change'

  const socket = useContext(SocketContext)

  const changePage = (offset) => {
    socket.emit(pdfPageChange, {newPage: props.currPage + offset, pdfId: props.id, fileName: props.fileName})
    updatePdfPage(space.teamId, params.roomId, props.fileName, props.currPage + offset)
  }

  const previousPage = () => changePage(-1)
  const nextPage = () => changePage(1);

  return(
    <>
      <div className="pdf-controls">
        <div className="controls-background" id={`${props.id}controls`}>
          <button
            type="button"
            disabled={props.currPage <= 1 || props.accessLevel !== 'owner'}
            onClick={previousPage}
          >
            &lt;
          </button>
          <p className="pdf-page-number">{props.currPage}/{props.numPages}</p>
          <button
            type="button"
            disabled={props.currPage >= props.numPages || props.accessLevel !== 'owner'}
            onClick={nextPage}
          >
            &gt;
          </button>
        </div>
      </div>
    </>
  )
}