import { createSlice } from "@reduxjs/toolkit";

export const annotationSlice = createSlice({
  name: 'annotations',
  initialState: {
    lines: '',
    shapes: [],
    textboxes: [],
  },
  reducers: {
    updateLines: (state, action) => {
      console.log(state.value);
      state.lines = action.payload;
    },
    updateShapes: (state, action) => {
      state.shapes = action.payload;
    },
    addTextboxes: (state, action) => {
      state.textboxes = action.payload;
    },
    updateTextboxes: (state, action) => {
      state.textboxes = action.payload
    }
  }
});

export const {
  updateLines,
  updateShapes,
  addTextboxes,
  updateTextboxes
} = annotationSlice.actions;
export default annotationSlice.reducer;