import { Dialog } from "@rmwc/dialog";
import { Tab, TabBar } from '@rmwc/tabs'
import SlackMessagingTab from "components/slack/SlackMessagingTab";
import { SocketContext } from "context/socket";
import { useRef } from "react";
import { useContext, useEffect, useState, Component } from "react";
import { Widget, renderCustomComponent, toggleWidget } from 'react-chat-widget';
import 'react-chat-widget/lib/styles.css';
import { useSelector } from "react-redux";
import './Modals.css'

export default function MessagingModal(props) {
   const socket = useContext(SocketContext)
   const chatMessage = 'chat-message'
   const user = useSelector(state => state.user)
   const [activeTab, setActiveTab] = useState(0)
   const openRef = useRef(props.messagingOpen)
   const activeTabRef = useRef(activeTab)

   useEffect(() => {
      openRef.current = props.messagingOpen
      activeTabRef.current = activeTab
   })

   useEffect(() => {
      const handleChatMessage = (message) => {
         if (message.message.name !== user.webex.name) {
            if (!openRef.current || activeTabRef.current !== 1) {
               props.setMessageNotification(true)
            }
            const sound = new Audio('/assets/sounds/message.mp3')
            sound.play();
            let time = new Date()
            let hours = time.getHours()
            if (hours > 12) hours -= 12
            if (hours < 10) hours = `0${hours}`
            let min = time.getMinutes()
            if (min < 10) min = `0${min}`
            renderCustomComponent(ChatText, {
               message: message.message.message,
               name: message.message.name,
               time: `${hours}:${min}`
            })
         }
      }
      socket.on(chatMessage, handleChatMessage)

      return () => {
         socket.off(chatMessage, handleChatMessage)
      }
   }, [user])

   useEffect(() => {
      toggleWidget()

      return () => {
         toggleWidget()
      }
   }, [])

   const handleClose = () => {
      props.setMessagingOpen(false)
   }

   const handleOpen = () => {
      if (activeTab === 1) {
         props.setMessageNotification(false)
      }
   }

   const handleActivate = (e) => {
      let active = user.guest ? e.detail.index + 1 : e.detail.index
      setActiveTab(active)
      if (e.detail.index === 1 && props.messageNotification) {
         props.setMessageNotification(false)
      }
   }

   const handleNewUserMessage = (message) => {
      socket.emit(chatMessage, {
         message: message,
         name: user.webex.name
      })
   }

   return (
      <>
         <Dialog
            preventOutsideDismiss={false}
            open={props.messagingOpen}
            onClose={handleClose}
            onOpen={handleOpen}
            className="messaging">
            <div className="messagingWrapper">
               <TabBar onActivate={handleActivate} className="messagingNav">
                  {!user.guest &&
                     <Tab>
                        <img src="/assets/slacklogo.png" height={25} width={'100%'} />
                     </Tab>
                  }
                  <Tab>
                     <img src="/assets/logobw.png" height={30} width={'100%'} />
                  </Tab>
               </TabBar>
               {activeTab === 0 &&
                  <SlackMessagingTab />
               }
               {activeTab === 1 &&
                  <>
                     <Widget
                        handleNewUserMessage={handleNewUserMessage}
                     />
                  </>
               }
            </div>
         </Dialog>
      </>
   )
}

class ChatText extends Component {
   render() {
      return (
         <>
            <div className="rcw-response">
               <div className="rcw-message-text">
                  <p style={{ fontWeight: 'bold' }}>{this.props.name}</p>
                  <p style={{ fontWeight: 'normal' }}>{this.props.message}</p>
               </div>
               <span className="rcw-timestamp">{this.props.time}</span>
            </div>
         </>
      )
   }
}