import { Tooltip } from '@rmwc/tooltip';
import { useSelector } from 'react-redux';
import { SocketContext } from "context/socket"
import { useContext, useEffect } from 'react';
import { destroyVNCAsset } from 'net/tiles';
import { useParams } from 'react-router-dom';

export default function UnmountButton(props = {}) {
   const params = useParams()
   const socket = useContext(SocketContext)
   const closeTile = 'close-tile'
   const fullscreenSync = 'large-tile-fullscreen';
   const viewState = useSelector(state => state.view)
   const userState = useSelector(state => state.user);
   const { isOwner } = userState;
   const { level, fullScreen } = viewState
   const item = props.item;
   const rooms = useSelector(state => state.room);

   useEffect(() => {
      
   }, [rooms.length])

   function handleClick() {
      console.log(`Either unmount or remove tile`, { props });
      const customHandleClick = props.handleClick;
      if (customHandleClick) {
         customHandleClick();
         return;
      }
      // const { tileId, viewLevel } = props
      const isHorus = props.isHorus;
      const tileId = props.id || props.tileId;
      if (!tileId) {
         console.error(`[CRITICAL ERROR] Missing tile ID!!!!!`);
         return;
      }
      console.log(socket);
      if (fullScreen) socket.emit(fullscreenSync, false);
      socket.emit(closeTile, { _id: tileId, folder: level })

      if (isHorus) {
         const horusSrc = item.src
         const horusSerial = horusSrc.substring(0, horusSrc.indexOf("."))
         destroyVNCAsset(params.roomId, horusSerial).then((result) => {
            console.log('destroyVNCAsset() result: ', result);
         }).catch((err) => {
            console.error(err)
         })
      }
   }

   const large = props.large;
   const title = item?.title || item?.name || item?.fileName || props.fileName || item?.src || '';
   const tooltip = large ? `Unmount ${title}` : `Remove ${title}`;

   return (
      <>
         {isOwner &&

            large ? (
            <Tooltip
               content={tooltip}
            >
               <div
                  className="unmount-btn"
                  onClick={handleClick}
               >
                  <div className='line'></div>
               </div>
            </Tooltip>

         ) : (
            <Tooltip
               content={tooltip}
            >
               <div className="remove-btn" onClick={handleClick}>
                  <div className="line line-1"></div>
                  <div className="line line-2"></div>
               </div>
            </Tooltip>
         )
            // <button className="tileClose btn" onClick={handleClick}>
            //    <Tooltip content={tooltip}>
            //       <span className="material-icons">clear</span>
            //    </Tooltip>
            // </button>
            // <button className="main-tile-btn main-tile-btn--minimize" onClick={handleClick}>
            //    <Tooltip content={tooltip}>
            //       <span className="material-icons">remove_circle</span>
            //    </Tooltip>
            // </button>


         }
      </>
   );
}