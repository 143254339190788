import { Fab } from "@rmwc/fab";
import { Tooltip } from '@rmwc/tooltip';
import { SocketContext } from "context/socket";
import { useContext } from "react";

export default function WhiteboardButton(props) {
   const socket = useContext(SocketContext)
   const whiteboardClick = 'whiteboard-click'

   const handleClick = () => {
      socket.emit(whiteboardClick, { inUse: true })
   }
   return (
      <>
         <Tooltip content="Whiteboard">
            <Fab
               style={{ display: 'none' }}
               onClick={handleClick}
               className="primePillBtn"
               icon="drive_file_rename_outline"
               mini>
            </Fab>
         </Tooltip>
      </>
   )
}