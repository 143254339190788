import { SocketContext } from "context/socket"
import { useState, useEffect, useContext } from "react"
import { CSS_COLOR_NAMES } from 'aesthetic/css_colors'
import { useDispatch, useSelector } from "react-redux"
import { assignCursors } from "app/participants"
import { assignCursorColor } from "app/user"
import { useRef } from "react"

export default function Cursor() {
   const ENV = useSelector(state => state.env);
   const CURSOR_DEBUG = ENV?.CURSOR_DEBUG;
   const CURSOR_TRACK_TIME = ENV?.CURSOR_TRACK_TIME;

   const socket = useContext(SocketContext)
   const cursorMovement = 'cursor-movement'
   const user = useSelector(state => state.user)
   const participants = useSelector(state => state.participants.participants)
   const cursors = useSelector(state => state.participants.cursors)
   const cursorsRef = useRef(cursors)
   const dispatch = useDispatch()
   const [allCursors, setAllCursors] = useState([])

   // only want initial state, will never reassign
   const [cursorColor,] = useState(CSS_COLOR_NAMES[Math.floor(Math.random() * CSS_COLOR_NAMES.length)])

   const broadcastPosition = (e) => {
      const { clientX, clientY } = e
      let propX = clientX === 0 ? 0 : clientX / window.innerWidth
      let propY = clientY === 0 ? 0 : clientY / window.innerHeight
      // socket.emit(cursorMovement, { id: user.webex.id, x: propX, y: propY, color: cursorColor })
      socket.emit(cursorMovement, { id: user._id, x: propX, y: propY, color: cursorColor })
   }

   const vncBroadcastPosition = (e) => {
      const { cursorX, cursorY } = e.detail
      // socket.emit(cursorMovement, { id: user.webex.id, x: cursorX, y: cursorY, color: cursorColor })
      socket.emit(cursorMovement, { id: user._id, x: cursorX, y: cursorY, color: cursorColor })
   }

   const debugTime = (against, compare) => {
      if (!compare || !compare.time || !compare.name) return;
      const { time, name, } = compare;

      const convertFormat = (time) => {
         const now = new Date(time); //?
         const minutesNow = now.getMinutes().toString().padStart(2, '0') //?;
         const secondsNow = now.getSeconds().toString().padStart(2, '0'); //?
         const formatted = `${minutesNow}:${secondsNow}`;
         return formatted
      }

      console.log();
      console.group(name);
      console.warn(`${convertFormat(against)} - NOW`);
      console.warn(`${convertFormat(time)} - ${name}`);
      console.warn(`${convertFormat(against - time)} - DIFF`);
      console.groupEnd(name);
      console.log();
   }

   const renderCursors = (data) => {
      console.log("renderCursors()", data);
      let list = []
      // const time = new Date().getTime()
      const time = Date.now();
      for (let pId in participants) {
         const participant = participants[pId];
         // console.log(participant);
         const participantExists = participant.id === data.id;
         const cursorIsNotMine = participant.id !== user._id;
         const participantTime = participant.time;
         const participantTimeIsRecent = CURSOR_TRACK_TIME ? participantTime >= time - 10000 : true;

         (CURSOR_DEBUG) && (debugTime(time, { name: 'PARTICIPANT', time: participantTime }));
         console.log({participantExists, cursorIsNotMine, participantTimeIsRecent});

         // if (participants[pId].id === data.id && participants[pId].id !== user._id && participants[pId].time >= time - 10000) {
         if (participantExists && cursorIsNotMine && participantTimeIsRecent) {
            console.log('--- ALL SUFFICE ---');
            let cursor = {
               name: participants[pId].name,
               id: participants[pId].id,
               cursorX: data.x,
               cursorY: data.y,
               color: data.color,
               time: participants[pId].time
            }
            // console.info('assignCursors()', cursor);
            dispatch(assignCursors(cursor))

            const cursorExists = !!cursorsRef.current[pId];
            const cursorTime = cursorsRef.current[pId].time
            const cursorTimeIsRecent = CURSOR_TRACK_TIME ? cursorTime >= time - 10000 : true;

            console.log({cursorExists});

            (CURSOR_DEBUG) && (debugTime(time, { name: 'CURSOR', time: cursorTime }));

            // if (cursorsRef.current[pId] !== undefined && cursorsRef.current[pId].time >= time - 10000) {
            if (cursorExists && cursorTimeIsRecent) {
               list.push(
                  <div className="cursorWrapper" key={participants[pId].id}>
                     <div
                        className="flex-container-row center"
                        style={{
                           position: 'absolute',
                           pointerEvents: 'none',
                           left: cursorsRef.current[pId].cursorX * window.innerWidth,
                           top: cursorsRef.current[pId].cursorY * window.innerHeight,
                           transform: 'translate(-50%, -50%)'
                        }}
                     >
                        <svg
                           height="30"
                           viewBox="0 0 24 24"
                           width="30"
                           xmlns="http://www.w3.org/2000/svg"
                           style={{ strokeWidth: 0.5 }}
                        >
                           {/* from https://www.flaticon.com/free-icon/up-sign_3601914?term=cursor&related_id=3601918&origin=tag */}
                           <path d="m18 9.75c-.828 0-1.5.672-1.5 1.5v-1c0-.828-.672-1.5-1.5-1.5s-1.5.672-1.5 1.5v-1c0-.828-.672-1.5-1.5-1.5s-1.5.672-1.5 1.5v-6c0-.828-.672-1.5-1.5-1.5s-1.5.672-1.5 1.5v11.25l-3.717-2.28h-.003c-.22-.14-.49-.22-.78-.22-.83 0-1.5.67-1.5 1.5 0 .29.08.57.23.8l-.002-.004 4.596 7.329c.732 1.167 2.012 1.875 3.389 1.875h4.787c2.761 0 5-2.239 5-5v-7.25c0-.828-.672-1.5-1.5-1.5z" fill={cursorsRef.current[pId].color} />
                           <path d="m7.25 15c-.414 0-.75-.336-.75-.75v-11c0-1.24 1.009-2.25 2.25-2.25s2.25 1.01 2.25 2.25v10c0 .414-.336.75-.75.75s-.75-.336-.75-.75v-10c0-.413-.336-.75-.75-.75s-.75.337-.75.75v11c0 .414-.336.75-.75.75z" />
                           <path d="m13.25 14c-.414 0-.75-.336-.75-.75v-4c0-.413-.336-.75-.75-.75s-.75.337-.75.75c0 .414-.336.75-.75.75s-.75-.336-.75-.75c0-1.24 1.009-2.25 2.25-2.25s2.25 1.01 2.25 2.25v4c0 .414-.336.75-.75.75z" />
                           <path d="m16.25 14c-.414 0-.75-.336-.75-.75v-3c0-.413-.336-.75-.75-.75s-.75.337-.75.75c0 .414-.336.75-.75.75s-.75-.336-.75-.75c0-1.24 1.009-2.25 2.25-2.25s2.25 1.01 2.25 2.25v3c0 .414-.336.75-.75.75z" />
                           <path d="m15.25 24h-5.517c-1.635 0-3.184-.864-4.043-2.257l-4.335-7.029c-.001-.002-.001-.003-.002-.004-.231-.353-.353-.772-.353-1.21 0-1.24 1.009-2.25 2.25-2.25.413 0 .808.108 1.147.314.012.007.024.015.037.021l3.217 2.029c.349.223.454.686.233 1.036s-.685.454-1.035.234l-3.188-2.012c-.012-.007-.023-.014-.034-.021-.104-.065-.238-.101-.377-.101-.414 0-.75.337-.75.75 0 .138.035.269.097.373.007.01.013.02.019.029l4.35 7.054c.588.952 1.648 1.544 2.767 1.544h5.517c1.792 0 3.25-1.458 3.25-3.25v-8c0-.413-.336-.75-.75-.75s-.75.337-.75.75c0 .414-.336.75-.75.75s-.75-.336-.75-.75c0-1.24 1.009-2.25 2.25-2.25s2.25 1.01 2.25 2.25v8c0 2.619-2.131 4.75-4.75 4.75z" />
                        </svg>
                        {cursorsRef.current[pId].name !== undefined &&
                           <p className="cursor-name" style={{ backgroundColor: cursorsRef.current[pId].color }}>
                              {cursorsRef.current[pId].name.split(' (guest)')[0]}
                           </p>
                        }
                     </div>
                  </div>
               )
            }
         }
      }
      // console.log({ list, participants, cursors });
      setAllCursors(list)
   }

   useEffect(() => {
      cursorsRef.current = cursors
   })

   useEffect(() => {
      document.querySelector('.largeTileClass').addEventListener('mousemove', broadcastPosition, false)
      document.addEventListener('vncmousemove', vncBroadcastPosition, false)
      return () => {
         let el = document.querySelector('.largeTileClass')
         if (el) el.removeEventListener('mousemove', broadcastPosition)
         document.removeEventListener('vncmousemove', vncBroadcastPosition)
      }
   }, [user])

   useEffect(() => {
      dispatch(assignCursorColor(cursorColor))
   }, [])

   useEffect(() => {
      const handleCursorMovement = (message) => {
         console.log('handleCursorMovement()', message);
         renderCursors(message.message)
      }
      socket.on(cursorMovement, handleCursorMovement)

      return () => {
         socket.off(cursorMovement, handleCursorMovement)
      }
   }, [participants])

   return (
      <>
         {allCursors}
      </>
   )
}