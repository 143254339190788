import { defaultTheme, Provider } from "@adobe/react-spectrum";
import { useDispatch, useSelector } from 'react-redux';
import { updateWidth as updateChatWidth, setWidth, openMM, setResize } from 'app/mattermost';
import { updateShow, updateHovering, updateFullscreen, updateWidth as updateVideoWidth, updateResize, updateInitialX } from 'app/video'
import { useEffect } from "react";



export default function DefaultLayout(props) {
   const dispatch = useDispatch()
   const mmWidth = useSelector(state => state.mattermost.width)
   const mmShow = useSelector(state => state.mattermost.show)
   const resize = useSelector(state => state.mattermost.resize)
   const initMouseX = useSelector(state => state.mattermost.initMouseX)
   const minWidth = useSelector(state => state.mattermost.minWidth);
   const maxWidth = useSelector(state => state.mattermost.maxWidth);

   const videoState = useSelector(state => state.video);
   const {
      show,
      hovering,
      fullscreen,
      width,
      minWidth: videoMinWidth,
      maxWidth: videoMaxWidth,
      resize: videoResize,
      initialX: videoInitialX,
   } = videoState;

   function onMouseMove(event) {
      (resize) && (console.warn('Chat resizing'));
      (videoResize) && (console.warn('Video resizing'));

      if (!resize || !videoResize) return;

      //@@ Chat resizing @@//
      if (resize && mmShow) {
         const oldClientX = initMouseX;
         const newClientX = event.clientX;
         if (newClientX === oldClientX) return;

         const isShrinking = newClientX > oldClientX;
         const isExpanding = newClientX < oldClientX;

         if (isShrinking) { // If shrinking.
            if (mmWidth <= minWidth) return;
         }
         if (isExpanding) { // If expanding.
            if (mmWidth >= maxWidth) return;
         }

         dispatch(updateChatWidth(event.movementX));
      }


      //@@ Video resizing @@//
      if (videoResize && show) {
         console.log('video resizing!');
         const mx = event.movementX;
         dispatch(updateVideoWidth(mx));
      }

   }

   function onMouseUp(event) {
      dispatch(setResize({ resize: false, event }));
      dispatch(updateResize(false));
   }

   useEffect(() => {
      const iframes = document.getElementsByTagName('iframe');
      console.log({ iframes, length: iframes.length });
   }, []);


   return (
      <>
         <Provider theme={defaultTheme} colorScheme="dark" UNSAFE_className="mainBG">
            <div
               id="main_bg_child"
               className="flex-container-column flex-between"
               style={{
                  'position': 'absolute',
                  'left': '0',
                  'top': '0',
                  'width': '100%',
                  'height': '100%',
               }}
               onMouseMoveCapture={onMouseMove}
               onMouseUpCapture={onMouseUp}
               onMouseLeave={onMouseUp}
            >
               <div>
                  {props.children}
               </div>
            </div>
         </Provider>
      </>
   );

}