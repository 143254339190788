import useSWR from 'swr';
import { fetcher, poster } from './fetcher';

export function useToken(userId, roomId){
  const {data, error} = useSWR(`/api/slack/${userId}/${roomId}/fetchToken`, fetcher);
  return {
    data:data,
    isLoading: !error && !data,
    isError: error,
  }
}

export function useSlackUsers(){
  const {data, error} = useSWR(`/api/slack/listUsers`, fetcher);
  return {
    data:data,
    isLoading: !error && !data,
    isError: error,
  }
}

export function sendInviteMessage(name, url, channel){
  const body = {
    name: name,
    url: url,
    channel: channel
  }

  return poster(`/api/slack/sendInviteMessage`, body)
}

export function sendSlackMessage(message, channel){
  const body = {
    message: message,
    channel: channel
  }

  return poster(`/api/slack/sendSlackMessage`, body)
}