import { addElementMedia } from 'js/webex-controls';
import { useLayoutEffect, useEffect, useContext, Component } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import ADataTile from 'components/dataTiles/DataTileNew';
import { SocketContext } from 'context/socket';
import AnnotationCanvas from 'components/AnnotationCanvas';
import CalendarIframe from 'components/temp/CalendarIframe';
import { updateShow, updateHovering, updateFullscreen, updateWidth as updateVideoWidth, updateResize, updateInitialX } from 'app/video';
import { setResize, updateWidth as updateChatWidth } from 'app/mattermost';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

// Adding a component extensions for the tile to use proper React Update comparison
// To prevent over re-rendering
class Tile extends Component {
   shouldComponentUpdate(nextProps) {
      if (JSON.stringify(nextProps.tileList) === JSON.stringify(this.props.tileList) && JSON.stringify(this.props.pdfTracker) === JSON.stringify(nextProps.pdfTracker)) {
         return false;
      }
      return true;
   }
   render() {
      return (
         this.props.tileList.map((item, index) => (
            <ADataTile
               id={item._id}
               key={item._id + index}
               index={index}
               item={item}
               large={true}
               videoSize={this.props.videoSize}
               accessLevel={this.props.accessLevel}
               pdfTracker={this.props.pdfTracker}
            />

         ))
      );
   }
}

export default function TilePanelLarge(props) {
   const dispatch = useDispatch();
   const sceneUpdate = 'scene-string'

   const socket = useContext(SocketContext)

   const getDroppableStyle = () => {
      let styleWithProps = Object.assign({}, {
         "maxHeight": "calc(100vh - 10rem)"
      })
      return styleWithProps
   }

   // new code
   useEffect(() => {
      socket.on(sceneUpdate, (message) => {
         if (message.message.dataTile00 && JSON.stringify(message.message.dataTile00) === JSON.stringify(props.tileList)) {
            props.setTileList(props.tileList)
         }
         else {
            props.setTileList(message.message.dataTile00)
         }
      })
   }, [])

   // useEffect doesn't fire late enough
   useLayoutEffect(() => {
      addElementMedia(props.sharing)
   }, [props.tileList])

   const videoState = useSelector(state => state.video);
   const {
      show: videoShow,
      width: videoWidth,
      resize: videoResize,
   } = videoState;

   const chatState = useSelector(state => state.mattermost);
   const {
      show: chatShow,
      width: chatWidth,
      minWidth: chatMinWidth,
      maxWidth: chatMaxWidth,
      resize: chatResize,
      initMouseX: chatInitMouseX
   } = chatState;

   function onMouseMove(event) {
      (chatResize) && (console.warn('Chat resizing'));
      (videoResize) && (console.warn('Video resizing'));

      if (!chatResize || !videoResize) return;

      //@@ Chat resizing @@//
      if (chatResize && chatShow) {
         const oldClientX = chatInitMouseX;
         const newClientX = event.clientX;
         if (newClientX === oldClientX) return;

         const isShrinking = newClientX > oldClientX;
         const isExpanding = newClientX < oldClientX;

         if (isShrinking) { // If shrinking.
            if (chatWidth <= chatMinWidth) return;
         }
         if (isExpanding) { // If expanding.
            if (chatMinWidth >= chatMaxWidth) return;
         }

         dispatch(updateChatWidth(event.movementX));
      }


      //@@ Video resizing @@//
      if (videoResize && videoShow) {
         console.log('video resizing!');
         const mx = event.movementX;
         dispatch(updateVideoWidth(mx));
      }



   }

   return (
      <Droppable droppableId="large-tile-list">
         {(provided, snapshot) => (
            <div
               className={(props.smallListShow ? 'largeTileClass' : 'largeTileClass expand') + ' observe-resize'}
               ref={provided.innerRef}
               style={getDroppableStyle(snapshot)}
               {...provided.droppableProps}
            >
               {props.tileList.map((item, index) => (
                  <>
                     <Tile
                        tileList={props.tileList}
                        id={item._id}
                        key={item._id + index}
                        index={index}
                        item={item}
                        large={true}
                        videoSize={props.videoSize}
                        accessLevel={props.accessLevel}
                        pdfTracker={props.pdfTracker}
                     />
                     <span style={{ display: "none" }} key={index}>
                        {provided.placeholder}
                     </span>
                     {props.annotationOpen && (<AnnotationCanvas key={item + index + index} />)}
                     {props.children}
                  </>
               ))}
               <div>
                  <CalendarIframe />
               </div>

               {/* <div>
               <WebexVideo selfVideoOn={false ?? selfVideoOn} />
               </div> */}

               <div
                  id="large_tile_resize_overlay"
                  style={{
                     'display': videoResize || chatResize ? 'block' : 'none',
                     'opacity': 0.1,
                     position: 'absolute',
                     top: 0,
                     left: 0,
                     height: '100%',
                     width: '100%',
                     background: 'grey',
                     zIndex: 1,
                  }}
                  onMouseMoveCapture={onMouseMove}
               ></div>
            </div>
         )}
      </Droppable>
   );
}
