import { useDispatch, useSelector } from 'react-redux';
import { Draggable } from "react-beautiful-dnd";
import { Rnd } from "react-rnd";

// const compSrcIndex = require('./index.html');
// const compSrcDashboard = require('./dashboard.html');

export default function CalendarIframe() {
   const dispatch = useDispatch()
   const showN = useSelector(state => state.ndrc.show)


   const wrapperStyle = {
      display: showN ? "flex" : 'none',
      alignItems: "center",
      justifyContent: "center",
      border: "solid 1px #ddd",
      borderRadius: '5px',
      // background: "#171717",
      background: "#0a2136",
      zIndex: 1000000,
      padding: '20px',
   };

   const iframeStyle = {
      // width: 'calc(100% - 50px)',
      // height: 'calc(100% - 50px)',
      width: '100%',
      height: '100%',
      borderRadius: '5px',
   };



   return (
      <Rnd
         style={wrapperStyle}
         default={{
            x: 0,
            y: 0,
            width: 320,
            height: 200
         }}
      >
         {/* <img src="https://img.icons8.com/ios-filled/50/ffffff/google-meet.png" alt="" width="10px" height="10px" style={{
            position: 'absolute',
            left: 0,
         }} /> */}

         <div
            style={{
               position: 'absolute',
               top: 0,
               width: '100%',
               display: 'flex',
               flexDirection: 'row',
               justifyContent: 'center',
               alignContent: 'center',
            }}
         >
            <img
               src="https://img.icons8.com/color/48/null/google-meet--v1.png"
               alt=''
               width="19px"
               height="19px"
            />
         </div>
         {/* <img
            src="https://img.icons8.com/color/48/null/google-meet--v1.png"
            alt=''
            width="20px"
            height="20px"
            style={{
               // position: 'absolute',
               left: 0,
               top: 0,
            }} /> */}
         <iframe
            title="ndrc-pip"
            src="https://pexip.staging-spaces.3data.io/webapp/"
            style={iframeStyle}
            sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
            allow="camera *; microphone *; display-capture *"
            allowFullScreen
         ></iframe>
         {/* <iframe id="pexip000" src="https://pexip.staging-spaces.3data.io/webapp/" class="inlineFrameTile" height="99%" width="100%" title="kratos-tile" name="kratos-tile" allowfullscreen="" sandbox="allow-same-origin allow-scripts allow-popups allow-forms"></iframe> */}
         {/* <iframe id="pexip000" src="https://pexip.staging-spaces.3data.io/webapp/" class="inlineFrameTile" height="99%" width="100%" title="kratos-tile" name="kratos-tile" allow="camera *; microphone *; display-capture *" allowfullscreen="" sandbox="allow-same-origin allow-scripts allow-popups allow-forms"></iframe> */}
      </Rnd >
   )

}
