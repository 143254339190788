import { createSlice } from "@reduxjs/toolkit";

export const roomSlice = createSlice({
   name: "room",
   initialState: {
      data: null,
   },
   reducers: {
      updateRoom(state, action) {
         const roomData = action.payload;
         const roomDataCloneDeep = JSON.parse(JSON.stringify(roomData));
         return {
            ...state,
            data: {
               ...roomDataCloneDeep,
            },
         };
      },
   },
});

export const { updateRoom } = roomSlice.actions;
export default roomSlice.reducer;
