// import { createContext } from 'react';
// import io from 'socket.io-client'
// const baseSocketUrl = window._env_.REACT_APP_SOCKET_SERVER;
// const roomId = window.location.pathname.split('/')[2]

// export const socketConnection = io(baseSocketUrl + "/space-" + roomId, {transports: [ "websocket" ]});
// export const SocketContext = createContext()





import { createContext } from 'react';
import io from 'socket.io-client'
const roomId = window.location.pathname.split('/')[2]
const baseSocketUrl = window._env_.REACT_APP_SOCKET_SERVER;
const subPath = window._env_.REACT_APP_SOCKET_SUBPATH || '';
const roomPath = `/space-${roomId}`;
const socketPath = subPath + roomPath;

export const socketConnection = io.connect(baseSocketUrl + socketPath, {
   // path: socketPath,
   transports: [ "websocket" ]
});
export const SocketContext = createContext()

