import { Draggable } from "react-beautiful-dnd";
import ScreenShareVideo from '../ScreenShareVideo';
import { draggableStyle } from '../../styles/draggableStyle';
import FullScreenButton from "buttons/FullScreenButton";
import { useSelector } from "react-redux";

export default function ScreenShareTile(props){
  const userSharing = useSelector(state => state.meeting.sharing)
  return (
    <Draggable draggableId={props.id} index={props.index} disableInteractiveElementBlocking isDragDisabled={props.accessLevel === 'owner' ? false : true}>
      {(provided, snapshot) => (
        <div  
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className="vidTile defaultTile flex-container-column shareTile"
          style={draggableStyle(snapshot, provided.draggableProps.style, props.id, props.large)}
          id={props.id}
        >
          <div {...provided.dragHandleProps} className="flex-container-row defaultTileHeader">
            <div className="flex-container-row full-width center overflow-hide">
                <p className="dataTileTitle">{`${userSharing} is sharing`}</p>
            </div>
            <div className="flex-container-row flex-end headerPadding">
              { props.large && 
                <FullScreenButton />
              }
            </div>
          </div>
          <div className="imgWrapper">
            <ScreenShareVideo/>
          </div>
        </div>
      )}
    </Draggable>
  );
}