import { arrayFetcher, blobFetcher, fetcher, poster } from './fetcher'
import useSWR from 'swr';

export function updateTiles(roomId, newSmall, newLarge){
  const body = {
    'dataTile00': newLarge,
    'dataTile01': newSmall
  }
  poster(`/api/me/tiles/${roomId}/updateTiles`, body).then(() => {
    return;
  })
}

export function updatePdfPage(teamId, roomId, fileName, newPage){
  const body = {
    newPage: newPage
  }

  poster(`/api/teams/assetLocker/${teamId}/${roomId}/${fileName}/updatePage`, body).then(() => {
    return;
  })
}

export function useDefaultAsset(teamId, roomId, fileName){
  const {data, error} = useSWR(`/api/teams/assetlocker/${teamId}/${roomId}/${fileName}/withdraw`, fetcher);
  return {
    data:data,
    isLoading: !error && !data,
    isError: error,
  }
}

export function useImageAsset(teamId, roomId, fileName){
  const {data, error} = useSWR(`/api/teams/assetlocker/${teamId}/${roomId}/${fileName}/withdraw`, blobFetcher);
  return {
    data:data,
    isLoading: !error && !data,
    isError: error,
  }
}

export function usePortableDocumentAsset(teamId, roomId, fileName){
  const {data, error} = useSWR(`/api/teams/assetlocker/${teamId}/${roomId}/${fileName}/withdraw`, arrayFetcher);
  return {
    data:data,
    isLoading: !error && !data,
    isError: error,
  }
}

export function getVNCAsset(roomId, url){
  const body = {
    roomId: roomId,
    requestedUrl: url
  }
  
  return new Promise((resolve, reject) => {
    poster('/api/cobrowse/init', body).then((res) => {
      resolve(res)
    }).catch((e) => {
      console.error(e)
      reject(e)
    })
  })
}

export function destroyVNCAsset(roomId, horusSerial){
  const options = {
    method: 'DELETE',
    credentials: 'include'
  }

  const URL = `${window._env_.REACT_APP_API_SERVER}/api/cobrowse/stop/${roomId}/${horusSerial}`

  return new Promise((resolve, reject) => {
    fetch(URL, options).then((res) => {
      const data = res.json()
      if(res.ok) resolve(data)
      reject(data)
    }).catch((e) => {
      reject(e)
    })
  })
}
