import LeaveCallButton from "buttons/LeaveCallButton";
import { useState } from "react";
import SpaceInfoButton from "buttons/SpaceInfoButton";
import AnnotationButton from "buttons/AnnotationButton"
import SpaceInfoModal from "./modals/SpaceInfoModal";
import ParticipantsModal from "./modals/ParticipantsModal";
import UploadAsset from "buttons/UploadAsset";
import UploadAssetModal from "./modals/UploadAssetModal";
import MessagingModal from "./modals/MessagingModal";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMeetingMembers } from "js/webex-controls";
import { assignSharing } from "app/meeting";
import { assignAudioDevice, assignDevices, assignVideoDevice, assignWebexSelf } from "app/user";
import { useRef } from "react";
import { useContext } from "react";
import { SocketContext } from "context/socket";
import { MessagingButton } from "buttons/MessagingButton";
import WhiteboardButton from "buttons/WhiteboardButton";
import ParticipantsButton from "buttons/ParticipantsButton";
import CalendarButton from "buttons/CalendarButton";
import ToggleVideoButton from 'buttons/ToggleVideoButton';
// import {show} from 'app/calendar'
import { NdrcButton } from 'buttons/NdrcButton';

export default function CallControls(props) {
   const socket = useContext(SocketContext)
   const checkParticipants = 'check-participants'
   const meetingSelector = useSelector(state => state.meeting)
   const cursors = useSelector(state => state.participants.cursors)
   const cursorRef = useRef(cursors)
   const userCursor = useSelector(state => state.user.cursorColor)
   const showCal = useSelector(state => state.calendar.show);

   const userCursorRef = useRef(userCursor)
   const dispatch = useDispatch()
   const [spaceInfoOpen, setSpaceInfoOpen] = useState(false)
   const [participantsOpen, setParticipantsOpen] = useState(false)
   const [uploadAssetOpen, setUploadAssetOpen] = useState(false)
   const [messagingOpen, setMessagingOpen] = useState(false)
   const [userSharing, setUserSharing] = useState(meetingSelector.sharing)
   const [messageNotification, setMessageNotification] = useState(false)


   useEffect(() => {
      cursorRef.current = cursors
      userCursorRef.current = userCursor
   })
   useEffect(() => {
      navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then((mediaStream) => {
         dispatch(assignAudioDevice({ label: mediaStream.getAudioTracks()[0].label }))
         dispatch(assignVideoDevice({ label: mediaStream.getVideoTracks()[0].label }))
         navigator.mediaDevices.enumerateDevices().then((devices) => {
            dispatch(assignDevices(devices))
         })
      });
   }, [])

   useEffect(() => {
      socket.on(checkParticipants, () => {
         const members = getMeetingMembers()
         for (let member in members) {
            if (members[member].isContentSharing) {
               dispatch(assignSharing(members[member].name))
               setUserSharing(members[member].name)
               let defaultColor = '#038C7F'
               if (members[member].isSelf) {
                  defaultColor = userCursorRef.current
               } else {
                  try {
                     defaultColor = cursorRef.current[members[member].id].color
                  } catch (error) {
                     defaultColor = '#038C7F'
                  }
               }
               try {
                  document.getElementsByClassName('share-screen-title')[0].firstChild.style.backgroundColor = defaultColor
                  document.getElementsByClassName('shareTile')[0].style.backgroundColor = defaultColor
                  document.getElementsByClassName('shareTile')[0].style.borderColor = defaultColor
                  if (!members[member].isSelf) {
                     document.getElementById('share-button-id').style.backgroundColor = defaultColor
                  }
               } catch (error) {
                  console.error('Unable to change ribbon color.', error)
               }
            }
            if (members[member].isSelf) {
               dispatch(assignWebexSelf(members[member]))
            }
         }
      })
   }, [])

   return (
      <>
         <div
            className="call-controls"
            style={{
               display: 'flex',
               flexDirection: 'row',
               width: '100%',
               height: '80px',
               // justifyContent: 'center',
               alignItems: 'center',
            }}
         >
            <div
               style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexGrow: 1,
                  height: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '40px',
               }}
            >
               <UploadAsset
                  uploadAssetOpen={uploadAssetOpen}
                  setUploadAssetOpen={setUploadAssetOpen}
               />
               <SpaceInfoButton
                  spaceInfoOpen={spaceInfoOpen}
                  setSpaceInfoOpen={setSpaceInfoOpen}
               />
               <AnnotationButton
                  annotationOpen={props.annotationOpen}
                  setAnnotationOpen={props.setAnnotationOpen}
               />
               <MessagingButton
                  messagingOpen={messagingOpen}
                  setMessagingOpen={setMessagingOpen}
                  messageNotification={messageNotification}
               />
               <WhiteboardButton />
               <CalendarButton />

               <ParticipantsButton
                  participantsOpen={participantsOpen}
                  setParticipantsOpen={setParticipantsOpen}
               />
               <ToggleVideoButton />
               <NdrcButton />
            </div>

            <div
               style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100px',
                  height: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
               }}
            >
               <LeaveCallButton
                  setLoading={props.setLoading}
                  setBlocking={props.setBlocking}
                  setLeaving={props.setLeaving}
                  accessLevel={props.accessLevel}
                  endCall={props.endCallBlock}
               />
            </div>

         </div>
         {/* <div>
            {props.accessLevel !== 'owner' &&
               <div className={props.smallListShow ? 'button-bumper' : 'button-bumper collapse'} />
            }
            {props.accessLevel === 'owner' &&
               <div className="buttonBox panelControls">
                  <UploadAsset
                     uploadAssetOpen={uploadAssetOpen}
                     setUploadAssetOpen={setUploadAssetOpen}
                  />
               </div>
            }
            <div className={`${props.smallListShow ? 'buttonControlsMain' : 'buttonControlsMain expand'} flex-container-row`}>
               <div className="full-width flex-container-row flex-between">
                  <div className="buttonBox Button-left">
                  </div>
                  <div className="buttonBox Button-center" style={{
                     display: 'flex',
                     flexDirection: 'row',
                     justifyContent: 'center',
                  }}>
                     <SpaceInfoButton spaceInfoOpen={spaceInfoOpen} setSpaceInfoOpen={setSpaceInfoOpen} />
                     <ParticipantsButton participantsOpen={participantsOpen} setParticipantsOpen={setParticipantsOpen} />
                     <MessagingButton messagingOpen={messagingOpen} setMessagingOpen={setMessagingOpen} messageNotification={messageNotification} />
                     <WhiteboardButton></WhiteboardButton>
                     <CalendarButton />
                     <AnnotationButton
                        annotationOpen={props.annotationOpen}
                        setAnnotationOpen={props.setAnnotationOpen}
                     />
                     <ToggleVideoButton />
                     <NdrcButton />
                  </div>
                  <div className="buttonBox Button-end">
                     <LeaveCallButton setLoading={props.setLoading} setBlocking={props.setBlocking} setLeaving={props.setLeaving} accessLevel={props.accessLevel} endCall={props.endCallBlock} />
                  </div>
               </div>
            </div>
         </div> */}
         <UploadAssetModal
            uploadAssetOpen={uploadAssetOpen}
            setUploadAssetOpen={setUploadAssetOpen}
            setTileListSmall={props.setTileListSmall}
            setTileListLarge={props.setTileListLarge}
            tileListSmall={props.tileListSmall}
            tileListLarge={props.tileListLarge}
         />
         <SpaceInfoModal
            spaceInfoOpen={spaceInfoOpen}
            setBlocking={props.setBlocking}
            setSpaceInfoOpen={setSpaceInfoOpen}
            accessLevel={props.accessLevel}
            setUserSharing={setUserSharing}
         />
         <ParticipantsModal
            participantsOpen={participantsOpen}
            setBlocking={props.setBlocking}
            setParticipantsOpen={setParticipantsOpen}
            accessLevel={props.accessLevel}
            setUserSharing={setUserSharing}
         />
         <MessagingModal
            messagingOpen={messagingOpen}
            setMessagingOpen={setMessagingOpen}
            messageNotification={messageNotification}
            setMessageNotification={setMessageNotification}
         />
      </>
   );
}