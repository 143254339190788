import { createSlice } from "@reduxjs/toolkit";

const ENDPOINTS = {

};

export const rdpSlice = createSlice({
   name: 'rdp',
   initialState: {
      available: true,
      show: false,
      loading: false,
      fetching: false,
      username: 'guacadmin',
      password: 'guacadmin',
      token: null,
      connections: null,
      msg: '',
   },
   reducers: {
      rdpReducer(state, action) {
         const payload = action?.payload;
         const key = payload?.key;
         const value = payload?.value;
         console.warn({ key, value });
         if (!key) return { ...state };
         return {
            ...state,
            [key]: value,
         }
      },
      available: (state, action) => {
         return {
            ...state,
            available: action.payload,
         }
      },
      show: (state, action) => {
         return {
            ...state,
            show: action.payload,
         }
      },
      loading: (state, action) => {
         return {
            ...state,
            loading: action.payload,
         }
      },
      fetching: (state, action) => {
         return {
            ...state,
            fetching: action.payload,
         }
      },
      token: (state, action) => {
         return {
            ...state,
            token: action.payload,
         }
      },
      connections: (state, action) => {
         return {
            ...state,
            connections: action.payload,
         }
      },
      msg: (state, action) => {
         return {
            ...state,
            msg: action.payload,
         }
      },
   }
})

export const { rdpReducer, available, show, loading, fetching, token, connections, msg } = rdpSlice.actions
export default rdpSlice.reducer