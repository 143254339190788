import { Dialog } from "@rmwc/dialog";
import { useRoom } from "net/rooms";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { Button } from "@rmwc/button";
import copy from 'copy-to-clipboard';
import { SocketContext } from "context/socket";
import { useDispatch } from "react-redux";
import { assignWebexSelf } from "app/user";
import { useRef } from "react";
import { assignSharing } from "app/meeting";
import './Modals.css'
import { updateRoom } from "app/room";

export default function SpaceInfoModal(props){
  const socket = useContext(SocketContext)
  const params = useParams()
  const dispatch = useDispatch()
  const room = useRoom(params.roomId);
  if (room.data && !room.isError) {
      dispatch(updateRoom(room.data));
  }
  const currentURL = `${window.location.origin}/r/${params.roomId}`;
  const [spaceName, setSpaceName] = useState('')
  const [copyText, setCopyText] = useState('Copy Link')
  const setWebexSelf = 'set-webex-self'
  const checkParticipants = 'check-participants'
  const [allParticipants, setAllParticipants] = useState([])
  const participantsRef = useRef(allParticipants)


  const determineSharing = () => {
    // let membersList = getMeetingMembers()
    let membersList = []
    for(let member in membersList){
      if(membersList[member].isContentSharing){
        dispatch(assignSharing(membersList[member].name))
        props.setUserSharing(membersList[member].name)
      }

      if(membersList[member].isSelf){
        dispatch(assignWebexSelf(membersList[member]))
      }
    }
  }

  const handleCopyClick = () => {
    setCopyText('Copied!')
    copy(currentURL);
    setTimeout(() => {
      props.setSpaceInfoOpen(false)
    }, 800)
  }

  const handleClose = () => {
    props.setSpaceInfoOpen(false)
    // add this so we don't see it transitioning back to
    // default text as the modal closes
    setTimeout(() => {
      setCopyText('Copy Link')
    }, 75)
  }

  const handleOpen = () => {
    determineSharing()
  }

  useEffect(() => {
    participantsRef.current = allParticipants
  })

  useEffect(() => {
    if(room.data){
      setSpaceName(room.data.name)
    }
  }, [room.data])

  useEffect(() => {
    determineSharing();
  }, [])


  // initilize members list without having to open modal
  useEffect(() => {
    socket.on(setWebexSelf, () => {
      handleOpen();
    })

    socket.on(checkParticipants, () => {
      determineSharing()
    })
  }, [])

  return(
    <>
      <Dialog preventOutsideDismiss={false}
        open={props.spaceInfoOpen}
        onClose={handleClose}
        onOpen={handleOpen}
        className="spaceInfo">
          <div className="flex-container-row flex-end headerPadding">
            <button className="tileClose btn" onClick={handleClose}>
              <span className="material-icons">clear</span>
            </button>
          </div>
        <h1 className="spaceInfoTitle">{spaceName}</h1>
        <div className="flex-container-row detailSection">
          <div className="flex-container-column spaceDetails">
            <h3 className="modalSubHeading">Space Details</h3>
            <div className="flex-container-column">
              <h5 className="subHeading">URL :</h5>
              <p className="detailText">{currentURL}</p>
            </div>
            <div className="copy-button" style = {{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <Button
              className="primePillBtn copyLink"
              onClick={handleCopyClick}>
              {copyText}
            </Button>
            </div>
          </div>
          </div>
      </Dialog>
    </>
  )
}