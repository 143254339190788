import { Fab } from '@rmwc/fab';
import { Tooltip } from '@rmwc/tooltip';
import './Buttons.css'
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openMM } from "app/mattermost"
import { updateShow } from "app/video"

export function MessagingButton(props) {
   const showMM = useSelector(state => state.mattermost.show)
   const dispatch = useDispatch()

   const [chat, setChat] = useState({ show: false, window: null });

   // useEffect(() => {
   //    if (chat?.window) return;
   //    const { innerHeight, innerWidth } = window;
   //    console.log(innerHeight, innerWidth);
   //    const mmChatHeight = 600;
   //    const mmChatWidth = 800;
   //    const left = (innerWidth / 2) - (mmChatWidth / 2);
   //    const top = (innerHeight / 2) - (mmChatHeight / 2);
   //    const mm = window.open("https://3data.cloud.mattermost.com/main/channels/town-square", "Mattermost", `top=${top},left=${left},width=${mmChatWidth},height=${mmChatHeight},status=no,toolbar=no,location=no,menubar=no,titlebar=no`);
   //    setChat((state) => {
   //       state.window = mm;
   //    });
   // }, [chat]);

   const createChatWindow = () => {
      // if (chat?.window) return;
      const { innerHeight, innerWidth } = window;
      const mmChatHeight = 600;
      const mmChatWidth = 800;
      const left = (innerWidth / 2) - (mmChatWidth / 2);
      const top = (innerHeight / 2) - (mmChatHeight / 2);
      const mm = window.open("https://3data.cloud.mattermost.com/main/channels/town-square", "Mattermost", `top=${top},left=${left},width=${mmChatWidth},height=${mmChatHeight},status=no,toolbar=no,location=no,menubar=no,titlebar=no`);
      setChat((prevState) => {
         console.log({ prevState });
         return {
            ...prevState,
            show: true,
            window: mm,
         }
      });
   }

   const handleClick = () => {
      // console.warn({ props });
      // props.setMessagingOpen(!props.messagingOpen)
      // if (!window) return;
      // createChatWindow();
      // console.log(chat);
      // if (!chat || !chat.window) {
      //    createChatWindow();
      // }
      // // //@@ If it should close @@//
      // const open = chat.show;
      // if (open) {
      //    chat.window.open();
      //    setChat(prev => {
      //       return {
      //          ...prev,
      //          show: true,
      //       }
      //    });
      //    return;
      // }
      // console.log(chat.window);
      // setChat(prev => {
      //    return {
      //       ...prev,
      //       show: false,
      //    }
      // });

      const newShowMM = !showMM;
      dispatch(openMM(!showMM));
      (newShowMM) && (dispatch(updateShow(false)));
   }

   return (
      <div className={`messagingButtonWrapper ${showMM ? 'closeButton' : ''}`}>
         {/* <Tooltip content="Messaging"> */}
         <Tooltip content="Chat">
            <Fab onClick={handleClick}
               // icon={showMM ? 'close' : 'chat'}
               icon='chat'
               mini
               className={`primePillBtn bbtn ${showMM ? 'active' : ''}`}>
            </Fab>
         </Tooltip>
         {props.messageNotification &&
            <span className="badge" />
         }
      </div>
   )
}