/**
 * How we display the name of the 3Data space users are using.
 */
import { useRoom } from 'net/rooms';
import { TopAppBar, TopAppBarRow, TopAppBarSection, TopAppBarTitle, TopAppBarFixedAdjust } from '@rmwc/top-app-bar';
import '@rmwc/top-app-bar/styles';
import { useParams } from "react-router";
import { Fab } from '@rmwc/fab';
import { Tooltip } from '@rmwc/tooltip';
// import ToggleVideoButton from 'buttons/ToggleVideoButton';
import Logo from './Logo';
import { useDispatch, useSelector } from 'react-redux';
import { updateRoom } from "app/room";

export default function Header() {
   const ENV = useSelector(state => state.env);
   const params = useParams()
   const room = useRoom(params.roomId);
   const dispatch = useDispatch();

   if (room.isLoading || room.isError) {
      document.title = `3Data | ${ENV.VERSION}`;
      return (<></>);
   }
   document.title = `${room.data.name} | ${ENV.VERSION}`;
   dispatch(updateRoom(room.data));
   return (
      <>
         <TopAppBar id="top_bar" dense>
            <TopAppBarRow style={{ height: '100%' }}>
               <TopAppBarSection style={{ height: '100%' }}>
                  <TopAppBarTitle style={{
                     height: '100%',
                     width: '100%',
                  }}>
                     {/* <div
                        style={{
                           width: '25%',
                           display: 'flex',
                           alignItems: 'center',
                           justifyContent: 'center',
                           height: '100%',
                           backgroundColor: 'black',
                           marginRight: '32px',
                        }}
                     >
                        <Logo width={125} />
                     </div> */}
                  </TopAppBarTitle>
               </TopAppBarSection>
            </TopAppBarRow>
         </TopAppBar>
         <TopAppBarFixedAdjust />
      </>
   );
}