import { Fab } from '@rmwc/fab';
import { Tooltip } from '@rmwc/tooltip';
import './Buttons.css'

export default function AnnotationButton(props) {
   const { setAnnotationOpen, annotationOpen } = props;
   const handleClick = () => {
      setAnnotationOpen(!annotationOpen);
   }

   return (
      <>
         <div className={`annotationButtonWrapper ${annotationOpen ? 'closeButton' : ''}`}>
            <Tooltip content={`${annotationOpen ? 'Hide' : ''} Annotations`}>
               <Fab onClick={handleClick}
                  mini
                  // className="primePillBtn"
                  className={`primePillBtn bbtn ${annotationOpen ? 'active' : ''}`}
                  // icon={annotationOpen ? 'close' : 'drive_file_rename_outline'} />
                  icon="drive_file_rename_outline" />
            </Tooltip>
         </div>
      </>
   )
}