
//Object.defineProperty(exports, "__esModule", { value: true });
/**
 * we would name this useSwr() per the docs,
 * however we need to call it conditionally in
 * useMyUser and break the rules of hooks.
 */
import swrHook from "swr";

var swr_1 = require("swr");
// eslint-disable-next-line no-unused-vars
var fetcher = require("net/fetcher");

export function useMyUser() {
    if (sessionStorage.getItem('guestToken') === null) {
      const {data, error} = swrHook('/api/me', fetcher);
      return {
        data:data,
        isLoading: !error && !data,
        isError: error,
      }
    } else {
      const {data, error} = swrHook('/api/auth/testGuestToken', fetcher);
      return {
        data:data,
        isLoading: !error && !data,
        isError: error,
      }
    }
}
export async function permitted(roomId){
  return new Promise((resolve, reject) => {
    fetch(window._env_.REACT_APP_API_SERVER + "/api/rooms/" + roomId + "/permits/accessLevel", {
      method: 'GET',
      credentials: 'include'
    }).then(res => {
      const accessData = res.json()
      if(res.ok){
        resolve(accessData);
      }
      reject(accessData);
    }).catch(accessErr => {
      reject(accessErr);
    })
  }); 
}

export function removeGuestToken() {
  let headers = {"x-access-token": sessionStorage.getItem('guestToken'), "x-room-id": sessionStorage.getItem('roomId')}
  let tokenBody = {email: sessionStorage.getItem('guestEmail'), roomId: sessionStorage.getItem('roomId')}
  return new Promise((resolve, reject) => {
    fetch(window._env_.REACT_APP_API_SERVER + "/api/auth/revokeGuestToken", {
      method: 'POST',
      credentials: 'include',
      headers: headers,
      body: JSON.stringify(tokenBody)
    }).then(res => {
      const accessData = res.json()
      if(res.ok){
        window.sessionStorage.clear()
        resolve(accessData);
      }
      reject(accessData);
    }).catch(accessErr => {
      reject(accessErr);
    })
  });
}
