import useSWR from "swr";
import { fetcher } from "./fetcher";

export function useRoom(id) {
   const { data, error } = useSWR(`/api/rooms/${id}`, fetcher);

   return {
      data: data,
      isLoading: !error && !data,
      isError: error,
   };
}
