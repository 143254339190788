import { Breadcrumbs as Wrapper, Item } from '@adobe/react-spectrum'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { assignLevel } from 'app/view';

export default function Breadcrumbs(props){
  const [level, setLevel] = useState([{
    key: 'all',
    title: 'All Tiles'
  }])
  const dispatch = useDispatch()
  const viewSelector = useSelector(state => state.view)

  const breadcrumbAction = (e) => {
    dispatch(assignLevel(e))
    if(e === 'all'){
      setLevel([{
        key: 'all',
        title: 'All Tiles'
      }])
    }
  }
  useEffect(() => {
    if(viewSelector.level !== 'all'){
      let newKey = undefined
      let newTitle = undefined
      for(let i = 0; i < props.tileList.length; i++){
        if(props.tileList[i]._id === viewSelector.level){
          newKey = viewSelector.level
          newTitle = props.tileList[i].title
        }
      }
      if(newKey !== undefined && newTitle !== undefined){
        setLevel((val) => [...val, {
          key: newKey,
          title: newTitle
        }])
      }
    } else {
      setLevel([{
        key: 'all',
        title: 'All Tiles'
      }])
    }
  }, [viewSelector.level])

  return(
    <>
      <Wrapper UNSAFE_className="tileBreadcrumbs" onAction={breadcrumbAction}>
        {
          level.map((val) => {
            return (
              <Item key={val.key}>{val.title}</Item>
            )
          })
        }
      </Wrapper>
    </>
  )
}