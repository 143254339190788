import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateWidth, openMM, setResize } from 'app/mattermost';
import { updateShow, updateHovering, updateFullscreen, updateWidth as updateVideoWidth, updateResize, updateInitialX } from 'app/video'

export default function MattermostChat() {
   const dispatch = useDispatch()
   const src = window._env_.REACT_APP_MATTERMOST_URL;
   const mmRef = useRef(null);
   const mmWidth = useSelector(state => state.mattermost.width)
   const mmShow = useSelector(state => state.mattermost.show)
   const resize = useSelector(state => state.mattermost.resize)
   const minWidth = useSelector(state => state.mattermost.minWidth);

   useEffect(() => {
      console.warn(mmWidth);
   },[mmWidth]);


   const videoState = useSelector(state => state.video);
   const {
      show: videoShow,
      // hovering,
      // fullscreen,
      width: videoWidth,
      minWidth: videoMinWidth,
      maxWidth: videoMaxWidth,
      resize: videoResize,
      initialX: videoInitialX,
   } = videoState;

   function onMouseDown(event) {
      dispatch(setResize({ resize: true, event, }));
   }

   function onMouseUp(event) {
      dispatch(setResize({ resize: false, event, }));
      dispatch(updateResize(false));
   }

   function onMouseMove() {
      if (!videoShow || !videoResize) return;
      console.log('video resizing~~~~');
   }

   return (
      <div
         id="mm_wrapper"
         className="mattermost-chat-wrapper"
         style={{
            // 'position': 'fixed',
            'position': 'relative',
            'width': mmShow ? `${mmWidth}px` : '0',
            'opacity': mmShow ? 1 : 0,
            // 'right': (mmShow) ? "0" : "-30%",
            // 'display': mmShow ? 'block' : 'none',
            // 'top': 0,
            // 'height': `calc(${window.innerHeight}px - 110px)`,
            'height': '100%',
            zIndex: 1000,
            'minWidth': mmShow ? `${minWidth}px` : '0',
            userSelect: 'none',
         }}
      >
         {/* <div id="mm_handle_bar"></div> */}
         <div
            id="mm_resizebar"
            style={{
               'position': 'absolute',
               'left': 0,
               'top': 0,
               'height': '100%',
               'width': resize ? '100%' : '10px',
               'zIndex': 1,
               'cursor': 'e-resize',
               'opacity': 0,
            }}
            onMouseDown={onMouseDown}
            onMouseUpCapture={onMouseUp}
         ></div>
         <div
            id="chat_resize_overlay"
            onMouseMove={onMouseMove}
            style={{
               'display': videoResize ? 'block' : 'none',
               'opacity': 0.1,
               position: 'absolute',
               top: 0,
               left: 0,
               height: '100%',
               width: '100%',
               background: 'grey',
               zIndex: 1,
            }}
         ></div>
         <iframe
            id="mm_iframe"
            src={src}
            // src='https://mm.staging-spaces.3data.io/'
            ref={mmRef}
            // src="https://mm.staging-spaces.3data.io/"
            title="mattermost-chat"
            sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
            allowFullScreen
            height="100%"
            width="100%"
         // onMouseMoveCapture={onMouseMove}
         ></iframe>
      </div>

   )
}