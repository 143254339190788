import React, { useState, useEffect, useRef, useContext } from 'react';
import { Draggable } from "react-beautiful-dnd";
import CanvasDraw from "react-canvas-draw";
import { SocketContext } from 'context/socket';
import { Image } from '@react-spectrum/image';
import CalendarTile from './CalendarTile';
import WhiteboardTile from './WhiteboardTile';
import {Grid, GridCell} from '@rmwc/grid';
import '@rmwc/grid/styles';
import '@rmwc/elevation/styles';

export default function UtilitiesTile(props){
	const utility = 'utility'
	const whiteboardClick = 'whiteboard-click'
	const calendarClick = 'calendar-click'
	const [utilClicked, setUtilClicked] = useState('');
	const [currentUtility, setCurrentUtility] = useState('');
	const currentUtilityRef = useRef(currentUtility);

	let utilOrder = ['calendar', 'whiteboard'];

  	const socket = useContext(SocketContext);

	useEffect(() => {
		socket.on(utility, (message) => {
			setCurrentUtility(message.message.util)
			localStorage.setItem('currentUtility', message.message.util)
		})
		return () => (
			socket.off(utility)
		)
	}, [])

  	const getDraggableStyleSmall = (snapshot, draggableStyle) =>  {

		let styleWithProps;
		if(!snapshot.isDragging){
		styleWithProps = {}
		}
		else{
		styleWithProps = Object.assign({}, draggableStyle)
		}
		if(snapshot.isDropAnimating){
		styleWithProps.transitionDuration = '0.001s'
		}
		styleWithProps.height = snapshot.isDragging ? '33%' : '100%'
		styleWithProps.paddingLeft = 'auto'
		styleWithProps.paddingRight = 'auto'
		return styleWithProps
	}

	const getDraggableStyleUtil = (snapshot, draggableStyle) =>  {

		let styleWithProps;
		if(!snapshot.isDragging){
		styleWithProps = {}
		}
		else{
		styleWithProps = Object.assign({}, draggableStyle)
		}
		if(snapshot.isDropAnimating){
		styleWithProps.transitionDuration = '0.001s'
		}
		styleWithProps.height = snapshot.isDragging ? '33%' : '100%'
		styleWithProps.width = snapshot.isDragging ? '50%' : '100%'
		return styleWithProps
	}

	const getDraggableStyleLarge = (snapshot, draggableStyle) =>  {
		let styleWithProps;
		if(!snapshot.isDragging){
		styleWithProps = {}
		}
		else{
		styleWithProps = Object.assign({}, draggableStyle)
		}
		if(snapshot.isDropAnimating){
		styleWithProps.transitionDuration = '0.001s'
		}
		styleWithProps.height = snapshot.isDragging ? '50%' : '100%'
		styleWithProps.width = snapshot.isDragging ? '50%' : '100%'
		return styleWithProps
	}

	return(
		<Draggable draggableId={props.id} index={props.index} isDragDisabled={props.accessLevel === 'owner' ? false : true}>
			{(provided, snapshot) => (
			<div  
				ref={provided.innerRef}
				{...provided.draggableProps}
				{...provided.dragHandleProps}
				style={props.large ? getDraggableStyleLarge(snapshot, provided.draggableProps.style) : getDraggableStyleSmall(snapshot, provided.draggableProps.style)}
				className="placeholderTile defaultTile flex-container-column"
			>
				<div
					className="flex-container-row defaultTileHeader">
					<p className="iframeTitle center">Utilities</p>
				</div>
				<div>
					<Grid>
						{
							utilOrder.map((util, index) => {
								if (util === 'calendar') {
									return (
										<GridCell span={6} key={index}>
											<Draggable draggableId={`utility-draggable-${index}`} index={index} isDragDisabled={props.accessLevel === 'owner' ? false : true}>
												{(provided, snapshot) => (
													<div
														ref={provided.innerRef}
														{...provided.draggableProps}
														{...provided.dragHandleProps}
														style={getDraggableStyleUtil(snapshot, provided.draggableProps.style)}
													>
														<div className="preview-util-tile">
															<CalendarTile
																id={`util-draggable-${index}`}
																index={props.index}
																large={props.large}
																accessLevel={props.accessLevel}
																utility={true}
															/>
														</div>
													</div>
												)}
											</Draggable>
										</GridCell>
									)
								}
								if (util === 'whiteboard') {
									return (
										<GridCell span={6} key={index}>
											<Draggable draggableId={`utility-draggable-${index}`} index={index} isDragDisabled={props.accessLevel === 'owner' ? false : true}>
												{(provided, snapshot) => (
													<div
														ref={provided.innerRef}
														{...provided.draggableProps}
														{...provided.dragHandleProps}
														style={getDraggableStyleUtil(snapshot, provided.draggableProps.style)}
													>
														<div className="preview-utility-tile">
															<WhiteboardTile
																id={`util-draggable-${index}`}
																index={props.index}
																large={props.large}
																accessLevel={props.accessLevel}
																utility={true}
															/>
														</div>
													</div>
												)}
											</Draggable>
										</GridCell>
									)
								}
							})
						}
					</Grid>
				</div> 
			</div>
			)}
		</Draggable>
	)
}