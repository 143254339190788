import { Button } from '@rmwc/button';
import { Tooltip } from '@rmwc/tooltip';
import { muteAudioPreview } from 'js/webex-controls';
export default function PreviewMuteAudioButton(props){

  const handleClick = () => {
    props.setAudioPreMuted(!props.audioPreMuted);
    muteAudioPreview(!props.audioPreMuted);
  }

  let toggleColor = {
    color: 'red',
  };

  return(
    <Tooltip content={props.audioPreMuted ? 'Unmute Microphone' : 'Mute Microphone'}>
      <Button
        onClick={handleClick}
        style={props.audioPreMuted ? toggleColor : {}}
        className="btnLeft"
        icon={props.audioPreMuted ? 'mic_off' : 'mic'}>
      </Button>
    </Tooltip>
  );
}