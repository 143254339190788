import { TextField, Button } from "@adobe/react-spectrum";
import { useMyUser } from "net/user";
import { useEffect } from "react";
import { useState } from "react";
import './Pages.css'
import { GoogleLogin } from "components/GoogleLogin";
import { useSelector } from 'react-redux';

// import io from 'socket.io-client'
// const test = io.connect('https://dev.3data.io', {
//    transports: ['websocket'],
//    path: '/hermes/space-123/',
// });
// console.log(test)


export default function Home() {
   const ENV = useSelector(state => state.env);
   const [email, setEmail] = useState('')

   const { data, isLoading, isError } = useMyUser()

   const query = new URLSearchParams(window.location.search)

   document.title = `Home | 3Data | ${ENV.VERSION}`;

   const handleSubmit = (email) => {
      let redirect = query.get('redirectTo') || window.location.href;
      window.location.href = `${window._env_.REACT_APP_API_SERVER}/api/auth/loginRouterPost?email=${email}&redirectTo=${redirect}`
   }

   useEffect(() => {
      if (data !== undefined && data.role !== undefined) {
         window.location.href = `${window.location.href}r/${data._id}`
      }
   }, [data])

   return (
      <>
         <div className="page-bg" />
         <div className="animation-wlarapper">
            <div className="particle particle-1"></div>
            <div className="particle particle-2"></div>
            <div className="particle particle-3"></div>
            <div className="particle particle-4"></div>
         </div>
         <div className="homePage">
            <img style={{ zIndex: 99 }} src="/logo.png" width="480" alt="" />
            <div className="login-input">

               {/* full-width default-input */}
               <input
                  className="login-field"
                  placeholder="Enter email address..."
                  type="text"
                  name="email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  style={{ color: "black", backgroundColor: "white" }}
               />
               {/* <TextField
                  UNSAFE_className="login-field"
                  placeholder="Enter email address..."
                  type="text"
                  name="email"
                  onChange={setEmail}
                  value={email}
                  style={{ 
                     color: "black", 
                     backgroundColor: "white",
                  }}
               /> */}

            </div>
            <Button
               UNSAFE_className="login-btn"
               variant="primary"
               aria-label="Send email"
               onPress={() => {
                  handleSubmit(email);
               }}
            >
               Login
            </Button>
         </div>
      </>
   )
}