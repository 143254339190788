import ImageTile from "./ImageTile";
import PortableDocumentTile from "./PortableDocumentTile";
import ScreenShareTile from "./ScreenShareTile";
import PlaceholderTile from "./PlaceholderTile";
import RemoteDesktopTile from "./RemoteDesktopTile";
import WhiteboardTile from "./WhiteboardTile";
import CalendarTile from "./CalendarTile";
import UtilitiesTile from "./UtilitiesTile";
import InlineFrameTile from "./InlineFrameTile";
import VNCTile from './VNCTile'
import Folder from './Folder'
import { Component } from 'react';

// Removed the export of this function component
// Below I created a new class component that will be the exported component for data tiles
// DataTile function component is built is still used within the class component
function DataTile(props){
  let file = props.item.fileName.split('.').pop().toLowerCase();
  switch (file) {
    case 'folder':
      return (
        <>
          <Folder
            id={props.id}
            index={props.index}
            item={props.item}
            large={props.large}
            accessLevel={props.accessLevel}
          />
        </>
      );
    case 'screen-share':
      return (
        <>
          <ScreenShareTile
            id={props.id}
            index={props.index}
            large={props.large}
            videoSize={props.videoSize}
            accessLevel={props.accessLevel}
          />
        </>
      );
    case 'utilities-tile':
      return (
        <>
          <UtilitiesTile
            id={props.id}
            index={props.index}
            large={props.large}
            accessLevel={props.accessLevel}
          />
        </>
      );
    case 'whiteboard':
      return (
        <>
          <WhiteboardTile
            id={props.id}
            index={props.index}
            large={props.large}
            accessLevel={props.accessLevel}
            utility={false}
          />
        </>
      );
    case 'calendar':
      return (
        <>
          <CalendarTile
            id={props.id}
            index={props.index}
            large={props.large}
            accessLevel={props.accessLevel}
            utility={false}
          />
        </>
      );
    case 'iframe':
      return (
        <>
          <InlineFrameTile
            id={props.id}
            item={props.item}
            index={props.index}
            large={props.large}
            accessLevel={props.accessLevel}
            utility={false}
          />
        </>
      );
    case 'vnc':
      return (
        <>
          <VNCTile
            id={props.id}
            item={props.item}
            index={props.index}
            large={props.large}
            accessLevel={props.accessLevel}
            thumbnail={props.thumbnail}
          />
        </>
      );
    case 'png':
    case 'jpg':
    case 'jpeg':
    case 'gif':
      return (
        <>
          <ImageTile
            id={props.id}
            index={props.index}
            item={props.item} 
            large={props.large}
            accessLevel={props.accessLevel}
          />
        </>
      );
    case 'pdf':
      return (
        <>
          <PortableDocumentTile
            id={props.id}
            index={props.index}
            item={props.item}
            large={props.large}
            accessLevel={props.accessLevel}
            pdfTracker={props.pdfTracker}
            thumbnail={props.thumbnail}
          />
        </>
      )
    case 'rdp':
      return (
        <>
          <RemoteDesktopTile
            id={props.id}
            index={props.index}
            item={props.item} 
            large={props.large}
            accessLevel={props.accessLevel}
          />
        </>
      );
    default:
      return (
        <>
          <PlaceholderTile
            id={props.id}
            index={props.index}
            accessLevel={props.accessLevel}
          />
        </>
      );
  }
}

// Adding a new class component to extend the Component class from React
// using shouldComponentUpdate to help with state of props and rerenders of components
class ADataTile extends Component {
  constructor(props) {
    super(props)
  }

  shouldComponentUpdate(nextProps) {
    let pageChange;
    if(this.props.pdfTracker !== undefined){
      if(this.props.pdfTracker[this.props.id]){
        this.props.pdfTracker[this.props.id].page === nextProps.pdfTracker[nextProps.id].page ? pageChange = false : pageChange = true
      }
    }
    if (JSON.stringify(nextProps.id) === JSON.stringify(this.props.id) && !pageChange) {
      return false;
    } else {
        return true;
    }
  }

  render() {
    return (
      <DataTile
        props={this.props}
        id={this.props.id}
        key={this.props.key}
        index={this.props.index}
        item={this.props.item}
        large={this.props.large}
        videoSize={this.props.videoSize}
        accessLevel={this.props.accessLevel}
        pdfTracker={this.props.pdfTracker}
      />
    )
  }
};

export default ADataTile;