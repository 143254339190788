import useSWR, { mutate } from 'swr';
import { fetcher, poster } from './fetcher';

export function isValidFileName(fileName){
  const regex = new RegExp(/^(?!\.)(?!com[0-9]$)(?!con$)(?!lpt[0-9]$)(?!nul$)(?!prn$)[^\|\*\?\\:<>/#$"]*[^\.\|\*\?\\:<>/#$"]+$/)
  return regex.test(fileName)
}

export function useAssets(roomId){
  const { data: room } = useSWR(`/api/rooms/${roomId}`, fetcher);
  const {data, error} = useSWR(() => `/api/teams/assetlocker/${room.teamId}/${roomId}/listAssets`, fetcher);
  return {
    data:data,
    isLoading: !error && !data,
    isError: error,
  }
}

export function uploadAsset(teamId, file){
  const formData = new FormData()
  formData.append('', file, file.name)

  const options = {
    method: 'POST',
    body: formData,
    credentials: 'include'
  }

  const URL = `${window._env_.REACT_APP_API_SERVER}/api/teams/${teamId}/addAsset`

  return new Promise((resolve, reject) => {
    fetch(URL, options).then((res) => {
      const data = res.json()
      if(res.ok) resolve(data)
      reject(data)
    }).catch((e) => {
      reject(e)
    })
  })
}

export function uploadRDPAsset(teamId, assetData){
  const options = {
    method: 'POST',
    body: JSON.stringify(assetData),
    credentials: 'include'
  }

  const URL = `${window._env_.REACT_APP_API_SERVER}/api/teams/${teamId}/addRdpAsset`;

  return new Promise((resolve, reject) => {
    fetch(URL, options).then((res) => {
      const data = res.json()
      if(res.ok) resolve(data)
      reject(data)
    }).catch((e) => {
      reject(e)
    })
  })
}

export function depositAssetLocker(teamId, roomId, fileName){
  const options = {
    method: 'POST',
    headers:{
      'Content-Type':'application/json'
    },
    credentials: 'include'
  }

  const URL = `${window._env_.REACT_APP_API_SERVER}/api/teams/assetLocker/${teamId}/${roomId}/${fileName}/deposit`

  return new Promise((resolve, reject) => {
    fetch(URL, options).then((res) => {
      const data = res.json()
      if(res.ok) resolve(data)
      reject(data)
    }).catch((e) => {
      reject(e)
    })
  })
}

export function unlinkAsset(teamId, roomId, fileName){
  const options = {
    method: 'DELETE',
    credentials: 'include'
  }

  const URL = `${window._env_.REACT_APP_API_SERVER}/api/teams/assetLocker/${teamId}/${roomId}/${fileName}/remove`

  return new Promise((resolve, reject) => {
    fetch(URL, options).then((res) => {
      const data = res.json()
      if(res.ok) resolve(data)
      reject(data)
    }).catch((e) => {
      reject(e)
    })
  })
}

export function updateAssetName(teamId, roomId, fileName, newName){
  let newWithExtension = `${newName}.${fileName.split('.').pop()}`
  if(!isValidFileName(newWithExtension)) return Promise.reject()
  const body = {
    newName: newWithExtension
  }

  return new Promise((resolve, reject) => {
    poster(`/api/teams/assetLocker/${teamId}/${roomId}/${fileName}/updateFileNameLive`, body).then((res) => {
      mutate('/api/rooms')
      resolve(res)
    }).catch((e) => {
      console.error(e)
      reject(e)
    })
  })
}

export function updateVirtualAssetName(roomId, id, newName){
  if(!isValidFileName(newName)) return Promise.reject()

  const body = {
    newName: newName
  }

  return poster(`/api/me/tiles/${roomId}/${id}/updateVirtualName`, body)
}
