import { Ripple } from '@rmwc/ripple';
import { assignFullScreen } from 'app/view';
import { useState, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { SocketContext } from "context/socket"
import { useSelector } from 'react-redux';
import { Tooltip } from '@rmwc/tooltip';

export default function FullScreenButton(props) {
   const dispatch = useDispatch()
   //   const [fullScreen, setFullScreen] = useState(false)
   const fullScreen = useSelector(state => state.view.fullScreen)

   const socket = useContext(SocketContext);

   useEffect(() => {
      const el = document.getElementsByClassName('largeTileClass')[0];
      el.classList[fullScreen ? 'add' : 'remove']('fullScreen');
   }, [fullScreen]);

   const handleClick = () => {
      // const el = document.getElementsByClassName('largeTileClass')[0];

      //  if(!fullScreen){
      //    dispatch(assignFullScreen(true))
      //    el.classList.add('fullScreen')
      //    // setFullScreen(true)
      //  } else {
      //    el.classList.remove('fullScreen')
      //    dispatch(assignFullScreen(false))
      //    // setFullScreen(false)
      //  }
      const newFullscreen = !fullScreen;
      socket.emit('large-tile-fullscreen', newFullscreen);
      // dispatch(assignFullScreen(newFullscreen));
      // el.classList[fullScreen ? 'remove' : 'add']('fullScreen');
   }

   return (

      // <Ripple primary>
      //    <button className="generic btn" onClick={handleClick}>
      //       <Tooltip content={`${fullScreen ? 'Exit' : 'Enable'} Fullscreen`}>
      //          <span className="material-icons">
      //             {fullScreen ? 'fullscreen_exit' : 'fullscreen'}
      //          </span>
      //       </Tooltip>
      //    </button>
      // </Ripple>
      <Tooltip
         content={`${fullScreen ? 'Disable' : 'Enable'} Fullscreen`}
      >
         <div className={`fullscreen-btn ${fullScreen ? 'disable' : 'enable'}`} onClick={handleClick}>
            <div className="triangle triangle-1"></div>
            <div className="triangle triangle-2"></div>
         </div>
      </Tooltip>

   )
}