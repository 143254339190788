import { createSlice } from "@reduxjs/toolkit";

export const guestSlice = createSlice({
  name: "env",
  initialState: {
    displayName: null,
  },
  reducers: {
    updateGuestDisplayName(state, action) {
      return {
        ...state,
        displayName: action.payload,
      };
    },
  },
});

export const { updateGuestDisplayName } = guestSlice.actions;
export default guestSlice.reducer;
