import { Tooltip } from "@rmwc/tooltip";
import { SocketContext } from "context/socket";
import { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export default function SidebarToggle(props) {
   const socket = useContext(SocketContext)
   const showSmallList = 'show-small-list'
   const dispatch = useDispatch();

   const handleClick = (e) => {
      props.setSmallListShow(!props.smallListShow)
      socket.emit(showSmallList, !props.smallListShow)
   }

   return (
      <>
         <Tooltip content={props.smallListShow ? 'Hide Content' : 'Show Content'}>
            <button className={props.smallListShow ? 'sidebar-toggle' : 'sidebar-toggle collapse'} onClick={handleClick}>
               <span className="material-icons">{props.smallListShow ? 'navigate_before' : 'navigate_next'}</span>
            </button>
         </Tooltip>
      </>
   )
} 