import {
   createSlice
} from "@reduxjs/toolkit";

const importEnvs = [
   "REACT_APP_ENTITY",
   "REACT_APP_API_SERVER",
   "REACT_APP_VERSION",
   "REACT_APP_JITSI_DOMAIN",
   "REACT_APP_CURSOR_DEBUG",
   "REACT_APP_CURSOR_TRACK_TIME",
];

const initialState = {};
for (const prop in process.env) {
   if (importEnvs.includes(prop)) {
      let val = process.env[prop];
      const format = val?.toLowerCase().trim();
      val = (format === 'false' || format === 'true') ? JSON.parse(val) : val;
      const propShort = prop.replace('REACT_APP_', '');
      initialState[propShort] = val;
   }
}

export const envSlice = createSlice({
   name: 'env',
   initialState,
   reducers: {
      initEnv(state, action) {
         const payload = action.payload;
         const isObject = typeof payload === 'object' && !Array.isArray(payload);
         const isValid = Object.keys(isObject).length > 0;
         if (!isObject || !isValid) throw new Error(`Invalid ENV variables!`);

         return {
            ...state,
            ...payload,
         }
      }
   }
})

export const {
   initEnv,
} = envSlice;
export default envSlice.reducer