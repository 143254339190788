/**
 * Video element holding your large
 * scale video preview prior to entering the space
 */
import AudioSelection from 'buttons/video/AudioSelection';
import JoinSpaceButton from 'buttons/JoinSpaceButton';
import PreviewMuteAudioButton from 'buttons/video/PreviewMuteAudioButton';
import PreviewMuteVideoButton from 'buttons/video/PreviewMuteVideoButton';
import VideoSelection from 'buttons/video/VideoSelection';
import { getUserMedia } from 'js/webex-controls';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { assignAudioDevice, assignDevices, assignVideoDevice } from 'app/user';

import GuestLogin from './GuestLogin';
import { TextField } from '@rmwc/textfield';
import { useLayoutEffect } from 'react';
import { useRef } from 'react';

export default function VideoPreview(props) {

   const dispatch = useDispatch()
   const [videoInit, setVideoInit] = useState(false)
   const [validInput, setValidInput] = useState(true);

   const nameInput = useRef(null)

   useEffect(() => {
      getUserMedia({ audio: true, video: true }).then((mediaStream) => {
         if (mediaStream !== undefined) {
            dispatch(assignAudioDevice({ label: mediaStream.getAudioTracks()[0].label }))
            dispatch(assignVideoDevice({ label: mediaStream.getVideoTracks()[0].label }))
         }
         setVideoInit(document.getElementById('self-view-preview').srcObject !== null || mediaStream === undefined)
         navigator.mediaDevices.enumerateDevices().then((devices) => {
            dispatch(assignDevices(devices))
         })
      })
   }, [])

   useLayoutEffect(() => {
      if (!props.isUserLogin) {
         nameInput.current.addEventListener('input', (evt) => {
            if (evt.target.value && evt.target.value.length === 1) {
               setValidInput(true);
               return;
            }
            if (!evt.target.value) {
               setValidInput(false)
               return;
            }
         })
      }
   }, [])

   const isLoggedInUser = !!props.isUserLogin;

   // if authenticated user
   // if (props.isUserLogin) {
   //    return (
   //       <>
   //          <div className="vidTile Prev center">
   //             <div id="video-prev">
   //                <video autoPlay muted width={props.videoSize * (window.screen.width)} height={props.videoSize * (window.screen.height)} src="" id="self-view-preview"></video>
   //             </div>
   //          </div>

   //          {/* buttons and controls */}

   //          <div className="previewControlWrapper flex-container-row">
   //             <div className="resposiveControlBar">
   //                <div className="flex-container-row flex-between">
   //                   <div className="lobby full-width flex-container-row">
   //                      <div className="buttonBox Float-center">

   //                         <ul className="button-group">
   //                            <li><PreviewMuteVideoButton setVideoPreMuted={props.setVideoPreMuted} videoPreMuted={props.videoPreMuted} /></li>
   //                            <li><VideoSelection setVideoPreMuted={props.setVideoPreMuted} videoPreMuted={props.videoPreMuted} audioPreMuted={props.audioPreMuted} /></li>
   //                         </ul>
   //                         <ul className="button-group">
   //                            <li><PreviewMuteAudioButton setAudioPreMuted={props.setAudioPreMuted} audioPreMuted={props.audioPreMuted} /></li>
   //                            <li><AudioSelection setAudioPreMuted={props.setAudioPreMuted} audioPreMuted={props.audioPreMuted} videoPreMuted={props.videoPreMuted} /></li>
   //                         </ul>

   //                      </div>
   //                      <JoinSpaceButton
   //                         roomId={props.roomId}
   //                         audioPreMuted={props.audioPreMuted}
   //                         videoPreMuted={props.videoPreMuted}
   //                         videoInit={videoInit}
   //                      />
   //                   </div>
   //                </div>
   //             </div>
   //          </div>


   //       </>
   //    )
   //    // if guest
   // } else {
   //    return (
   //       <>
   //          <div className="vidTile Prev center">
   //             <div id="video-prev">
   //                <video autoPlay muted width={props.videoSize * (window.screen.width)} height={props.videoSize * (window.screen.height)} src="" id="self-view-preview"></video>
   //             </div>
   //          </div>

   //          {/* buttons and controls */}

   //          <div className="previewControlWrapper flex-container-row">
   //             <div className="resposiveControlBar">
   //                <div className="flex-container-row flex-between">
   //                   <div className="lobby full-width flex-container-row">
   //                      <div className="buttonBox Float-center">

   //                         <TextField className="guestLoginField" label="Enter Display Name" required inputRef={nameInput} invalid={validInput ? false : true} />
   //                         <ul className="button-group">
   //                            <li><PreviewMuteVideoButton setVideoPreMuted={props.setVideoPreMuted} videoPreMuted={props.videoPreMuted} /></li>
   //                            <li><VideoSelection setVideoPreMuted={props.setVideoPreMuted} videoPreMuted={props.videoPreMuted} audioPreMuted={props.audioPreMuted} /></li>
   //                         </ul>
   //                         <ul className="button-group">
   //                            <li><PreviewMuteAudioButton setAudioPreMuted={props.setAudioPreMuted} audioPreMuted={props.audioPreMuted} /></li>
   //                            <li><AudioSelection setAudioPreMuted={props.setAudioPreMuted} audioPreMuted={props.audioPreMuted} videoPreMuted={props.videoPreMuted} /></li>
   //                         </ul>

   //                      </div>
   //                      <GuestLogin
   //                         validInput={validInput}
   //                         setValidInput={setValidInput}
   //                         nameInput={nameInput}
   //                         audioPreMuted={props.audioPreMuted}
   //                         videoPreMuted={props.videoPreMuted}
   //                         videoInit={videoInit}
   //                         setLoading={props.setLoading}
   //                         setAdmitMessage={props.setAdmitMessage}
   //                      />
   //                   </div>
   //                </div>
   //             </div>
   //          </div>
   //       </>
   //    )
   // }

   return (
      <>
         <div className="vidTile Prev center">
            <div id="video-prev">
               <video autoPlay muted width={props.videoSize * (window.screen.width)} height={props.videoSize * (window.screen.height)} src="" id="self-view-preview"></video>
            </div>
         </div>

         <div className="previewControlWrapper flex-container-row">
            <div className="resposiveControlBar">
               <div className="flex-container-row flex-between">
                  <div className="lobby full-width flex-container-row">
                     <div className="buttonBox Float-center">
                        {
                           !isLoggedInUser &&
                           <TextField className="guestLoginField" label="Enter Display Name" required inputRef={nameInput} invalid={validInput ? false : true} />
                        }
                        <ul className="button-group">
                           <li><PreviewMuteVideoButton setVideoPreMuted={props.setVideoPreMuted} videoPreMuted={props.videoPreMuted} /></li>
                           <li><VideoSelection setVideoPreMuted={props.setVideoPreMuted} videoPreMuted={props.videoPreMuted} audioPreMuted={props.audioPreMuted} /></li>
                        </ul>
                        <ul className="button-group">
                           <li><PreviewMuteAudioButton setAudioPreMuted={props.setAudioPreMuted} audioPreMuted={props.audioPreMuted} /></li>
                           <li><AudioSelection setAudioPreMuted={props.setAudioPreMuted} audioPreMuted={props.audioPreMuted} videoPreMuted={props.videoPreMuted} /></li>
                        </ul>
                     </div>
                     {
                        (isLoggedInUser)
                           ? <JoinSpaceButton
                              roomId={props.roomId}
                              audioPreMuted={props.audioPreMuted}
                              videoPreMuted={props.videoPreMuted}
                              videoInit={videoInit}
                           />
                           : <GuestLogin
                              validInput={validInput}
                              setValidInput={setValidInput}
                              nameInput={nameInput}
                              audioPreMuted={props.audioPreMuted}
                              videoPreMuted={props.videoPreMuted}
                              videoInit={videoInit}
                              setLoading={props.setLoading}
                              setAdmitMessage={props.setAdmitMessage}
                           />
                     }
                  </div>
               </div>
            </div>
         </div>
      </>
   )
}
