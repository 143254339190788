/**
 * the fetcher used with SWR
 * specifies credentials, but other than that a 
 * pretty straightforward request
 */
export async function fetcher(endpoint) {
   if (sessionStorage.getItem('guestToken') !== null) {
      let headers = { "x-access-token": sessionStorage.getItem('guestToken'), "x-room-id": sessionStorage.getItem('roomId') }
      return new Promise((resolve, reject) => {
         fetch(
            `${window._env_.REACT_APP_API_SERVER}${endpoint}`,
            {
               method: 'GET',
               headers: headers,
               credentials: 'include',

            }
         )
            .then(res => {
               if (!res.ok) reject();

               res.json().then(data => {
                  resolve(data);
               });
               //  const data = res.json()
               //  if(res.ok){
               //    resolve(data);
               //  }
               //but we also should reject if network
               //succeeds but api returns >400 code
               //  reject(data); 
            })
            .catch(err => { // fetch api will error on network problems
               reject(err);
            })
      });
   } else {

      return new Promise((resolve, reject) => {
         fetch(
            `${window._env_.REACT_APP_API_SERVER}${endpoint}`,
            {
               method: 'GET',
               credentials: 'include'
            }
         )
            .then(res => {
               const data = res.json()
               if (res.ok) {
                  resolve(data);
               }
               //but we also should reject if network
               //succeeds but api returns >400 code
               reject(data);
            })
            .catch(err => { // fetch api will error on network problems
               reject(err);
            })
      });
   }
}

// for image files
export async function blobFetcher(endpoint) {
   if (sessionStorage.getItem('guestToken') !== null) {
      let headers = { "x-access-token": sessionStorage.getItem('guestToken'), "x-room-id": sessionStorage.getItem('roomId') }
      return new Promise((resolve, reject) => {
         fetch(
            `${window._env_.REACT_APP_API_SERVER}${endpoint}`,
            {
               method: 'GET',
               headers: headers,
               credentials: 'include',

            }
         )
            .then(res => {
               const data = res.blob()
               if (res.ok) {
                  resolve(data);
               }
               //but we also should reject if network
               //succeeds but api returns >400 code
               reject(data);
            })
            .catch(err => { // fetch api will error on network problems
               reject(err);
            })
      });
   } else {
      return new Promise((resolve, reject) => {
         fetch(
            `${window._env_.REACT_APP_API_SERVER}${endpoint}`,
            {
               method: 'GET',
               credentials: 'include'
            }
         )
            .then(res => {
               const data = res.blob()
               if (res.ok) {
                  resolve(data);
               }
               //but we also should reject if network
               //succeeds but api returns >400 code
               reject(data);
            })
            .catch(err => { // fetch api will error on network problems
               reject(err);
            })
      });
   }

}

// for pdf files
export async function arrayFetcher(endpoint) {
   if (sessionStorage.getItem('guestToken') !== null) {
      let headers = { "x-access-token": sessionStorage.getItem('guestToken'), "x-room-id": sessionStorage.getItem('roomId') }
      return new Promise((resolve, reject) => {
         fetch(
            `${window._env_.REACT_APP_API_SERVER}${endpoint}`,
            {
               method: 'GET',
               headers: headers,
               credentials: 'include',

            }
         )
            .then(res => {
               const data = res.arrayBuffer()
               if (res.ok) {
                  resolve(data);
               }
               //but we also should reject if network
               //succeeds but api returns >400 code
               reject(data);
            })
            .catch(err => { // fetch api will error on network problems
               reject(err);
            })
      });
   } else {
      return new Promise((resolve, reject) => {
         fetch(
            `${window._env_.REACT_APP_API_SERVER}${endpoint}`,
            {
               method: 'GET',
               credentials: 'include',
            }
         )
            .then(res => {
               const data = res.arrayBuffer()
               if (res.ok) {
                  resolve(data);
               }
               //but we also should reject if network
               //succeeds but api returns >400 code
               reject(data);
            })
            .catch(err => { // fetch api will error on network problems
               reject(err);
            })
      });
   }

}

export async function poster(endpoint, body) {
   if (sessionStorage.getItem('guestToken') !== null) {
      let headers = { "x-access-token": sessionStorage.getItem('guestToken'), "x-room-id": sessionStorage.getItem('roomId') }
      return new Promise((resolve, reject) => {
         fetch(
            `${window._env_.REACT_APP_API_SERVER}${endpoint}`,
            {
               method: 'POST',
               body: JSON.stringify(body),
               headers: headers,
               credentials: 'include',
            }
         )
            .then(res => {
               const data = res.json()
               if (res.ok) {
                  resolve(data);
               }
               //but we also should reject if network
               //succeeds but api returns >400 code
               reject(data);
            })
            .catch(err => { // fetch api will error on network problems
               reject(err);
            })
      });
   } else {
      return new Promise((resolve, reject) => {
         fetch(
            `${window._env_.REACT_APP_API_SERVER}${endpoint}`,
            {
               method: 'POST',
               body: JSON.stringify(body),
               credentials: 'include'
            }
         )
            .then(res => {
               const data = res.json()
               if (res.ok) {
                  resolve(data);
               }
               //but we also should reject if network
               //succeeds but api returns >400 code
               reject(data);
            })
            .catch(err => { // fetch api will error on network problems
               reject(err);
            })
      });
   }
}
export async function regPoster(endpoint, body) {
   return new Promise((resolve, reject) => {
      fetch(
         `${window._env_.REACT_APP_API_SERVER}${endpoint}`,
         {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
               'Content-Type': 'application/json'
            },
            credentials: 'include'
         }
      )
         .then(res => {
            const data = res.json()
            if (res.ok) {
               resolve(data);
            }
            //but we also should reject if network
            //succeeds but api returns >400 code
            reject(data);
         })
         .catch(err => { // fetch api will error on network problems
            reject(err);
         })
   });
}

