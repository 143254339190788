import {
   configureStore,
   getDefaultMiddleware
} from '@reduxjs/toolkit'
import userReducer from './user'
import spaceReducer from './space'
import viewReducer from './view'
import meetingReducer from './meeting'
import participantsReducer from './participants'
import messagesReducer from './messages'
import slackReducer from './slack'
import mattermostReducer from './mattermost'
import annotationsReducer from './annotation';
import calendarReducer from './calendar';
import videoReducer from './video';
import rdpReducer from './rdp'
import ndrcReducer from './ndrcpip'
import modalReducer from './modal'
import envReducer from './env'
import guestReducer from './guest'
import roomReducer from './room'

export default configureStore({
   reducer: {
      env: envReducer,
      user: userReducer,
      space: spaceReducer,
      view: viewReducer,
      meeting: meetingReducer,
      participants: participantsReducer,
      messages: messagesReducer,
      slack: slackReducer,
      mattermost: mattermostReducer,
      annotations: annotationsReducer,
      calendar: calendarReducer,
      video: videoReducer,
      rdp: rdpReducer,
      ndrc: ndrcReducer,
      modal: modalReducer,
      guest: guestReducer,
      room: roomReducer,
   },
   middleware: (getDefaultMiddleware) => getDefaultMiddleware({
      serializableCheck: {
         ignoredPaths: ['user.availableDevices'],
         ignoredActionPaths: ['payload']
      }
   })
})