import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const getUserList = createAsyncThunk("user/getUserList", async (thunkAPI) => {
  const baseURL = window._env_.REACT_APP_NEW_BACKEND_URL;
  const url = `${baseURL}/user-list`;
  const response = await fetch(url);
  if (!response.ok) return;
  const json = await response.json();
  if (!json.status) return;
  const data = json.data;
  return data;
});

export const userSlice = createSlice({
  name: "user",
  initialState: {
    _id: undefined,
    email: undefined,
    name: undefined,
    guest: true,
    owner: false,
    webex: {},
    cursorColor: "black",
    availableDevices: [],
    audioDevice: {},
    videoDevice: {},
    userList: [],
    displayName: null,
    isOwner: false, // Same as: props.accessLevel === 'owner'.
  },
  reducers: {
    assignId: (state, action) => {
      return {
        ...state,
        _id: action.payload,
      };
    },
    assignEmail: (state, action) => {
      return {
        ...state,
        email: action.payload,
      };
    },
    assignName: (state, action) => {
      return {
        ...state,
        name: action.payload,
      };
    },
    assignGuest: (state, action) => {
      return {
        ...state,
        guest: action.payload,
      };
    },
    assignOwner: (state, action) => {
      return {
        ...state,
        owner: action.payload,
      };
    },
    assignWebexSelf: (state, action) => {
      return {
        ...state,
        webex: { ...action.payload },
      };
    },
    assignCursorColor: (state, action) => {
      return {
        ...state,
        cursorColor: action.payload,
      };
    },
    assignDevices: (state, action) => {
      return {
        ...state,
        availableDevices: [...action.payload],
      };
    },
    assignAudioDevice: (state, action) => {
      return {
        ...state,
        audioDevice: {
          ...state.audioDevice,
          ...action.payload,
        },
      };
    },
    assignVideoDevice: (state, action) => {
      return {
        ...state,
        videoDevice: {
          ...state.audioDevice,
          ...action.payload,
        },
      };
    },
    updateUserList: (state, action) => {
      const payload = action.payload;
      return {
        ...state,
        userList: payload,
      };
    },
    updateDisplayName: (state, action) => {
      return {
        ...state,
        displayName: action.payload,
      };
    },
    updateIsOwner: (state, action) => {
      return {
         ...state,
         isOwner: action.payload,
      }
    },
  },
  extraReducers: {
    [getUserList.fulfilled]: (state, actions) => {
      console.log("getUserList.fulfilled", { state, actions });
    },
    [getUserList.pending]: (state, actions) => {
      console.log("getUserList.pending", { state, actions });
    },
    [getUserList.rejected]: (state, actions) => {
      console.log("getUserList.rejected", { state, actions });
    },
  },
});

export const {
  assignId,
  assignName,
  assignOwner,
  assignWebexSelf,
  assignCursorColor,
  assignDevices,
  assignAudioDevice,
  assignVideoDevice,
  assignEmail,
  assignGuest,
  updateUserList,
  updateDisplayName,
  updateIsOwner,
} = userSlice.actions;
export default userSlice.reducer;
