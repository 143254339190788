import { Divider } from "@react-spectrum/divider";
import { Button } from "@rmwc/button";
import { Badge, BadgeAnchor } from '@rmwc/badge'
import { assignSlackMessenger } from "app/slack";
import { useToken, sendInviteMessage, sendSlackMessage } from 'net/slack';
import { useRef, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router";
import '../Components.css'
import SlackMessagingContent from "./SlackMessagingContent";
import { addNewMessage } from "app/messages";
import { assignSlackSelf } from "app/slack";

export default function SlackMessagingTab(props){
  const params = useParams()
  const user = useSelector(state => state.user)
  const slackMessengerStore = useSelector(state => state.slack.slackMessenger)
  const { data, isLoading, isError } = useToken(user._id, params.roomId)
  const dataRef = useRef(data)
  const [loading, setLoading] = useState(true)
  const [hasToken, setHasToken] = useState(false)
  const [slackMessenger, setSlackMessenger] = useState(slackMessengerStore.active)

  useEffect(() =>{
    dataRef.current = data
  })

  useEffect(() => {
    if(isError){
      setLoading(false)
      setHasToken(false)
    }
    if(isLoading){
      setLoading(true)
    }
    if(data !== undefined){
      setLoading(false)
      if(data) setHasToken(true)
      else setHasToken(false)
    }
  }, [data, isLoading, isError])

  
  const handleConnectClick = () => {
    setLoading(true)
    let authWin = window.open(`${window._env_.REACT_APP_API_SERVER}/api/slack/${user._id}/slackAuth`, '', 'width=700,height=500,top=500,left=700,scrollbars=yes')
    let checkAuthWinClosed = setInterval(() => {
      if(authWin.closed){
        setLoading(false)
        clearInterval(checkAuthWinClosed)
      }
    }, 1000)
  }

  if(loading){
    return(
      <>
        <div className="flex-container-column center connectSlack">
          <img src="/assets/tile-loading.svg"/>
        </div>
      </>
    )
  }

  if(!hasToken){
    return(
      <>
        <div className="flex-container-column center connectSlack">
          <img src="/assets/connect-to-slack.png"/>
          <Button className="primePillBtn" label="Connect to Slack" onClick={handleConnectClick}></Button>
        </div>
      </>
    )
  }

  if(hasToken){
    return(
      <>
        <div className={`flex-container-column connectSlack ${slackMessenger ? 'slackMessenger' : ''}`}>
          { !slackMessenger && 
            <SlackList setSlackMessenger={setSlackMessenger} />
          }
          { slackMessenger && 
            <SlackMessenger slackMessenger={slackMessenger} setSlackMessenger={setSlackMessenger} />
          }
        </div>
      </>
    )
  }

  return(
    <>
      <div className="flex-container-column center connectSlack">
        <img src="/assets/connect-to-slack.png"/>
        <Button className="primePillBtn" label="Connect to Slack" onClick={handleConnectClick}></Button>
      </div>
    </>
  )
}

export function SlackList(props){
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const slackUsers = useSelector(state => state.slack.allUsers)
  const user = useSelector(state => state.user)
  const params = useParams()
  const currentURL = `${window.location.origin}/r/${params.roomId}`;

  // useEffect(() => {
  //   if(isError){
  //     setLoading(false)
  //   }
  //   if(isLoading){
  //     setLoading(true)
  //   }
  //   if(data !== undefined){
  //     setLoading(false)
  //     data.forEach((slackUser) => {
  //       if(slackUser.email === user.email) dispatch(assignSlackSelf(slackUser))
  //     })
  //   }
  // }, [data, isLoading, isError])

  useEffect(() =>{
    if(slackUsers.length !== 0) setLoading(false)
  }, [slackUsers])

  const handleInviteClick = (e) => {
    let el = e.target.classList.contains('mdc-button__ripple') ? e.target.offsetParent : e.target
    let ind = parseInt(el.id.split('-')[1])

    sendInviteMessage(user.name, currentURL, slackUsers[ind].id).then((res) => {
      if(res.ok){
        el.innerText = `Invited!`

        setTimeout(() => {
          el.innerText = 'Invite'
        }, 5000)

        return
      }
    })
  }

  const handleMessengerClick = (e) => {
    let ind = parseInt(e.target.offsetParent.id.split('-')[2])

    dispatch(assignSlackMessenger({
      ...slackUsers[ind],
      active: true
    }))
    props.setSlackMessenger(true)
  }

  if(loading){
    return(
      <>
        <div className="flex-container-column center connectSlack">
          <img src="/assets/tile-loading.svg"/>
        </div>
      </>
    )
  }
  if(slackUsers.length !== 0){
    return(
      <>
        <div className="slackList flex-container-column">
          {
            slackUsers.map((slackUser, ind) => {
              return(
                <>
                  <div className="slackListItem">
                    <BadgeAnchor>
                      <img src={slackUser.pfp} />
                      <Badge className="slack-badge" style={{backgroundColor: `${slackUser.presence === 'active' ? '#038C7F' : '#b00020'}`}} align='end' inset='10px'/>
                    </BadgeAnchor>
                    <div className="flex-container-column space-between slackMessage">
                      <p>{slackUser.name}</p>
                      <div className="slackInviteWrapper flex-container-row">
                        <Button className="primePillBtn" onClick={handleInviteClick} id={`invite-${ind}`}>Invite</Button>
                        <Button className="primePillBtn slackConvo" icon="chat" id={`slack-chat-${ind}`} onClick={handleMessengerClick}></Button>
                      </div>
                    </div>
                  </div>
                </>
              )
            })
          }
        </div>
      </>
    )
  }
  
  return(
    <>
      <div className="flex-container-column center connectSlack">
        <img src="/assets/tile-loading.svg"/>
      </div>
    </>
  )
}

export function SlackMessenger(props){
  const dispatch = useDispatch()
  const slackMessenger = useSelector(state => state.slack.slackMessenger)
  const slackSelf = useSelector(state => state.slack.slackSelf)
  const [sendDisabled, setSendDisabled] = useState(true)
  const messageRef = useRef()

  const handleExitClick = () => {
    dispatch(assignSlackMessenger({
      active: false,
      name: undefined,
      pfp: undefined
    }))
    props.setSlackMessenger(false)
  }

  const handleMessageChange = () =>{
    setSendDisabled(messageRef.current.value === "" ? true : false)
  }

  const handleSendClick = () => {
    if(messageRef.current.value){
      let time = new Date()
      let hours = time.getHours()
      let suffix = 'AM'
      if(hours > 12){
        hours -= 12
        suffix = 'PM'
      }
      let min = time.getMinutes()
      if(min < 10) min = `0${min}`
      dispatch(addNewMessage({[slackMessenger.channel]: {
        id: slackSelf.id,
        text: messageRef.current.value,
        timestamp: `${hours}:${min} ${suffix}`,
        pfp: slackSelf.pfp,
        name: slackSelf.name
      }}))
      sendSlackMessage(messageRef.current.value, slackMessenger.id)
      messageRef.current.value = ''
    }
  }

  const handleKeyPress = (e) => {
    if(e.charCode === 13) handleSendClick()
  }

  return(
    <>
      <div className="flex-container-row full-width sMessengerHeader">
        <Button className="primePillBtn exit" icon="arrow_back" onClick={handleExitClick}></Button>
        <BadgeAnchor>
        <img src={slackMessenger.pfp} />
          <Badge className="slack-badge" style={{backgroundColor: `${slackMessenger.presence === 'active' ? '#038C7F' : '#b00020'}`}} align='end' inset='1.5rem'/>
        </BadgeAnchor>
        <h1>{slackMessenger.name}</h1>
      </div>
      <Divider size="M" UNSAFE_className="sMessengerDivider"/>
      <div className="sMessengerContent">
        <SlackMessagingContent />
      </div>
      <div className="sMessengerInput flex-container-row">
        <input
          className="default-input"
          type="text"
          ref={messageRef}
          onChange={handleMessageChange}
          onKeyPress={handleKeyPress}
          placeholder={`Type a message to ${slackMessenger.name}...`}>
        </input>
        <Button className="primePillBtn" icon="send" disabled={sendDisabled} onClick={handleSendClick}></Button>
      </div>
    </>
  )
}