import ImageTile from "./ImageTile";
import PortableDocumentTile from "./PortableDocumentTile";
import ScreenShareTile from "./ScreenShareTile";
import PlaceholderTile from "./PlaceholderTile";
import RemoteDesktopTile from "./RemoteDesktopTile";
import WhiteboardTile from "./WhiteboardTile";
import CalendarTile from "./CalendarTile";
import UtilitiesTile from "./UtilitiesTile";
import InlineFrameTile from "./InlineFrameTile";
import VNCTile from './VNCTile'
import Folder from './Folder'
import { Component } from 'react';

// Functions to return valid JSX to be rendered into the DataTile
// class component.
const getFolder = (props) => <Folder {...props}/>
const getScreenShareTile = (props) => <ScreenShareTile {...props}/>
const getUtilitiesTile = (props) => <UtilitiesTile {...props}/>
const getWhiteboardTile = (props) => <WhiteboardTile {...props}/>
const getCalendarTile = (props) => <CalendarTile {...props}/>
const getInlineFrameTile = (props) => <InlineFrameTile {...props}/>
const getVNCTile = (props) => <VNCTile {...props}/>
const getImageTile = (props) => <ImageTile {...props}/>
const getPortableDocumentTile = (props) => <PortableDocumentTile {...props}/>
const getRemoteDesktopTile = (props) => <RemoteDesktopTile {...props}/>
const getPlaceholderTile = (props) => <PlaceholderTile {...props}/>

// Generic data tile function that exports a class component
// based off of the executed function.
function DataTile (props) {
  let file = props.item.fileName.split('.').pop().toLowerCase();
  switch (file) {
    case 'folder': return getFolder(props);
    case 'screen-share': return getScreenShareTile(props);
    case 'utilities-tile': return getUtilitiesTile(props);
    case 'whiteboard': return getWhiteboardTile(props);
    case 'calendar': return getCalendarTile(props);
    case 'iframe': return getInlineFrameTile(props);
    case 'vnc': return getVNCTile(props);
    case 'png':
    case 'jpg':
    case 'jpeg':
    case 'gif': return getImageTile(props);
    case 'pdf': return getPortableDocumentTile(props);
    case 'rdp': return getRemoteDesktopTile(props);
    default: return getPlaceholderTile(props);
  }
}

class ADataTile extends Component {
  constructor(props) {
    super(props)
  }

  shouldComponentUpdate(nextProps) {
    let pageChange;
    if(this.props.pdfTracker !== undefined){
      if(this.props.pdfTracker[this.props.id]){
        this.props.pdfTracker[this.props.id].page === nextProps.pdfTracker[nextProps.id].page ? pageChange = false : pageChange = true
      }
    }
    if (JSON.stringify(nextProps.id) === JSON.stringify(this.props.id) && !pageChange) {
      return false;
    } else {
        return true;
    }
  }

  render() {
    return (
    <>
      <DataTile
        props={this.props}
        id={this.props.id}
        key={this.props.key}
        index={this.props.index}
        item={this.props.item}
        large={this.props.large}
        videoSize={this.props.videoSize}
        accessLevel={this.props.accessLevel}
        pdfTracker={this.props.pdfTracker}
      />
    </>
    )
  }
};

export default ADataTile;