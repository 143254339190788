import { Dialog } from "@rmwc/dialog";
import { useRoom } from "net/rooms";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getMeetingMembers, muteUser, removeUser } from 'js/webex-controls';
import {Snackbar, SnackbarAction} from '@rmwc/snackbar';
import '@rmwc/snackbar/styles';
import {CreateCalendarEvents} from 'net/calendar';
import copy from 'copy-to-clipboard';
import io from 'socket.io-client'
import { Fab } from "@rmwc/fab";
import { MenuItem, MenuSurfaceAnchor, Menu } from "@rmwc/menu";
import {Markup} from 'interweave';
import { SocketContext } from "context/socket";
import { Content, Button, Heading } from "@adobe/react-spectrum";

export default function CreateCalEventModal(props){
  const params = useParams()
	const location = `${window.location.origin}${window.location.pathname}`;
	const [snackOpen, setSnackOpen] = useState(false);
	const [title, setTitle] = useState('');
	const [description, setDescription] = useState('');
	const [email, setEmail] = useState('');
	const [start_time, setStartTime] = useState(new Date());
	const [end_time, setEndTime] = useState(new Date());

	useEffect(() => {
    if(props.calDay){
      setStartTime(props.calDay.date);
			setEndTime(props.calDay.date);
    }
  }, [props.calDay])

  const handleClose = () => {
    props.setIsOpen(false)
		props.resetCalData();
	}

	function formatParticipants(participants) {
		let newArr = [];
		if (participants.length > 0) {
			let arr = participants.split(',');
			arr.map((email) => {
				newArr.push({email: email.trim()})
			})
			return newArr
		}
		return newArr
	}
	
	
	if (props.calDay) {
		
		return(
			<>
				<Dialog open={props.isOpen}
					onClose={handleClose}
					// onOpen={handleOpen}
					className="calInfo">
					<div className="flex-container-row flex-end">
						<button className="whiteboardClose btn" onClick={handleClose}>
						<span className="material-icons">clear</span>
						</button>
					</div>
					<h1 className="spaceInfoTitle">Schedule Meeting</h1>
					<div className="flex-container-row detailSection">
						<div>
							<div className="event-main-container">
								<div className="event-sub-container">
									<div>
										<Content>Title:*</Content>
										<input
											value={title}
											onChange={(evt) => {
												setTitle(evt.target.value)
											}}
											className="cal-text"
										/>
										<div>
											<Content>Details:</Content>
											<textarea
												value={description}
												onChange={(evt) => {
													setDescription(evt.target.value)
												}}
												className="cal-text"
												id="description"
											/>
										</div>
										<div>
											<Content>Invitees: <span className="subtext">separated by comma</span></Content>
											<input
												value={email}
												onChange={(evt) => {
													setEmail(evt.target.value)
												}}
												className="cal-text"
											/>
										</div>
									</div>
									<div style={{display: 'flex', width: '100%', paddingLeft: 'auto'}}>
										
									</div>
									<div>
										<div>
											<Content>From:*</Content>
											<DatePicker
												selected={start_time}
												onChange={(date) => setStartTime(date)}
												showTimeSelect
												timeIntervals={15}
												dateFormat="MMMM d, yyyy h:mm aa"
												className="cal-datepicker"
											/>
										</div>
										<div>
											<Content>To:*</Content>
											<DatePicker
												selected={end_time}
												onChange={(date) => setEndTime(date)}
												showTimeSelect
												timeIntervals={15}
												dateFormat="MMMM d, yyyy h:mm aa"
												className="cal-datepicker"
											/>
										</div>
									</div>
								</div>
			
								<br />
								<Snackbar
									open={snackOpen}
									onClose={evt => setSnackOpen(false)}
									message="Event being created..."
									dismissesOnAction
									action={
										<SnackbarAction
											label="Dismiss"
											style={{color: '#49FFC0'}}
										/>
									}
								/>
								<Button variant="primary" onPress={()=>{
									if (!title || !start_time || !end_time) {
										alert('Please fill out all required form fields')
										return
									} else {
										CreateCalendarEvents(sessionStorage.getItem('userId'), props.calendarId ? props.calendarId[0].calendar_id : '0', {
											title: title,
											description: description,
											start_time: start_time,
											end_time: end_time,
											participants: formatParticipants(email),
											location: location,
										});
										setSnackOpen(true);
										setTitle('');
										setDescription('');
										setEmail('');
									} 
								}}>
									Schedule
								</Button>
							</div>
						</div>
					</div>
				</Dialog>
			</>
		)
	}
  return (<></>)
}
