// CSS Color Names
// Compiled by @bobspace.
//
// A javascript array containing all of the color names listed in the CSS Spec.
// The full list can be found here: https://www.w3schools.com/cssref/css_colors.asp
// Use it as you please, 'cuz you can't, like, own a color, man.

export const CSS_COLOR_NAMES = [
  "Blue",
  "BlueViolet",
  "Brown",
  "CadetBlue",
  "Chocolate",
  "Coral",
  "CornflowerBlue",
  "Crimson",
  "DarkBlue",
  "FireBrick",
  "ForestGreen",
  "Fuchsia",
  "GoldenRod",
  "Green",
  "IndianRed",
  "Indigo",
  "Magenta",
  "Maroon",
  "Navy",
  "Olive",
  "Orange",
  "Orchid",
  "Peru",
  "Purple",
  "RebeccaPurple",
  "Red",
  "RoyalBlue",
  "SaddleBrown",
  "SeaGreen",
  "Sienna",
  "SteelBlue",
  "Teal",
  "Tomato",
];