import React, { useState, useEffect, useRef, useContext } from 'react';
import { Draggable } from "react-beautiful-dnd";
import FullScreenButton from 'buttons/FullScreenButton';
import GoogleButton from 'react-google-button';
import { SocketContext } from 'context/socket';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import rrulePlugin from '@fullcalendar/rrule';
import interactionPlugin from '@fullcalendar/interaction';
import {GetCalendar, getCalAuthUrl} from 'net/calendar';
import '@rmwc/grid/styles';
import { Fab } from "@rmwc/fab";
import { Tooltip } from '@rmwc/tooltip';
import CalEventInfoModal from '../modals/CalEventInfoModal';
import CreateCalEventModal from '../modals/CreateCalEventModal';
import '@rmwc/elevation/styles';

export default function CalendarTile(props){
	const socket = useContext(SocketContext);
	const {data, isLoading, isError} = GetCalendar(props.accessLevel);
	const [calInfoOpen, setCalInfoOpen] = useState(false);
	const [calData, setCalData] = useState();
	const [calendarDay, setCalendarDay] = useState();
	const [calDayOpen, setCalDayOpen] = useState(false);
	const [calStoredData, setCalStoredData] = useState(undefined)

	const calendar = 'calendar';
	const calendarClick = 'calendar-click'

	if (data && props.accessLevel === 'owner') {
		socket.emit(calendar, { 'calData': data })
	}
	
	useEffect(() => {
		if(props.accessLevel !== 'owner'){
			socket.on(calendar, (message) => {
				setCalStoredData(message.message.calData)
			})
		}

		return () => (
			socket.off(calendar)
		)
	}, [props.accessLevel])

  const getDraggableStyleSmall = (snapshot, draggableStyle) =>  {

    let styleWithProps;
    if(!snapshot.isDragging){
      styleWithProps = {}
    }
    else{
      styleWithProps = Object.assign({}, draggableStyle)
    }
    if(snapshot.isDropAnimating){
      styleWithProps.transitionDuration = '0.001s'
    }
    //styleWithProps.backgroundColor = snapshot.isDragging ? '#1e1e1e' : '#292929' 
    styleWithProps.height = snapshot.isDragging ? '33%' : '100%'
    styleWithProps.width = snapshot.isDragging ? '33%' : '100%'
    return styleWithProps
  }

  const getDraggableStyleLarge = (snapshot, draggableStyle) =>  {
    let styleWithProps;
    if(!snapshot.isDragging){
      styleWithProps = {}
    }
    else{
      styleWithProps = Object.assign({}, draggableStyle)
    }
    if(snapshot.isDropAnimating){
      styleWithProps.transitionDuration = '0.001s'
    }
    styleWithProps.height = snapshot.isDragging ? '100%' : '100%'
    styleWithProps.width = snapshot.isDragging ? '100%' : '100%'
    return styleWithProps
  }

  const handleCloseClick = () => {
    socket.emit(calendarClick, {inUse: false})
  }

  	if (props.utility) {
		return (
			<Draggable draggableId={props.id} index={props.index} isDragDisabled={props.accessLevel === 'owner' ? false : true}>
				{(provided, snapshot) => (
					<div  
						ref={provided.innerRef}
						{...provided.draggableProps}
						{...provided.dragHandleProps}
						className={`${props.accessLevel === 'owner' ? 'defaultTileOwner' : 'defaultTile'} flex-container-column utility-item`}
						style={props.large ? getDraggableStyleLarge(snapshot, provided.draggableProps.style) : getDraggableStyleSmall(snapshot, provided.draggableProps.style)}
					>
						<div {...provided.dragHandleProps} className="flex-container-row defaultTileHeader">
							<div className="flex-container-row full-width center">
								<p className="dataTileTitle center">Calendar</p>
							</div>
						</div>
						<div style={{backgroundColor: 'white', borderRadius: 10, width: '95%', height: '90%', marginRight: 'auto', marginLeft: 'auto', borderColor: 'transparent'}}>
							<FullCalendar
								plugins={[ dayGridPlugin, rrulePlugin, interactionPlugin ]}
								initialView="dayGridDay"
								eventColor="#038C7F"
								eventBackgroundColor="#038C7F"
								width="100%"
								height="90%"
								style={{zIndex: 9999}}
								// aspectRatio="3"
								headerToolbar={{
									start: '',
									center: '',
									right: ''
								}}
								events={eventBuilder(data)}
								dayMaxEvents={2}
							/>
						</div>
					</div>
				)}
			</Draggable>
		)
	}
	return (
		<Draggable draggableId={props.id} index={props.index} isDragDisabled={props.accessLevel === 'owner' ? false : true}>
			{(provided, snapshot) => (
				<div  
					ref={provided.innerRef}
					{...provided.draggableProps}
					{...provided.dragHandleProps}
					className={`${props.accessLevel === 'owner' ? 'defaultTileOwner' : 'defaultTile'} flex-container-column utility-title`}
					style={props.large ? getDraggableStyleLarge(snapshot, provided.draggableProps.style) : getDraggableStyleSmall(snapshot, provided.draggableProps.style)}
				>
					<div {...provided.dragHandleProps} className="flex-container-row defaultTileHeader">
						<div className="flex-container-row full-width center">
							<p className="dataTileTitle center">Calendar</p>
						</div>
						{ props.accessLevel === 'owner' && props.large ? (
							<div className="flex-container-row flex-end closeBtn">
							{ props.large && 
								<FullScreenButton />
							}
							</div>
						) : (<></>)
						}
					</div>
					{props.accessLevel === 'owner' ? (
						<div style={{backgroundColor: '#171717', borderRadius: 10, width: '100%', height: '90%', marginRight: 'auto', marginLeft: 'auto', borderColor: 'transparent'}}>
							<FullCalendar
								plugins={[ dayGridPlugin, rrulePlugin, interactionPlugin ]}
								initialView="dayGridMonth"
								eventColor="#038C7F"
								eventBackgroundColor="#038C7F"
								width="100%"
								height="90%"
								style={{zIndex: -9999}}
								headerToolbar={{
										start: '',
										center: 'title',
										right: 'today prev,next'
								}}
								dateClick={(info) => {
										info.jsEvent.preventDefault();
										setCalendarDay(info);
										setCalDayOpen(true);
								}}
								events={eventBuilder(data)}
								eventClick={(info) => {
									info.jsEvent.preventDefault();
									setCalData(info);
									setCalInfoOpen(true);
								}}
								dayMaxEvents={3}
							/>
							<CalEventInfoModal calData={calData} isOpen={calInfoOpen} setIsOpen={setCalInfoOpen} resetCalData={setCalData} />
							<CreateCalEventModal calDay={calendarDay} isOpen={calDayOpen} setIsOpen={setCalDayOpen} resetCalData={setCalendarDay} calendarId={data} />
							<Tooltip content="Calendar Sync">
								{/* <Fab
									onClick={() => {
										if (sessionStorage.getItem('userId')) {
											window.location.href = getCalAuthUrl(sessionStorage.getItem('userId'))
										}
									}}
									className="calSyncButton"
									style={{backgroundColor: '#343434'}}
									icon="donut_small"
									mini>
								</Fab> */}
								{
									data ? (<></>) : (
										<GoogleButton
											className="google-signin-button"
											style={{borderRadius: '10px'}}
											onClick={() => {
												if (sessionStorage.getItem('userId')) {
													window.location.href = getCalAuthUrl(sessionStorage.getItem('userId'))
												}
											}}
										/>
									)
								}
							</Tooltip>
						</div>
					) : (
						<div style={{backgroundColor: '#171717', borderRadius: 10, width: '100%', height: '90%', marginRight: 'auto', marginLeft: 'auto', borderColor: 'transparent'}}>
							<FullCalendar
								plugins={[ dayGridPlugin, rrulePlugin, interactionPlugin ]}
								initialView="dayGridMonth"
								eventColor="#038C7F"
								eventBackgroundColor="#038C7F"
								width="100%"
								height="90%"
								headerToolbar={{
									start: '',
									center: 'title',
									right: 'today prev,next'
								}}
								events={eventBuilder(calStoredData)}
								dayMaxEvents={3}
							/>
						</div>
					)}
				</div>
			)}
		</Draggable>
	)
}

function eventBuilder(events) {
	let arr = [];

	if (events) {
		events.map((event, index) => {
			if (event.when.object === 'timespan' && event.recurrence) {
					let obj = {
							title: event.title,
							description: event.description,
							participants: event.participants,
							start: reformatDate(event.when.start_time),
							end: reformatDate(event.when.end_time),
							rrule: event.recurrence.rrule[0]
					}
					arr.push(obj);
			}
			if (event.when.object === 'date' && event.recurrence) {
					let obj = {
							title: event.title,
							description: event.description,
							participants: event.participants,
							date: event.when.date,
							rrule: event.recurrence.rrule[0]
					}
					arr.push(obj);
			}
			if (event.when.object === 'timespan' && !event.recurrence) {
					let obj = {
							title: event.title,
							description: event.description,
							participants: event.participants,
							start: reformatDate(event.when.start_time),
							end: reformatDate(event.when.end_time),
					}
					arr.push(obj);
			}
			if (event.when.object === 'date' && !event.recurrence) {
					let obj = {
							title: event.title,
							description: event.description,
							participants: event.participants,
							date: event.when.date,
					}
					arr.push(obj);
			}
		})
	}
	return arr;
}

function reformatDate(elDate) {
	let date = new Date(elDate * 1000);
	let formattedDate = date.toISOString().split('T')[0];
	
	return formattedDate;
}