import { Button } from "@rmwc/button";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from '@rmwc/tooltip';
import {
   updateMode as updateModalMode,
   goBackMode as goBackModalMode,
   updateTileType as updateModalTileType
} from "app/modal"

export default function SingleControl(props) {
   const dispatch = useDispatch();
   const handleClick = () => {
      dispatch(updateModalMode('url'));
      dispatch(updateModalTileType('iframe'));
   }

   return (
      <>
         <Button
            style={{
               width: '100%',
               border: 'thin solid grey',
               display: 'flex',
               flexDirection: 'row',
               alignItems: 'center',
               justifyContent: 'center',
               textAlign: 'center',
               margin: 'auto',
               marginBottom: '15px'
            }}
            onClick={handleClick}
         >
            <div
               style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                  fontSize: '15px',
                  margin: 'auto',
               }}
            >
               <span
                  style={{
                     marginRight: '10px'
                  }}
               >Single Control</span>
            </div>
         </Button>
      </>
   )
}