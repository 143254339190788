import { createSlice } from "@reduxjs/toolkit";

export const messagesSlice = createSlice({
  name: 'messages',
  initialState: {
    slack: {}
  },
  reducers: {
    addNewMessage: (state, action) => {
      const key = Object.keys(action.payload)[0]
      return {
        ...state,
        slack: {
          ...state.slack,
          [key]: [
            // on the first message, state.slack[key] is empty --- need to account for this
            ...(state.slack[key] || []),
            action.payload[key]
          ]
        }
      }
    }
  }
})

export const { addNewMessage } = messagesSlice.actions
export default messagesSlice.reducer