import React, { useEffect, useState, useContext, useRef } from 'react'
import {
  Excalidraw,
  getSceneVersion,
  MainMenu
} from "@excalidraw/excalidraw";
import { SocketContext } from 'context/socket';

const AnnotationCanvas = () => {
  // Listen to the annotations channel for sockets.
  const socket = useContext(SocketContext);
  const annotationCanvas = 'annotation-canvas';

  // Manage the local canvas state.
  const [canvasState, setCanvasState] = useState([]);
  const canvasStateRef = useRef(canvasState);

  // Set the initial state for the canvas.
  const initialState = useRef(canvasState);

  // Set the scene version and drag state.
  const [sceneVer, setSceneVer] = useState(0);
  const [dragState, setDragState] = useState(false);

  const excalidrawRef = useRef();
  canvasStateRef.current = canvasState;

  // Handle the broadcasting to the canvas.
  useEffect(() => {
    socket.on(annotationCanvas, (data) => {
      const timeout = setTimeout(() => {
        // const updated = data.message.state.e;
        const updated = data.message.e;
        excalidrawRef.current.updateScene({elements: updated});
        setCanvasState(excalidrawRef.current)
        clearTimeout(timeout);
      }, 66) // cap to ~30 FPS
    })
  }, [excalidrawRef.current, canvasState, sceneVer])

  return (
    <div id="annotation-canvas">
      <Excalidraw
        ref={excalidrawRef}
        onPointerUpdate={(e) => {
          if (e.button === 'down') {
            setDragState(true);
          } else {
            setDragState(false);
          }
        }}
        onChange={(e, appState) => {
          // When Excalidraw is instantiated, the canvas runs the
          // onChange function; if it's at 0, broadcast an empty
          // message to indicate that the client needs an update.
          if (sceneVer === 0) {
            socket.emit(annotationCanvas, {
              'state': {}
            })
          }

          if (dragState === false) {
            // Broadcast updated scene to all clients.
            if (sceneVer < getSceneVersion(e)) {
              setSceneVer(getSceneVersion(e))
              socket.emit(annotationCanvas, { 
                'state': {e, appState}
              })
            }
          }
        }}
        initialData={{...initialState, appState: {viewBackgroundColor: '#ffffff44'}}}
        UIOptions={{
          welcomeScreen: false,
        }}
        renderSidebar={null}
      >
        {/* <MainMenu>
          <MainMenu.DefaultItems.LoadScene/>
        </MainMenu> */}
        <MainMenu>
          <MainMenu.DefaultItems.ToggleTheme/>
          <MainMenu.DefaultItems.ChangeCanvasBackground/>
        </MainMenu>
      </Excalidraw>
    </div>
  )
}

export default AnnotationCanvas