import {
   createSlice
} from "@reduxjs/toolkit";

export const viewSlice = createSlice({
   name: 'view',
   initialState: {
      level: 'all',
      fullScreen: false,
      pdfTracker: {},
      vncLoading: {},
   },
   reducers: {
      assignLevel: (state, action) => {
         return {
            ...state,
            level: action.payload
         }
      },
      assignFullScreen: (state, action) => {
         return {
            ...state,
            fullScreen: action.payload,
         }
      },
      assignPdfPage: (state, action) => {
         return {
            ...state,
            pdfTracker: {
               ...state.pdfTracker,
               [action.payload.id]: {
                  'page': action.payload.page,
                  '_id': action.payload.id
               }
            }
         }
      },
      assignVncLoading: (state, action) => {
         return {
            ...state,
            vncLoading: {
               ...state.vncLoading,
               [action.payload.id]: {
                  'done': action.payload.done,
                  'initTime': action.payload.initTime,
                  'progress': action.payload.progress,
               }
            }
         }
      }
   }
})

export const {
   assignLevel,
   assignPdfPage,
   assignFullScreen,
   assignVncLoading
} = viewSlice.actions
export default viewSlice.reducer