/**
 * For PDFs
 */
import PortableDocumentControls from "buttons/PortableDocumentControls";
import { Image } from '@adobe/react-spectrum'
import { useEffect, useMemo } from "react";
import { useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { pdfjs, Document, Page } from 'react-pdf/dist/esm/entry.webpack5';
import { usePortableDocumentAsset } from "net/tiles";
import TileHeader from 'aesthetic/TileHeader'
import { draggableStyle } from "../../styles/draggableStyle";
import { useRef } from "react";
import { useSelector } from "react-redux";

export default function PortableDocumentTile(props) {
   const fullScreen = useSelector(state => state.view.fullScreen)
   const [numPages, setNumPages] = useState(undefined)
   const [pageWidth, setPageWidth] = useState(0);
   const [pageHeight, setPageHeight] = useState(0);
   const [elWidth, setElWidth] = useState(0);
   const elWidthRef = useRef(elWidth)
   const [elHeight, setElHeight] = useState(0);
   const elHeightRef = useRef(elHeight)

   const [src, setSrc] = useState();
   const pdf = usePortableDocumentAsset(props.item.teamId, props.item.roomId, props.item.fileName)

   //   const resize = new ResizeObserver((list) => {
   //     for(let element of list){
   //       if(element.contentRect.width !== elWidthRef.current){
   //         let padding = props.large ? 0 : 0.16 * element.contentRect.width
   //         setElWidth(element.contentRect.width)
   //       }
   //       if(element.contentRect.height !== elHeightRef.current){
   //         let padding = props.large ? 0 :  0.1 * element.contentRect.height
   //         setElHeight(element.contentRect.height)
   //       } 
   //     }
   //   })
   function addResizeObserver(element, callback) {
      console.warn('addResizeObserver()', element);
      const isElement = element instanceof HTMLElement;
      if (!isElement) return;

      const timeout = 100;
      let intervalId;
      const setTimeoutDebounce = (callback) => {
         if (!callback || typeof callback !== 'function') return;
         if (intervalId) {
            clearInterval(intervalId);
         }
         intervalId = setTimeout(() => {
            callback();
            intervalId = null;
         }, timeout);
      }

      const resizeObserver = new ResizeObserver(entries => {
         const callback = () => {
            for (let element of entries) {
               if (element.contentRect.width !== elWidthRef.current) {
                  setElWidth(element.contentRect.width)
               }
               if (element.contentRect.height !== elHeightRef.current) {
                  setElHeight(element.contentRect.height)
               }
            }
         }
         setTimeoutDebounce(callback);
      });

      resizeObserver.observe(element);
   }

   let title = props.item.fileName.split('.')
   // remove file extension
   title.pop()
   title = title.join('')

   useEffect(() => {
      elWidthRef.current = elWidth
      elHeightRef.current = elHeight
   })

   // each PDF in the space will have its own listener so we need to manage them
   useEffect(() => {
      // resize.observe(document.getElementById(props.id))
      const el = document.getElementById(props.id);
      addResizeObserver(el);
   }, [])

   const addControlsListener = (element, parent) => {
      try {
         parent[0].addEventListener('mouseover', () => {
            try {
               element.style.visibility = 'visible'
               element.style.opacity = 1
            } catch (error) {
            }
         })
      } catch (error) {
      }
      try {
         parent[0].addEventListener('mouseout', () => {
            try {
               element.style.visibility = 'hidden'
               element.style.opacity = 0
            } catch (error) {
            }
         })
      } catch (error) {
      }

   }

   const addScrollListener = (element, parent) => {
      try {
         parent[0].addEventListener('mouseover', () => {
            try {
               element[0].style.overflowY = 'auto'
            } catch (error) {
            }
         })
      } catch (error) {
      }
      try {
         parent[0].addEventListener('mouseout', () => {
            try {
               element[0].style.overflowY = 'hidden'
            } catch (error) {
            }
         })
      } catch (error) {
      }
   }

   const handleMouseEnter = (e) => {
      try {
         let header = document.getElementById(props.id)
         if (!props.large) header.firstElementChild.firstElementChild.style.opacity = 1
      } catch (error) {
      }
   }

   const handleMouseLeave = (e) => {
      try {
         let header = document.getElementById(props.id)
         if (!props.large) header.firstElementChild.firstElementChild.style.opacity = 0
      } catch (error) {
      }
   }

   const onDocumentLoadSuccess = (pdf) => {
      setNumPages(pdf.numPages)
      if (props.accessLevel === 'owner' && props.large) addControlsListener(document.getElementById(`${props.id}controls`), document.getElementsByClassName(`${props.id}`))
      if (props.thumbnail !== true && props.large) addScrollListener(document.getElementsByClassName(`${`pdf-${props.id}`}`), document.getElementsByClassName(`${props.id}`))
   }

   const onPageLoadSuccess = (page) => {
      setPageWidth(page.width);
      setPageHeight(page.height);
   }

   // example from https://github.com/wojtekmaj/react-pdf/issues/718
   // const fitHorizontal = useMemo(() => {
   //   const wRatio = pageWidth / elWidth;
   //   const hRatio = pageHeight / elHeight;
   //   if (wRatio < hRatio) {
   //     return false;
   //   }
   //   return true;
   // }, [elHeight, elWidth, pageHeight, pageWidth])


   if (pdf.isLoading || pdf.isError) {
      if (props.preview) {
         return (
            <div className="loadTile flex-container-column" id={props.id}>
               <TileHeader title={title} id={props.id} accessLevel={props.accessLevel} />
               <div className="imgWrapper">
                  <img src={pdf.isError ? '/assets/tile-error.png' : '/assets/tile-loading.svg'} className="full-img-contain" />
               </div>
            </div>
         )
      }
      return (
         <Draggable draggableId={props.id} index={props.index} isDragDisabled={props.accessLevel === 'owner' && !fullScreen ? false : true}>
            {(provided, snapshot) => (
               <div
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  className="loadTile flex-container-column"
                  style={draggableStyle(snapshot, provided.draggableProps.style, props.id, props.large)}
                  id={props.id}
               >
                  <TileHeader title={title} id={props.id} accessLevel={props.accessLevel} />
                  <div className="imgWrapper">
                     <img src={pdf.isError ? '/assets/tile-error.png' : '/assets/tile-loading.svg'} className="full-img-contain" />
                  </div>
               </div>
            )}
         </Draggable>
      )
   }

   // we don't want to reload pdf every render but useMemo() was giving problems
   if (!src && pdf.data) {
      let dataArr = new Uint8Array(pdf.data)
      let dataBlob = new Blob([dataArr.buffer], { type: 'application/pdf' })
      setSrc(URL.createObjectURL(dataBlob))
   }
   let page = props.item.currPage
   if (props.pdfTracker) {
      if (props.pdfTracker[props.id] !== undefined) page = props.pdfTracker[props.id].page
   }

   return (
      <Draggable draggableId={props.id} index={props.index} isDragDisabled={props.accessLevel === 'owner' && !fullScreen ? false : true}>
         {(provided, snapshot) => {
            return (
               <>
                  {props.large &&
                     <div
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        className={`pdfTile ${props.id} defaultTile ${props.accessLevel === 'owner' ? 'owner' : ''} flex-container-column`}
                        style={draggableStyle(snapshot, provided.draggableProps.style, props.id, props.large)}
                        id={props.id}
                     >
                        <div  {...provided.dragHandleProps}>
                           <TileHeader title={title} id={props.id} accessLevel={props.accessLevel} teamId={props.item.teamId} roomId={props.item.roomId} fileName={props.item.fileName} edit={false} large={props.large} />
                        </div>
                        {props.large !== true &&
                           <div className="data-tile-shield" />
                        }
                        <Document
                           file={src}
                           className={`pdf-document`}
                           onLoadSuccess={onDocumentLoadSuccess}
                           loading={<div className="imgWrapper" style={{ 'display': 'flex', 'alignSelf': 'center', 'alignItems': 'center', 'justifyContent': 'center' }}><Image src={'/assets/tile-loading.svg'} height={elHeight} width={elWidth} objectFit="fill" /></div>}
                           error={<div className="imgWrapper"><Image src="/assets/tile-error.png" height="100%" width="100%" objectFit="contain" /></div>}
                        >
                           <Page
                              pageNumber={page}
                              loading={<Image src={'/assets/tile-loading.svg'} height={elHeight} width={elWidth} objectFit="contain" />}
                              className={props.large ? `pdf-page-large` : `pdf-page ${`pdf-${props.id}`}`}
                              renderAnnotationLayer={false}
                              onLoadSuccess={onPageLoadSuccess}
                              width={elWidth}
                              height={null} />
                           {props.pdfTracker !== undefined &&
                              <PortableDocumentControls currPage={page} numPages={numPages} id={props.id} fileName={props.item.fileName} accessLevel={props.accessLevel} />
                           }
                        </Document>
                     </div>
                  }
                  {!props.large &&
                     <div
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={`pdfTile ${props.id} defaultTile ${props.accessLevel === 'owner' ? 'owner' : ''} flex-container-column`}
                        style={draggableStyle(snapshot, provided.draggableProps.style, props.id, props.large)}
                        id={props.id}
                     >
                        <div>
                           <TileHeader title={title} id={props.id} accessLevel={props.accessLevel} teamId={props.item.teamId} roomId={props.item.roomId} fileName={props.item.fileName} edit={false} large={props.large} />
                        </div>
                        {props.large !== true &&
                           <div className="data-tile-shield" />
                        }
                        <Document
                           file={src}
                           className={`pdf-document`}
                           onLoadSuccess={onDocumentLoadSuccess}
                           loading={<div className="imgWrapper" style={{ 'display': 'flex', 'alignSelf': 'center', 'alignItems': 'center', 'justifyContent': 'center' }}><Image src={'/assets/tile-loading.svg'} height={elHeight} width={elWidth} objectFit="fill" /></div>}
                           error={<div className="imgWrapper"><Image src="/assets/tile-error.png" height="100%" width="100%" objectFit="contain" /></div>}
                        >
                           <Page
                              pageNumber={page}
                              loading={<Image src={'/assets/tile-loading.svg'} height={elHeight} width={elWidth} objectFit="contain" />}
                              className={props.large ? `pdf-page-large` : `pdf-page ${`pdf-${props.id}`}`}
                              renderAnnotationLayer={false}
                              onLoadSuccess={onPageLoadSuccess}
                              width={elWidth}
                              height={null} />
                           {props.pdfTracker !== undefined &&
                              <PortableDocumentControls currPage={page} numPages={numPages} id={props.id} fileName={props.item.fileName} accessLevel={props.accessLevel} />
                           }
                        </Document>
                     </div>
                  }
               </>
            )
         }}
      </Draggable>
   );
}