import { createSlice } from "@reduxjs/toolkit";

export const slackSlice = createSlice({
  name: 'slack',
  initialState: {
    slackMessenger: { active: false },
    slackSelf: {},
    allUsers: []
  },
  reducers: {
    assignSlackMessenger: (state, action) => {
      return {
        ...state,
        slackMessenger: {
          ...state.slackMessenger,
          ...action.payload
        }
      }
    },
    assignSlackSelf: (state, action) => {
      return {
        ...state,
        slackSelf: { ...action.payload }
      }
    },
    assignSlackUsers: (state, action) => {
      return {
        ...state,
        allUsers: [
          ...state.allUsers,
          action.payload
        ]
      }
    }
  }
})

export const { assignSlackMessenger, assignSlackSelf, assignSlackUsers } = slackSlice.actions
export default slackSlice.reducer