export const draggableStyle = (snapshot, draggableStyle, id, large) =>  {
  let styleWithProps;
  if(!snapshot.isDragging){
    // presentation panel doesn't need to make any space
    if(large) return {}
    // we only want to make so much room for a tile during a drag
    let transform = null
    if(draggableStyle.transform !== null) transform = "translate(0px, 25vh)"
    return {
      ...draggableStyle,
      transform: transform
    }
  }
  else {
    let draggingOverLarge = undefined
    let combining = undefined
    try {
      draggingOverLarge = snapshot.draggingOver.split('-')[0] === 'large' ? true : false
      combining = snapshot.combineWith !== null ? true : false
    } catch (error) {
    }
    styleWithProps = Object.assign({}, draggableStyle)
    styleWithProps.height = `${draggingOverLarge ? '50vh' : '25vh'}`
    styleWithProps.height = `${combining ? '21vh' : styleWithProps.height}`
    styleWithProps.width = `${draggingOverLarge ? '40vw' : '20vw'}`
    styleWithProps.width = `${combining ? '16vw' : styleWithProps.width}`
    const transitionW =  `width 0.1s ease-in-out`
    const transitionH =  `height 0.1s ease-in-out`
    styleWithProps.transition = `${transitionW}, ${transitionH}`
  }
  if(snapshot.isDropAnimating){
    let rect = document.getElementById(id).parentElement.getBoundingClientRect()
    const { moveTo, curve } = snapshot.dropAnimation;
    const translate = `translate(${moveTo.x}px, ${moveTo.y}px)`;
    const transitionW =  `width 0.05s ease-in-out`
    const transitionH =  `height 0.05s ease-in-out`
    styleWithProps.transform = translate
    styleWithProps.transition = `all ${curve} 0.01s, ${transitionW}, ${transitionH}`
    styleWithProps.width = `${rect.width-16}px`
    styleWithProps.height = `${rect.height-16}px`
  }
  return styleWithProps

}