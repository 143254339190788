import { Button } from "@rmwc/button";
import { Dialog, DialogContent } from "@rmwc/dialog";
import { TextField } from "@rmwc/textfield";
import { SocketContext } from "context/socket";
import { depositAssetLocker, uploadRDPAsset } from "net/assets";
import { updateTiles } from "net/tiles";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetcher } from "net/fetcher";
import { getConnections, createRDPConnection } from "net/guac";
import {
   rdpReducer,
   show as updateShow,
   loading as updateLoading,
   fetching as updateFetching,
   msg as updateMsg,
} from "app/rdp";

const sceneUpdate = 'scene-string'

export default function RDPModal(props) {
   const dispatch = useDispatch();
   const show = useSelector(state => state.rdp.show);
   const fetching = useSelector(state => state.rdp.fetching);
   const space = useSelector(state => state.space);
   const msg = useSelector(state => state.rdp.msg);
   const socket = useContext(SocketContext);
   const params = useParams();

   const [connName, setConnName] = useState(undefined)
   const [connHost, setConnHost] = useState(undefined)
   //>> Adding more fields <<//
   const [url, setUrl] = useState(undefined);
   const [username, setUsername] = useState(undefined);
   const [password, setPassword] = useState(undefined);
   const [guacUrl, setGuacUrl] = useState(undefined);
   const [domain, setDomain] = useState(undefined);
   const [connUser, setConnUser] = useState(undefined);
   const [connPass, setConnPass] = useState(undefined);

   async function getRDPList() {
      const guacURL = window?._env_.REACT_APP_GUACAMOLE_URL;
      if (!guacURL) return console.error('something wrong..');
      const result = await getConnections();
      console.log({ result });
   }

   useEffect(() => {
      getRDPList();
   }, [show]);

   function closeModal() {
      // dispatch(rdpReducer({
      // show: false,
      // }));
      dispatch(updateShow(false));
      dispatch(updateMsg(null));
   }

   async function handleAddConn() {
      if (!connName || !connHost) return;
      try {
         dispatch(updateMsg());
         dispatch(updateFetching(true));
         const payload = {
            //>> Changing on-demand <<//
            // name: connName,
            // host: connHost,

            username,
            password,
            host: connHost,
            name: connName,
            domain: domain,
            // connectionUser: connUser,
            // connectionPassword: connPass
         }
         const result = await createRDPConnection(payload);
         console.log({ result });

         if (!result.status) {
            console.error(result);
            const msg = `Failed to create RDP connection`;
            dispatch(updateMsg(msg));
            return;
         }

         uploadRDPAsset(space.teamId, payload).then(() => {
            props.setOpen(false)
            depositAssetLocker(space.teamId, params.roomId, `${connName}.rdp`).then((res) => {
               let newSmall = Array.from(props.tileListSmall)

               newSmall.unshift(res.data[0])
               updateTiles(params.roomId, newSmall, props.tileListLarge);
               let newString = {
                  'dataTile00': props.tileListLarge,
                  'dataTile01': newSmall
               }
               socket.emit(sceneUpdate, newString);
            }).catch((e) => {
               console.error(e)
            })
         }).catch((e) => {
            console.error(e)
         })

         closeModal();
      } catch (err) {
         console.error(err);
      } finally {
         dispatch(updateFetching(false));
      }
   }

   const checkValid = () => {
      let fields = document.getElementsByClassName('rdp-field')
      for (let i = 0; i < fields.length; i++) {
         if (!fields[i].control.validity.valid) return false
      }
      return true
   }

   return (
      <>
         <Dialog preventOutsideDismiss={false}
            // open={props.open}
            open={show}
            onClose={closeModal}
            className="rdpModal">
            <DialogContent>
               <div className="flex-container-row flex-between center">
                  <h2 className="rdp-title">RDP Session</h2>
                  <button className="tileClose btn" onClick={closeModal}>
                     <span className="material-icons">clear</span>
                  </button>
               </div>

               <div className="flex-container-column full-width flex-start rdp-field-wrapper">
                  <TextField
                     label="Name"
                     className="rdp-field"
                     required
                     value={connName}
                     disabled={fetching}
                     onChange={e => setConnName(e.target.value)}
                  />
                  <TextField
                     label="Host"
                     className="rdp-field"
                     required
                     value={connHost}
                     disabled={fetching}
                     onChange={e => setConnHost(e.target.value)}
                  />
                  {/* <TextField
                     label="Location"
                     className="rdp-field"
                     required
                     value={connHost}
                     onChange={e => setConnHost(e.target.value)}
                  /> */}
                  <TextField label="Username" className="rdp-field" required value={username} onChange={e => setUsername(e.target.value)} />
                  <TextField label="Password" className="rdp-field" required type="password" value={password} onChange={e => setPassword(e.target.value)} />
                  <TextField label="Domain" className="rdp-field" value={domain} onChange={e => setDomain(e.target.value)} />
                  {/* <TextField label="Connection User" className="rdp-field" required value={connUser} onChange={e => setConnUser(e.target.value)} /> */}
                  {/* <TextField label="Connection Password" className="rdp-field" type="password" required value={connPass} onChange={e => setConnPass(e.target.value)} /> */}
               </div>
               <br />
               <div style={{
                  display: msg ? 'block' : 'none',
                  color: 'red',
               }}>{msg ? msg : 'sdfsdf'}</div>

               <Button
                  className="primePillBtn rdp-submit"
                  disabled={!checkValid() || fetching}
                  onClick={handleAddConn}
               >ADD</Button>


            </DialogContent>

         </Dialog>
      </>
   );
}



// export default function RDPModal(props) {
//    const socket = useContext(SocketContext)
//    const sceneUpdate = 'scene-string'
//    const space = useSelector(state => state.space)
//    const params = useParams()

//    const [username, setUsername] = useState(undefined)
//    const [password, setPassword] = useState(undefined)
//    const [guacUrl, setGuacUrl] = useState(undefined)
//    const [connName, setConnName] = useState(undefined)
//    const [connHost, setConnHost] = useState(undefined)
//    const [connUser, setConnUser] = useState(undefined)
//    const [connPass, setConnPass] = useState(undefined)

//    const handleClose = () => {
//       setUsername(undefined)
//       setPassword(undefined)
//       setGuacUrl(undefined)
//       setConnName(undefined)
//       setConnHost(undefined)
//       setConnUser(undefined)
//       setConnPass(undefined)
//       let fields = document.getElementsByClassName('rdp-field')
//       for (let i = 0; i < fields.length; i++) {
//          fields[i].control.value = ''
//       }
//       props.setOpen(false)
//    }

//    const checkValid = () => {
//       let fields = document.getElementsByClassName('rdp-field')
//       for (let i = 0; i < fields.length; i++) {
//          if (!fields[i].control.validity.valid) return false
//       }
//       return true
//    }

//    const handleClick = () => {
//       const data = {
//          'username': username,
//          'password': password,
//          'guacUrl': guacUrl,
//          'connectionName': connName,
//          'connectionHost': connHost,
//          'connectionUser': connUser,
//          'connectionPassword': connPass
//       }

//       uploadRDPAsset(space.teamId, data).then(() => {
//          props.setOpen(false)
//          depositAssetLocker(space.teamId, params.roomId, `${connName}.rdp`).then((res) => {
//             let newSmall = Array.from(props.tileListSmall)

//             newSmall.unshift(res.data[0])
//             updateTiles(params.roomId, newSmall, props.tileListLarge);
//             let newString = {
//                'dataTile00': props.tileListLarge,
//                'dataTile01': newSmall
//             }
//             socket.emit(sceneUpdate, newString);
//          }).catch((e) => {
//             console.error(e)
//          })
//       }).catch((e) => {
//          console.error(e)
//       })
//    }

//    return (
//       <>
//          <Dialog preventOutsideDismiss={false}
//             open={props.open}
//             closeModal={handleClose}
//             className="rdpModal">
//             <DialogContent>
//                <div className="flex-container-row flex-between center">
//                   <h2 className="rdp-title">Create RDP Session</h2>
//                   <button className="tileClose btn" onClick={handleClose}>
//                      <span className="material-icons">clear</span>
//                   </button>
//                </div>
//                <div className="flex-container-column full-width flex-start rdp-field-wrapper">
//                   <TextField label="Username" className="rdp-field" required value={username} onChange={e => setUsername(e.target.value)} />
//                   <TextField label="Password" className="rdp-field" required type="password" value={password} onChange={e => setPassword(e.target.value)} />
//                   <TextField label="Guacamole URL" className="rdp-field" required value={guacUrl} onChange={e => setGuacUrl(e.target.value)} />
//                   <TextField label="Connection Name" className="rdp-field" required value={connName} onChange={e => setConnName(e.target.value)} />
//                   <TextField label="Connection Host" className="rdp-field" required value={connHost} onChange={e => setConnHost(e.target.value)} />
//                   <TextField label="Connection User" className="rdp-field" required value={connUser} onChange={e => setConnUser(e.target.value)} />
//                   <TextField label="Connection Password" className="rdp-field" type="password" required value={connPass} onChange={e => setConnPass(e.target.value)} />
//                </div>
//                <Button className="primePillBtn rdp-submit" disabled={!checkValid()} onClick={handleClick}>Submit</Button>
//             </DialogContent>
//          </Dialog>
//       </>
//    )
// }
