/**
 * For leaving a space
 */
//import { Button } from '@adobe/react-spectrum'
import { leaveCall } from 'js/webex-controls'
import { Fab } from '@rmwc/fab';
import { Tooltip } from '@rmwc/tooltip';
import { useParams, Prompt } from 'react-router';
import { useContext, useEffect, useState } from 'react';
import { SocketContext } from 'context/socket';
import { useSelector } from 'react-redux';
import { fetcher } from "net/fetcher";
import Swal from 'sweetalert2'

export default function LeaveCallButton(props) {
  const params = useParams()
  const userKick = 'user-kick'
  const socket = useContext(SocketContext)
  // const [kicked, setKicked] = useState(false)
  const user = useSelector(state => state.user)
  /**
   * Due to the nature of this single-page react app
   * we need to use state to accurately conditionally render
   * a loading UI or not
   */

  const env = useSelector(state => state.env);
  const isDev = env.NODE_ENV === 'development';

  async function logout() {
    const response = await fetcher(`/api/auth/keycloakLogout`);
    if (!isDev) return;
    const result = await response.json();
    if (!result.status) {
      console.error('Signout failed!');
      return;
    }
    window.location.href = window.location.origin;
  }

  const handleClick = (kicks) => {
    if (kicks === 'kicked') {
      props.setBlocking(true)
      props.setLeaving(true)
      props.setLoading(true)
      leaveCall(params.roomId, props.accessLevel).then(() => {
        socket.disconnect()
        window.location = window._env_.REACT_APP_PLATFORM
      }).catch((e) => {
        socket.disconnect()
        console.error(e)
        window.location = window._env_.REACT_APP_PLATFORM
      })
    } else {
      Swal.fire({
        title: 'Are you sure you want to leave?',
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: `Leave`,
        denyButtonText: `Cancel`,
        customClass: {
          confirmButton: 'leave-call'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          props.setBlocking(true)
          props.setLeaving(true)
          Swal.fire({
            title: 'Logout?',
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: `Logout`,
            denyButtonText: `Cancel`,
            customClass: {
              confirmButton: 'logout',
            }
          }).then((result) => {
            if (result.isConfirmed) {
              leaveCall(params.roomId, props.accessLevel).then(() => {
                socket.disconnect()
                window.location.href = window.location.origin
              })
              logout();
            } else if (result.isDenied) {
              props.setBlocking(true)
              props.setLeaving(true)
              props.setLoading(true)
              leaveCall(params.roomId, props.accessLevel).then(() => {
                socket.disconnect()
                window.location = window._env_.REACT_APP_PLATFORM
              }).catch((e) => {
                socket.disconnect()
                console.error(e)
                window.location = window._env_.REACT_APP_PLATFORM
              })
            } else if (result.isDenied) {
              Swal.fire('Changes are not saved', '', 'info')
            }
          })
        }
      })
    }
  }
  

  // listen to see if you were kicked
  useEffect(() => {
    const handleKick = (message) => {
      if (message.message.id === user._id) {
        handleClick('kicked');
      }
    }
    socket.on(userKick, handleKick)
    return () => {
      socket.off(userKick, handleKick)
    }
  }, [user])

  return (
    <>
      <Tooltip content="Leave Space">
        <Fab onClick={handleClick}
          icon="call_end"
          className="hangUp"
          style={{ backgroundColor: 'var(--mdc-theme-error)' }}
          theme={['onError']}>
        </Fab>
      </Tooltip>
    </>
  )
}