import { Fab } from '@rmwc/fab';
import { Tooltip } from '@rmwc/tooltip';
// import './Buttons.css'

export default function SpaceInfoButton(props) {

   const handleClick = () => {
      props.setSpaceInfoOpen(!props.spaceInfoOpen)
   }

   const opened = props.spaceInfoOpen;

   return (
      <>
         <div className={`spaceInfoButtonWrapper ${opened ? 'closeButton' : ''}`}>
            <Tooltip content="Space Information">
               <Fab onClick={handleClick}
                  mini
                  className={`primePillBtn bbtn ${opened ? 'active' : ''}`}
                  //  icon={props.spaceInfoOpen ? 'close' : 'info'}>
                  icon='info'
               />
            </Tooltip>
         </div>
      </>
   )
}