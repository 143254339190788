import TileHeader from 'aesthetic/TileHeader';
import DataTile from 'components/dataTiles/DataTile';
import { Draggable, Droppable } from "react-beautiful-dnd";
import { draggableStyle } from 'styles/draggableStyle';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { assignLevel } from 'app/view';
import { Tooltip } from '@rmwc/tooltip';

export default function Folder(props){
  const dispatch = useDispatch()
  const params = useParams()

  const handleExpandClick = (e) => {
    if(e.target.className !== 'dataTileTitle' && e.target.nodeName !== 'INPUT') dispatch(assignLevel(props.id))
  }

  return (
    <Draggable draggableId={props.id} index={props.index} isDragDisabled={props.accessLevel === 'owner' ? false : true}>
      {(provided, snapshot) => (
        <>
          <div  
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={`folderTile ${props.accessLevel === 'owner' ? 'owner' : ''} flex-container-column`}
            style={draggableStyle(snapshot, provided.draggableProps.style, props.id)}
            id={props.id}
          >
            <div onDoubleClick={handleExpandClick}>
             <TileHeader 
               dragging={snapshot.isDragging} 
               title={props.item.title} 
               id={props.id} 
               roomId={params.roomId} 
               accessLevel={props.accessLevel} 
               edit={true} 
               virtual={true}
             />
            </div>
            { props.large !== true && 
              <div className="data-tile-shield" />
            }
            <div className="folderWrapper center flex-start flex-container-column" onDoubleClick={handleExpandClick}>
              <TilePreview item={props.item}/>
            </div>
            <Tooltip content={'Open Folder'} align="bottom">
              <div className="expandAnchor flex-container-row center" onClick={handleExpandClick}>
                <span className="material-icons">expand_more</span>
              </div>
            </Tooltip>
          </div>
        </>
      )}
    </Draggable>
  );
}

export function TilePreview(props){
  return (
    <>
      <div className="folder-grid full-width flex-wrap grid-center">
        {
          props.item.files.map((file, ind) => {
            return(
              <div className="flex-container-column center folderItemPreview full-width">
                <DataTile
                  id={file._id}
                  key={file._id}
                  index={ind}
                  item={file}
                  thumbnail={true}
                />
              </div>
            )
          })
        }
      </div>
    </>
  )
}