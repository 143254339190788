import { createSlice } from "@reduxjs/toolkit";

export const spaceSlice = createSlice({
  name: 'space',
  initialState: {
    teamId: undefined
  },
  reducers: {
    assignTeamId: (state, action) => {
      return {
        ...state,
        teamId: action.payload
      }
    }
  }
})

export const { assignTeamId } = spaceSlice.actions
export default spaceSlice.reducer