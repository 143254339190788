import { useImageAsset } from 'net/tiles';
import TileHeader from 'aesthetic/TileHeader'
import { Draggable } from "react-beautiful-dnd";
import { draggableStyle } from '../../styles/draggableStyle';
import { useSelector } from 'react-redux';

export default function ImageTile(props){
  const fullScreen = useSelector(state => state.view.fullScreen)
  const img = useImageAsset(props.item.teamId, props.item.roomId, props.item.fileName)
  let title = props.item.fileName.split('.')
  // remove file extension
  title.pop()
  title = title.join('')

  const handleMouseEnter = (e) => {
    try {
      let header = document.getElementById(props.id)
      if(!props.large) header.firstElementChild.style.opacity = 1
    } catch (error) {
    }
  }

  const handleMouseLeave = (e) => {
    try {
      let header = document.getElementById(props.id)
      if(!props.large) header.firstElementChild.style.opacity = 0
    } catch (error) {
    }
  }

  if(img.isLoading || img.isError){
    if(props.preview){
      return(
        <div className="loadTile flex-container-column" id={props.id}>
          <TileHeader title={title} id={props.id} accessLevel={props.accessLevel} teamId={props.item.teamId} roomId={props.item.roomId} fileName={props.item.fileName} edit={false} large={props.large}/>
          <div className="imgWrapper">
              <img src={img.isError ? '/assets/tile-error.png' : '/assets/tile-loading.svg'} className="full-img-contain" alt="" />
          </div>
        </div>
      )
    }
    return(
      <Draggable draggableId={props.id} index={props.index} isDragDisabled={props.accessLevel === 'owner' && !fullScreen ? false : true}>
        {(provided, snapshot) => (
          <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className="loadTile flex-container-column"
            style={draggableStyle(snapshot, provided.draggableProps.style, props.id, props.large)}
            id={props.id}
          >
            <TileHeader title={title} id={props.id} accessLevel={props.accessLevel}/>
            <div className="imgWrapper">
              <img src={img.isError ? '/assets/tile-error.png' : '/assets/tile-loading.svg'} className="full-img-contain" alt="" />
            </div>
          </div>
        )}
      </Draggable>
    )
  }

  const src = URL.createObjectURL(img.data)

  if(props.preview){
    return(
      <div className="defaultTile flex-container-column" id={props.id}>
        <TileHeader title={title} id={props.id} accessLevel={props.accessLevel} teamId={props.item.teamId} roomId={props.item.roomId} fileName={props.item.fileName} edit={false} large={props.large}/>
        <div className="imgWrapper">
          <img src={src} className="full-img-contain" alt="" />
        </div>
      </div>
    )
  }
  
  return (
    <Draggable draggableId={props.id} index={props.index} isDragDisabled={props.accessLevel === 'owner' && !fullScreen ? false : true}>
      {(provided, snapshot) => (
        <div
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={`defaultTile ${props.accessLevel === 'owner' ? 'owner' : ''} flex-container-column`}
          style={draggableStyle(snapshot, provided.draggableProps.style, props.id, props.large)}
          id={props.id}
        >
          <TileHeader title={title} id={props.id} accessLevel={props.accessLevel} teamId={props.item.teamId} roomId={props.item.roomId} fileName={props.item.fileName} edit={false} large={props.large}/>
          <div className="imgWrapper">
            <img src={src} className="full-img-contain" alt="" />
          </div>
        </div>
      )}
    </Draggable>
  );
}