import { useDispatch, useSelector } from 'react-redux';
import { updateWidth, setWidth, openMM, setResize } from 'app/mattermost';

export default function CalendarIframe() {
   const dispatch = useDispatch()

   const showCal = useSelector(state => state.calendar.show)

   const src = window._env_.REACT_APP_CALENDAR_URL;

   function onMouseUp(event) {
      dispatch(setResize({ resize: false, event }));
   }

   return (
      <div
         className="calendar-wrapper"
         style={{
            'position': 'absolute',
            'width': `100%`,
            'left': '0',
            'right': '0',
            // 'bottom': showCal ? '100%' : '0',
            'top': showCal ? '0' : '100%',
            // 'height': showCal ? `calc(100% - ${100 + 38}px)` : '0',
            'height': showCal ? `100%` : `0px`,
            // 'height': '100%',
            'opacity': showCal ? 1 : 0,
            zIndex: 99,
            'bottom': 0,
         }}
         onMouseUpCapture={onMouseUp}
      >
         <iframe
            id="cal_iframe"
            src={src}
            // src="https://mm.staging-spaces.3data.io/"
            title="Calendar"
            sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
            allowFullScreen
            height="100%"
            width="100%"
         ></iframe>
      </div>
   )
}