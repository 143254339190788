import { Draggable } from "react-beautiful-dnd";
import TileHeader from 'aesthetic/TileHeader'
import { draggableStyle } from "../../styles/draggableStyle";
import { useSelector } from "react-redux";

export default function InlineFrameTile(props){
  const fullScreen = useSelector(state => state.view.fullScreen)

  const handleMouseEnter = (e) => {
    try {
      let el = document.getElementById(props.id)
      if(!props.large) el.firstChild.style.opacity = 1
    } catch (error) {
    }
  }

  const handleMouseLeave = (e) => {
    try {
      let el = document.getElementById(props.id)
      if(!props.large) el.firstChild.style.opacity = 0
    } catch (error) {
    }
  }

  return (
    <Draggable draggableId={props.id} index={props.index} isDragDisabled={props.accessLevel === 'owner' && !fullScreen ? false : true}>
      {(provided, snapshot) => (
        <div
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={`defaultTile ${props.accessLevel === 'owner' ? 'owner' : ''} flex-container-column`}
          style={draggableStyle(snapshot, provided.draggableProps.style, props.id, props.large)}
          id={props.id}
        >
          
          <TileHeader title={props.item.title} id={props.id} accessLevel={props.accessLevel} edit={true} virtual={true} {...props} />
          { props.large !== true &&  
            <div className="data-tile-shield" />
          }
          <div className="iframeWrapper">
            <iframe
              // onLoad={onIframeLoad}
              id={props.id}
              src={props.item.src}
              className="inlineFrameTile"
              height="99%"
              width="100%"
              title={'kratos-tile'}
              name={'kratos-tile'}
              allowFullScreen
              sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
            />
          </div>
        </div>
      )}
    </Draggable>
  );
}