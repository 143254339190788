import { Fab } from '@rmwc/fab';
import { Tooltip } from '@rmwc/tooltip';
import { SocketContext } from "context/socket";
import { useContext } from "react";
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { updateShow as updateVideoShow, updateShowParticipants } from 'app/video';
import { openMM } from "app/mattermost"
import './Buttons.css'
import { useEffect } from 'react';

export default function ParticipantsButton(props) {
   const dispatch = useDispatch();
   const socket = useContext(SocketContext)
   const setParticipantsOpen = 'participantsOpen'
   const videoShowing = useSelector(state => state.video.show);
   //   const handleClick = () => {
   //     socket.emit(setParticipantsOpen, {inUse: true})
   //     props.setParticipantsOpen(!props.participantsOpen)
   //   }
   const opened = useSelector(state => state.video.showParticipants);


   // const jitsi = useSelector(state => state.video.jitsi);
   // function handleClick() {
   //    // if (!jitsi) return;
   //    // if (!window || !window.jitsi) return;
   //    if (!opened) return;
   //    if (!videoShowing) {
   //       dispatch(updateVideoShow(true));
   //       window.jitsi.executeCommand('toggleParticipantsPane', true);
   //       // window.showPartici = true;
   //       dispatch(updateShowParticipants(true));
   //       return;
   //    }
   //    const showing = window.showPartici || false;
   //    const show = !showing;
   //    window.jitsi.executeCommand('toggleParticipantsPane', show);
   //    // window.showPartici = show;
   //    dispatch(updateShowParticipants(show));
   // }

   function handleClick() {
      // if (!jitsi) return;
      // if (!window || !window.jitsi) return;
      if (!videoShowing) {
         dispatch(updateVideoShow(true));
         window.jitsi.executeCommand('toggleParticipantsPane', true);
         // window.showPartici = true;
         // dispatch(updateShowParticipants(true));

         dispatch(openMM(false));
         return;
      }
      const showing = opened;
      const show = !showing;
      window.jitsi.executeCommand('toggleParticipantsPane', show);
      // window.showPartici = show;
      // dispatch(updateShowParticipants(show));
      if (show) {
         dispatch(openMM(false));
      }
   }

   return (
      <>
         {/* <div className={`participantsButtonWrapper ${opened ? 'closeButton' : ''}`}> */}
         <div className={`participantsButtonWrapper`}>
            <Tooltip content="Participants">
               <Fab
                  onClick={handleClick}
                  className={`primePillBtn bbtn ${opened ? 'active' : ''}`}
                  // icon={opened? 'close' : 'account_circle'}
                  icon="account_circle"
                  mini>
               </Fab>
            </Tooltip>
         </div>
      </>
   )
}