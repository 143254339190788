import { Fab } from "@rmwc/fab";
import { Tooltip } from "@rmwc/tooltip";
import { useSelector } from "react-redux";
import './Buttons.css'

export default function UploadAsset(props) {

   const handleClick = () => {
      props.setUploadAssetOpen(!props.uploadAssetOpen)
   }

   const opened = props.uploadAssetOpen;

   const isOwner = useSelector(state => state.user.isOwner);

   return (
      <>
         {/* <div className={`assetButtonWrapper ${props.uploadAssetOpen ? 'closeButton' : ''}`}> */}
         <div
            className={`assetButtonWrapper ${!isOwner && 'display-none'}`}
         >
            <Tooltip content={'Add Asset'}>
               <Fab onClick={handleClick}
                  mini
                  //  icon={props.uploadAssetOpen ? 'close' : 'dashboard_customize'}
                  icon="dashboard_customize"
                  // className="primePillBtn"
                  className={`primePillBtn bbtn ${opened ? 'active' : ''}`}
               >
               </Fab>
            </Tooltip>
         </div>
      </>
   )
}