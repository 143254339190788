import { createSlice } from "@reduxjs/toolkit";

export const participantsSlice = createSlice({
  name: 'participants',
  initialState: {
    participants: {},
    cursors: {}
  },
  reducers: {
    assignParticipants: (state, action) => {
      return {
        ...state,
        participants: {
          ...state.participants,
          [action.payload.id]: { ...action.payload }
        }
      }
    },
    assignCursors: (state, action) => {
      return {
        ...state,
        cursors: {
          ...state.cursors,
          [action.payload.id]: { ...action.payload }
        }
      }
    }
  }
})

export const { assignParticipants, assignCursors } = participantsSlice.actions
export default participantsSlice.reducer