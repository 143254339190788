import { useContext } from 'react';
import { SocketContext } from 'context/socket';
import { Draggable } from "react-beautiful-dnd";
import { useEffect } from 'react';
import { draggableStyle } from '../../styles/draggableStyle';
import { useState } from 'react';
import { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { updateVirtualAssetName } from 'net/assets';
import { destroyVNCAsset } from 'net/tiles';
import { useDispatch, useSelector } from 'react-redux';
import { assignVncLoading } from 'app/view';
import { ProgressBar } from "react-step-progress-bar";
import RFB from 'novnc-core'
import FullScreenButton from 'buttons/FullScreenButton';
import TileHeader from 'aesthetic/TileHeader';
import './DataTiles.css'
import { Ripple, RippleSurface } from '@rmwc/ripple';
import UnmountButton from 'buttons/UnmountButton';
import { Tooltip } from '@rmwc/tooltip';


export default function VNCTile(props) {
   const socket = useContext(SocketContext)
   const dispatch = useDispatch()
   const params = useParams()
   const sceneUpdate = 'scene-string'
   const closeTile = 'close-tile'
   const fullScreen = useSelector(state => state.view.fullScreen)
   const timer = 50000
   const vncLoading = useSelector(state => state.view.vncLoading)
   const [loading, setLoading] = useState(false)
   const [editing, setEditing] = useState(false)
   const [fileName, setFileName] = useState(props.item.title)
   const editingRef = useRef(editing)
   const propsRef = useRef(props)
   let rfb;
   let loadingInterval

   const broadcastPosition = (e) => {
      const { clientX, clientY } = e
      let propX = clientX === 0 ? 0 : clientX / window.innerWidth
      let propY = clientY === 0 ? 0 : clientY / window.innerHeight
      let customCursor = new CustomEvent('vncmousemove', { detail: { cursorX: propX, cursorY: propY } })
      document.dispatchEvent(customCursor)
   }

   const onConnect = () => {
      if (props.large) {
         document.getElementById(`${props.id}canvas`).firstChild.firstChild.addEventListener('mousemove', broadcastPosition, false)
      }
   }

   const credentialsrequired = () => {
      rfb.sendCredentials({ "password": "1234" })
   }

   const titleDoubleClick = () => {
      console.log('test');
      if (props.accessLevel === 'owner') setEditing(true)
   }

   const checkForSubmit = (e) => {
      if ((e.code === 'Enter' || e.target.id !== 'edit-tile-name') && editingRef.current) {
         let el = document.getElementById('edit-tile-name')
         if (el) {
            if (el.value === "") {
               setFileName(propsRef.current.item.title)
            } else {
               let newName = el.value
               updateVirtualAssetName(params.roomId, propsRef.current.id, newName).then((res) => {
                  socket.emit(sceneUpdate, res.value.scene2d)
               }).catch(() => {
                  setFileName(propsRef.current.item.title)
               })
               setFileName(el.value)
            }
         }
         setEditing(false)
      }
   }

   const checkProgress = () => {
      let initTime = vncLoading[props.id].initTime
      let currTime = new Date().getTime()
      let diff = currTime - initTime
      if (diff >= timer) {
         setLoading(false)
         dispatch(assignVncLoading({
            id: props.id,
            done: true,
            initTime: vncLoading[props.id].initTime,
            progress: 1,
         }))

         clearInterval(loadingInterval)
      } else {
         setLoading(true)
         let progress = diff / timer
         dispatch(assignVncLoading({
            id: props.id,
            done: false,
            initTime: vncLoading[props.id].initTime,
            progress: progress,
         }))
      }
   }

   useEffect(() => {
      editingRef.current = editing
      propsRef.current = props
   })

   useEffect(() => {
      setFileName(props.item.title)
   }, [props.item.title])

   useEffect(() => {
      // set refresh interval to random # between 750 and 1000
      let refreshRate = Math.floor(Math.random() * 250) + 750
      // if the VNC tile is in the loading object
      if (vncLoading[props.id] !== undefined) {
         // and if it's not done loading
         if (!vncLoading[props.id].done) loadingInterval = setInterval(checkProgress, refreshRate)
      } else {
         setLoading(false)
      }

      return () => {
         clearInterval(loadingInterval)
      }
   }, [])

   useEffect(() => {
      if (!loading) {
         rfb = new RFB(document.getElementById(`${props.id}canvas`), `wss://${props.item.src}`)
         rfb.addEventListener('connect', onConnect)
         rfb.addEventListener('credentialsrequired', credentialsrequired)
         rfb.scaleViewport = true
         rfb.resizeSession = true

         document.addEventListener('click', checkForSubmit)
         document.addEventListener('keydown', checkForSubmit)

         return () => {
            rfb.disconnect()
            document.removeEventListener('click', checkForSubmit)
            document.removeEventListener('keydown', checkForSubmit)
         }
      }
   }, [loading])

   const handleCloseClick = () => {

      //get horus props	
      let horusSrc = props.item.src
      let horusSerial = horusSrc.substring(0, horusSrc.indexOf("."))
      //make call to tile close
      socket.emit(closeTile, { _id: props.id })
      //make call to container close
      destroyVNCAsset(params.roomId, horusSerial).then(() => {
      }).catch((err) => {
         console.error(err)
      })
   }

   const handleChange = (e) => {
      setFileName(e.target.value)
   }

   const handleBackClick = () => {
      try {
         rfb.focus()
         rfb.sendKey(0xffe9, "AltLeft", true);
         rfb.sendKey(0xff96, "Left", true);
         rfb.sendKey(0xff96, "Left", false);
         rfb.sendKey(0xffe9, "AltLeft", false);
      } catch (error) {
         console.error(error)
      }
   }

   // const handleForwardClick = () => {
   //   try {
   //     rfb.focus()
   //     rfb.sendKey(0xffe9, "AltLeft", true);
   //     rfb.sendKey(0xff98, "Right", true);
   //     rfb.sendKey(0xff98, "Right", false);
   //     rfb.sendKey(0xffe9, "AltLeft", false);
   //   } catch (error) {
   //     console.error(error)
   //   }
   // }

   //using F5 instead of ctrl + r for mac compatibility
   const handleVncRefresh = () => {
      try {
         rfb.focus()
         rfb.sendKey(0xFFC2, "F5", true);
         rfb.sendKey(0xFFC2, "F5", false);
      } catch (error) {
         console.error(error)
      }
   }

   const handleMouseEnter = (e) => {
      try {
         let el = document.getElementById(props.id)
         if (!props.large) el.firstChild.style.opacity = 1
      } catch (error) {
      }
   }

   const handleMouseLeave = (e) => {
      try {
         let el = document.getElementById(props.id)
         if (!props.large) el.firstChild.style.opacity = 0
      } catch (error) {
      }
   }

   let progress = 0
   try {
      progress = vncLoading[props.id].progress * 100
   } catch (error) {
   }
   if (loading) {
      return (
         <Draggable draggableId={props.id} index={props.index} isDragDisabled={props.accessLevel === 'owner' && !fullScreen ? false : true}>
            {(provided, snapshot) => (
               <div
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  className="loadTile flex-container-column"
                  style={draggableStyle(snapshot, provided.draggableProps.style, props.id)}
                  id={props.id}
               >
                  <TileHeader title={props.item.title} id={props.id} accessLevel={props.accessLevel} {...props} />
                  <div className="imgWrapper">
                     <div className="progressWrapper">
                        <ProgressBar className="vnc-progress" percent={progress} />
                     </div>
                  </div>
               </div>
            )}
         </Draggable>
      )
   }

   return (
      <Draggable draggableId={props.id} index={props.index} isDragDisabled={props.accessLevel === 'owner' && !fullScreen ? false : true}>
         {(provided, snapshot) => {
            if (editing && snapshot.isDragging) {
               checkForSubmit({ code: 'Enter' })
            }
            return (
               <>
                  {props.large &&
                     <div
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        className={`vncTile defaultTile ${props.accessLevel === 'owner' ? 'owner' : ''} flex-container-column`}
                        style={draggableStyle(snapshot, provided.draggableProps.style, props.id, props.large)}
                        id={props.id}
                     >
                        {/* we cant pass rfb variable needed for button clicks through props, so this header section can't be separated */}
                        <div
                           {...provided.dragHandleProps}
                           className="flex-container-row defaultTileHeader"
                           style={{ opacity: props.large ? 1 : 0 }}
                        >
                           {
                              props.thumbnail !== true && props.large === true &&
                              <div
                                 className="flex-container-row center headerPadding"
                                 style={{ paddingLeft: '5px' }}
                              >
                                 {/* <Ripple primary>
                                    <button className="generic btn" onClick={handleBackClick}>
                                       <span className="material-icons">arrow_back</span>
                                    </button>
                                 </Ripple> */}
                                 <Ripple primary>
                                    <button
                                       className="generic btn"
                                       onClick={handleVncRefresh}
                                    >
                                       <Tooltip content="Refresh">
                                          <span className="material-icons md-18">
                                             <img src='/assets/icons/refresh.png' alt="" />
                                          </span>
                                       </Tooltip>
                                    </button>
                                 </Ripple>
                              </div>
                           }
                           {
                              props.thumbnail !== true && !props.large &&
                                 <div style={{ width: '33px' }} />
                           }
                           <div 
                              className="flex-container-row full-width center overflow-hide"
                           >
                              {
                                 (editing && !snapshot.isDragging) &&
                                    <input 
                                       id="edit-tile-name" 
                                       value={fileName} 
                                       onChange={handleChange} 
                                       minLength="1" 
                                       autoFocus 
                                       inputMode="text" 
                                    />
                              }
                              {
                                 (!editing || snapshot.isDragging) &&
                                    <p 
                                       className="dataTileTitle" 
                                       onDoubleClick={titleDoubleClick}
                                    >{fileName}</p>
                              }
                           </div>
                           <div className="flex-container-row flex-end headerPadding">
                              {props.large &&
                                 <FullScreenButton />
                              }
                              {/* { props.accessLevel === 'owner' && 
                              <button className="tileClose btn" onClick={handleCloseClick}>
                                 <span className="material-icons">clear</span>
                              </button>
                              } */}
                              <UnmountButton {...props} isHorus={true} />
                           </div>
                        </div>
                        {props.large !== true &&
                           <div className="data-tile-shield" />
                        }
                        <div className='novnc-canvas' id={`${props.id}canvas`} />
                     </div>
                  }
                  {!props.large &&
                     <div
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={`vncTile defaultTile ${props.accessLevel === 'owner' ? 'owner' : ''} flex-container-column`}
                        style={draggableStyle(snapshot, provided.draggableProps.style, props.id, props.large)}
                        id={props.id}
                     >
                        {/* we cant pass rfb variable needed for button clicks through props, so this header section can't be separated */}
                        <div className="flex-container-row defaultTileHeader" style={{ opacity: props.large ? 1 : 0 }}>
                           {props.thumbnail !== true && props.large === true &&
                              <div className="flex-container-row center headerPadding" style={{ paddingLeft: '5px' }}>
                                 <Ripple primary>
                                    <button className="generic btn" onClick={handleBackClick}>
                                       <span className="material-icons">arrow_back</span>
                                    </button>
                                 </Ripple>
                                 <Ripple primary>
                                    <button className="generic btn" onClick={handleVncRefresh}>
                                       <span className="material-icons md-18"><img src='/assets/icons/refresh.png' /></span>
                                    </button>
                                 </Ripple>
                              </div>
                           }
                           {props.thumbnail !== true && !props.large &&
                              <div style={{ width: '33px' }} />
                           }
                           <div className="flex-container-row full-width center overflow-hide">
                              {(editing && !snapshot.isDragging) &&
                                 <input id="edit-tile-name" value={fileName} onChange={handleChange} minLength="1" autoFocus inputMode="text"></input>
                              }
                              {(!editing || snapshot.isDragging) &&
                                 <p className="dataTileTitle" onDoubleClick={titleDoubleClick}>{fileName}</p>
                              }
                           </div>
                           <div className="flex-container-row flex-end headerPadding">
                              {props.large &&
                                 <FullScreenButton />
                              }
                              {/* { props.accessLevel === 'owner' && 
                    <button className="tileClose btn" onClick={handleCloseClick}>
                      <span className="material-icons">clear</span>
                    </button>
                  } */}
                              <UnmountButton {...props} isHorus={true} />
                           </div>
                        </div>
                        {props.large !== true &&
                           <div className="data-tile-shield" />
                        }
                        <div className='novnc-canvas' id={`${props.id}canvas`} />
                     </div>
                  }
               </>
            )
         }
         }
      </Draggable>
   );
}
