import { regPoster } from './fetcher'
import useSWR from "swr";
var swr_1 = require("swr");
// eslint-disable-next-line no-unused-vars
var fetcher = require("net/fetcher");

export function GetCalendar(owner) {
    // var _a = swr_1.default('/api/me', fetcher.fetcher);
    const {data: dataU, error: errorU} = useSWR(owner === 'owner' ? '/api/me' : null, fetcher);
    // let userId = _a.data ? _a.data._id : 0
    const {data:cal, error:err} = useSWR(() => `/api/calendar/auth/getCalendar/${dataU._id}`, fetcher.fetcher);
    const {data, error}= useSWR(() => `/api/calendar/auth/getCalendarEvents/${dataU._id}/${cal[0].id}`, fetcher.fetcher);

    return {
        data:data,
        isLoading: !error && !data,
        isError: error,
    }
}
export function CreateCalendarEvents(userId, calId, body) {
    return new Promise((resolve, reject) => {
        regPoster(`/api/calendar/auth/createEvent/${userId}/${calId}`, body).then((data) => {
            resolve(data);
        }).catch((e) => {
            reject(e)
        })
    })
}

export function getCalAuthUrl(userId){
    return window._env_.REACT_APP_API_SERVER
    +`/api/calendar/auth?userId=${userId}`;
}