import { Button } from '@rmwc/button';
import { useDefaultAsset } from 'net/tiles';
import { killTileConnection, startTileConnection, getShareString } from 'net/guac';
import { useContext, useEffect, useState } from 'react';
import { Draggable } from "react-beautiful-dnd";
import { SocketContext } from 'context/socket';
import TileHeader from 'aesthetic/TileHeader'
import { draggableStyle } from '../../styles/draggableStyle';

export default function RemoteDesktopTile(props){

  const socket = useContext(SocketContext)
  const guacShareString = 'guac-share-string'

  const defaultAsset = useDefaultAsset(props.item.teamId, props.item.roomId, props.item.fileName)

  const [url, setUrl] = useState(undefined)
  const [connected, setConnected] = useState(false);
  const [btnText, setBtnText] = useState('Start Remote Session')
  
  /**
   * I want to keep the styles for draggable and droppable
   * in the same place but I need to add dynamic variables
   * after the fact
   * 
   * We also sometimes don't want to include the default styling
   * to avoid some list-like animations
   */
   const getDraggableStyleSmall = (snapshot, draggableStyle) =>  {

    let styleWithProps;
    if(!snapshot.isDragging){
      styleWithProps = {}
    }
    else{
      styleWithProps = Object.assign({}, draggableStyle)
    }
    if(snapshot.isDropAnimating){
      styleWithProps.transitionDuration = '0.001s'
    }
    //styleWithProps.backgroundColor = snapshot.isDragging ? '#1e1e1e' : '#292929' 
    styleWithProps.height = snapshot.isDragging ? '33%' : '100%'
    return styleWithProps
  }

  const getDraggableStyleLarge = (snapshot, draggableStyle) =>  {
    let styleWithProps;
    if(!snapshot.isDragging){
      styleWithProps = {}
    }
    else{
      styleWithProps = Object.assign({}, draggableStyle)
    }
    if(snapshot.isDropAnimating){
      styleWithProps.transitionDuration = '0.001s'
    }
    //styleWithProps.backgroundColor = snapshot.isDragging ? '#1e1e1e' : '#292929' 
    styleWithProps.height = snapshot.isDragging ? '50%' : '100%'
    styleWithProps.width = snapshot.isDragging ? '50%' : '100%'
    return styleWithProps
  }

  const handleSessionClick = () => {
    getShareString(defaultAsset.data).then((res) => {
      setBtnText('Remote Session Enabled')
      socket.emit(guacShareString, { 'url': res.data })
    })
  }

  const handleMouseEnter = (e) => {
    try {
      let el = document.getElementById(props.id)
      if(!props.large) el.firstChild.style.opacity = 1
    } catch (error) {
    }
  }

  const handleMouseLeave = (e) => {
    try {
      let el = document.getElementById(props.id)
      if(!props.large) el.firstChild.style.opacity = 0
    } catch (error) {
    }
  }

  useEffect(() => {
    return () => (
      killTileConnection(defaultAsset.data).then(() => {
        setConnected(false)
      })
    )
  }, [])
  
  useEffect(() => {
    if(props.accessLevel !== 'owner'){
      socket.on(guacShareString, (message) => {
        setUrl(message.message.url)
      })
    }
  }, [props.accessLevel])

  useEffect(() => {
    if(defaultAsset.data && props.large && props.accessLevel === 'owner'){
      startTileConnection(defaultAsset.data).then((connection) => {
        setUrl(connection.data)
        setConnected(true)
      }).catch((e) => {
        setConnected(false)
      })
    }
  }, [defaultAsset])

  useEffect(() => {
    if(!props.large && connected && defaultAsset.data && props.accessLevel === 'owner'){
      killTileConnection(defaultAsset.data).then(() => {
        setConnected(false)
      })
    }
  }, [props.large, defaultAsset])

  if(!props.large){
    return(
      <Draggable draggableId={`${props.id}-${props.item.fileName}`} index={props.index} isDragDisabled={props.accessLevel === 'owner' ? false : true}>
        {(provided, snapshot) => (
          <div  
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={`defaultTile ${props.accessLevel === 'owner' ? 'owner' : ''} flex-container-column`}
            style={draggableStyle(snapshot, provided.draggableProps.style, props.id, props.large)}
            id={props.id}
          >
            <TileHeader title={(defaultAsset.isLoading || defaultAsset.isError) ? 'Loading...' : defaultAsset.data.connectionName} id={props.id} accessLevel={props.accessLevel}/>
            <div className="imgWrapper">
              <img src={'/assets/rdp-placeholder.jpg'} className="full-img-cover"/>
            </div>
          </div>
        )}
      </Draggable>
    )
  }

  if(defaultAsset.isLoading || defaultAsset.isError){
    return(
      <Draggable draggableId={`${props.id}-${props.item.fileName}`} index={props.index} isDragDisabled={props.accessLevel === 'owner' ? false : true}>
        {(provided, snapshot) => (
          <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave} 
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className="loadTile"
            style={draggableStyle(snapshot, provided.draggableProps.style, props.id, props.large)}
            id={props.id}
          >
            <TileHeader title={(defaultAsset.isLoading || defaultAsset.isError) ? 'Loading...' : defaultAsset.data.connectionName} id={props.id} accessLevel={props.accessLevel}/>
            <div className="imgWrapper">
              <img src={defaultAsset.isError ? '/assets/tile-error.png' : '/assets/tile-loading.svg'} className="full-img-contain"/>
            </div>
          </div>
        )}
      </Draggable>
    )
  }

  return (
    <Draggable draggableId={`${props.id}-${props.item.fileName}`} index={props.index} isDragDisabled={props.accessLevel === 'owner' ? false : true}>
      {(provided, snapshot) => (
        <div
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={`defaultTile ${props.accessLevel === 'owner' ? 'owner' : ''} flex-container-column`}
          style={draggableStyle(snapshot, provided.draggableProps.style, props.id, props.large)}
          id={props.id}
        >
          { props.accessLevel === 'owner' && 
            <div className="flex-container-row defaultTileHeader">
              <div className="flex-container-row full-width center">
                <p className="dataTileTitle">{defaultAsset.data.connectionName}</p>
              </div>
              <div className="flex-container-row flex-end">
                <Button
                  className="smallPillBtn"
                  onClick={handleSessionClick}
                  disabled={!connected}>
                  {btnText}
                </Button>
              </div>
            </div>
          }
          { props.accessLevel !== 'owner' &&
            <TileHeader title={defaultAsset.data.connectionName} id={props.id} accessLevel={props.accessLevel} edit={true} virtual={true}/>
          }
          <div className="iframeWrapper">
            <iframe
              src={url}
              height="99%"
              width="100%"
              className="inlineFrameTile"
              title={defaultAsset.data.connectionName}
              name={defaultAsset.data.connectionName}
              allowFullScreen
              sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
            />
          </div>
        </div>
      )}
    </Draggable>
  );
}