import { createSlice } from "@reduxjs/toolkit";

export const calendarSlice = createSlice({
   name: 'calendar',
   initialState: {
      show: false,
      height: (window.innerHeight / 2),
   },
   reducers: {
      show: (state, action) => {
         return {
            ...state,
            show: action.payload,
         }
      },
      updateHeight: (state, action) => {
         return {
            ...state,
            height: state.height + (() => {
               // const mousemoveX = action.payload;
               // if (mousemoveX < 0) return Math.abs(mousemoveX);
               // return -Math.abs(mousemoveX)

               return action.payload;
            })(),
         }
      }
   }
})

export const { show, updateHeight } = calendarSlice.actions
export default calendarSlice.reducer;