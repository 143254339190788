import { Button } from '@rmwc/button';
import { SocketContext } from 'context/socket';
import { depositAssetLocker, uploadAsset } from 'net/assets';
import { updateTiles } from 'net/tiles';
import { useContext } from 'react';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
export default function UploadFile(props) {
   const socket = useContext(SocketContext)
   const sceneUpdate = 'scene-string'
   const space = useSelector(state => state.space)
   const params = useParams()
   const fileInput = useRef()

   const handleUploadClick = () => {
      fileInput.current.click()
   }

   const handleFileUpload = () => {
      props.setUploadAssetOpen(false)
      let files = fileInput.current.files
      let newFiles = []

      for (let i = 0; i < files.length; i++) {
         uploadAsset(space.teamId, files[i]).then((res) => {
            if (res.success) {
               depositAssetLocker(space.teamId, params.roomId, files[i].name).then((res) => {
                  newFiles.push(res.data[0])
                  if (newFiles.length === files.length) {
                     let newSmall = Array.from(props.tileListSmall)
                     for (let j = 0; j < newFiles.length; j++) {
                        newSmall.unshift(newFiles[j])
                     }
                     updateTiles(params.roomId, newSmall, props.tileListLarge);
                     let newString = {
                        'dataTile00': props.tileListLarge,
                        'dataTile01': newSmall
                     }
                     socket.emit(sceneUpdate, newString);
                  }
               }).catch((e) => {
                  console.error(e)
               })
            }
         }).catch((e) => {
            console.error(e)
         })
      }
   }

   return (
      <>
         <Button
            onClick={handleUploadClick}
            // className="primePillBtn"
            style={{
               width: '100%',
               border: 'thin solid grey',
               display: 'flex',
               flexDirection: 'row',
               alignItems: 'center',
               justifyContent: 'center',
               textAlign: 'center',
               margin: 'auto',
               marginBottom: '15px'
            }}
         >
            <div
               style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                  fontSize: '15px',
                  margin: 'auto',
               }}
            >
               <span
                  style={{
                     marginRight: '10px'
                  }}
               >Add PDF</span>
               <span className="material-icons">
                  cloud_upload
               </span>
            </div>

         </Button>
         <input
            type="file"
            style={{ display: 'none' }}
            ref={fileInput}
            onChange={handleFileUpload}
            multiple
         />
      </>


   )
}

{/* <>
<Button onClick={handleUploadClick} className="primePillBtn" icon="upload">
  Upload PDF Assets
  <input
    type="file"
    style={{display: 'none'}}
    ref={fileInput}
    onChange={handleFileUpload}
    multiple
  />
</Button>
</> */}