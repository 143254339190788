import {
   createSlice
} from "@reduxjs/toolkit";

const initialState = {
   mode: 'default',
   previousMode: '',
   options: ['default', 'webapp', 'url'],
   tileType: '',
   tileTypeOptions: ['iframe', 'vnc'],
}

export const modalSlice = createSlice({
   name: 'modal',
   initialState: {
      ...initialState,
   },
   reducers: {
      updateMode(state, action) {
         const options = state.options;
         let payload = action.payload;
         if (!payload || !options.includes(payload)) payload = 'default';
         return {
            ...state,
            mode: payload,
            previousMode: state.mode,
         }
      },
      goBackMode(state, action) {
         const options = state.options;
         const currentMode = state.mode;
         const previousModeIndex = options.findIndex(item => item === currentMode);
         if (previousModeIndex === 0) return;
         const newMode = options[previousModeIndex - 1];
         return {
            ...state,
            mode: newMode,
         }
      },
      updateTileType(state, action) {
         const options = state.tileTypeOptions;
         let payload = action.payload || '';
         if (payload && !options.includes(payload)) payload = '';
         return {
            ...state,
            tileType: payload,
         }
      },
      resetModal(state, action) {
         return {
            ...state,
            ...initialState,
         }
      }
   }
})

export const {
   updateMode,
   goBackMode,
   updateTileType,
   resetModal,
} = modalSlice.actions
export default modalSlice.reducer;