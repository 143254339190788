import {
   arrayFetcher,
   blobFetcher,
   fetcher,
   poster
} from './fetcher'

export function startTileConnection(body) {
   return new Promise((resolve, reject) => {
      poster(`/api/guac/startConnection`, body).then((data) => {
         resolve(data);
      }).catch((e) => {
         reject(e)
      })
   })
}

export function killTileConnection(body) {
   return new Promise((resolve, reject) => {
      fetch(`${window._env_.REACT_APP_API_SERVER}/api/guac/killConnection`, {
         method: 'PATCH',
         body: JSON.stringify(body),
         credentials: 'include',
      }).then((res) => {
         const data = res.json()
         if (res.ok) resolve(data)
         reject(data)
      }).catch((e) => {
         reject(e)
      })
   })
}

export function getShareString(body) {
   return new Promise((resolve, reject) => {
      poster(`/api/guac/getConnectionShareString`, body).then((data) => {
         resolve(data);
      }).catch((e) => {
         reject(e)
      })
   })
}



/**
 * 
 **/

//##
export async function generateToken(param) {
   const ENDPOINT = '/api/tokens';
   const baseURL = param?.baseURL || window?._env_.REACT_APP_GUACAMOLE_URL;
   if (!baseURL) {
      const msg = `No baseURL found for guac`;
      return {
         status: false,
         msg
      };
   }

   const fetchURL = baseURL + ENDPOINT;

   try {
      const details = {
         username: 'guacadmin',
         password: 'guacadmin',
      }
      const formBody = [];

      for (let property in details) {
         var encodedKey = encodeURIComponent(property);
         var encodedValue = encodeURIComponent(details[property]);
         formBody.push(encodedKey + "=" + encodedValue);
      }

      console.log(formBody);

      const body = formBody.join('&');

      const response = await fetch(fetchURL, {
         method: 'POST',
         headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
         },
         body,
         // body: JSON.stringify({
         //    username: 'guacadmin',
         //    password: 'guacadmin',
         // }),
      });

      if (!response.ok) return {
         status: false,
         msg: response.statusText
      };

      const tokenObj = await response.json();

      return {
         status: true,
         data: tokenObj
      };

      // const dataSrc = 'postgresql' // 'mysql' || 'sqlserver' || 'postgresql'.

      // const fetcExistingConns = await fetch(`${baseURL}/api/session/data/${dataSrc}/connections?token=${tokenObj.authToken}`);


   } catch (error) {
      console.error(error);
      return {
         status: false,
         msg: error
      };
   }
}

//##
export async function getConnections(param) {
   const DATA_SRC = window?._env_?.REACT_APP_GUACAMOLE_DATA_SOURCE;
   const paramToken = param?.token;
   const storageToken = window?.localStorage.getItem('GUAC_AUTH');
   console.warn({
      storageToken
   });
   const storageTokenJSON = storageToken && storageToken !== 'undefined' && JSON.parse(storageToken);
   const token = paramToken || storageTokenJSON?.authToken;

   if (!DATA_SRC || !token) {
      const msg = `Missing guac data source or auth token!`;
      // window.alert(msg);
      console.error(msg);
      return {
         status: false,
         msg
      };
   }

   const ENDPOINT = `/api/session/data/${DATA_SRC}/connections?token=${token}`;
   const baseURL = param?.baseURL || window?._env_.REACT_APP_GUACAMOLE_URL;

   if (!baseURL) {
      const msg = `No baseURL found for guac`;
      console.error(msg);
      return {
         status: false,
         msg
      };
   }

   const fetchURL = baseURL + ENDPOINT;

   try {
      const response = await fetch(fetchURL);
      if (!response.ok) {
         const msg = response.statusText
         console.error(msg);
         return {
            status: false,
            msg,
         }
      }

      const data = await response.json();

      return {
         status: true,
         data
      }
   } catch (error) {
      console.error(error);
   }


}

export async function createRDPConnection(param) {
   const DATA_SRC = window?._env_?.REACT_APP_GUACAMOLE_DATA_SOURCE;
   const paramToken = param?.token;
   const storageToken = window?.localStorage.getItem('GUAC_AUTH');
   const token = paramToken || (storageToken && JSON.parse(storageToken).authToken);

   if (!DATA_SRC || !token) {
      const msg = `Missing guac data source or auth token!`;
      window.alert(msg);
      console.error(msg);
      return {
         status: false,
         msg
      };
   }

   const ENDPOINT = `/api/session/data/${DATA_SRC}/connections?token=${token}`;
   const baseURL = param?.baseURL || window?._env_.REACT_APP_GUACAMOLE_URL;

   if (!baseURL) {
      const msg = `No baseURL found for guac`;
      console.error(msg);
      return {
         status: false,
         msg
      };
   }

   const fetchURL = baseURL + ENDPOINT;

   // const name = param?.name || window.crypto?.randomUUID?.() || `test-connection-${Math.floor(Math.random() * 100 + 1)}`;
   const name = param?.name || param?.connName;
   const hostname = param?.hostname || param?.host;
   const username = param?.username || '';
   const password = param?.password || '';
   const domain = param?.domain || '';


   if (!name || !hostname) {
      const msg = `Missing connection or host name`;
      return {
         status: false,
         msg,
      }
   }
   //>> Previous version <<//
   const body = JSON.stringify({
      "name": name,
      "parentIdentifier": "ROOT",
      "protocol": "rdp",
      "parameters": {
         "color-depth": "string:32",
         "hostname": hostname,
         "ignore-cert": "true",
         "password": password,
         "domain": domain,
         "port": "3389",
         "resize-method": "string:display-update",
         "security": "any",
         "username": username
      },
      "attributes": {
         "guacd-encryption": null,
         "failover-only": null,
         "weight": null,
         "max-connections": null,
         "guacd-hostname": null,
         "guacd-port": null,
         "max-connections-per-user": null
      }
   });

   //>> Changed version on demand <<//
   // const body = JSON.stringify({
   //    ...param,
   // });
   
   try {
      const response = await fetch(fetchURL, {
         method: 'POST',
         headers: {
            'Content-Type': 'application/json',
         },
         body,
      });

      const data = await response.json();

      return {
         status: true,
         data,
      }
   } catch (error) {
      console.error(error);
      return {
         status: false,
         msg: error,
      }
   }

}