/**
 * Changing audio input device (mic, etc...)
 * 
 * Dual purpose for both pre-call and in-call selection.
 */
import { Menu, MenuSurfaceAnchor, MenuItem} from '@rmwc/menu';
import {useState} from 'react';
import { changeAudioDeviceCall, changeAudioDevicePreview } from 'js/webex-controls';
import { Button } from '@rmwc/button';
import { Tooltip } from '@rmwc/tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { assignAudioDevice } from 'app/user';
import { useEffect } from 'react';

export default function AudioSelection(props){
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false);
  const [devices, setDevices] = useState([])
  const user = useSelector(state => state.user)
  const query = new URLSearchParams(window.location.search)
  const audioSource = query.get('audioSource')

  const handleAction = (index) => {
    if(props.audioPreMuted){
      props.setAudioPreMuted(!props.audioPreMuted)
    }
    // if we're pre-call
    if(!props.webex){
      changeAudioDevicePreview(devices[index].deviceId, user.videoDevice, props.videoPreMuted)
    }
    // otherwise
    else changeAudioDeviceCall(devices[index].deviceId);
    dispatch(assignAudioDevice({ label: devices[index].label, id: devices[index].deviceId }))
    updateSelection()
  }

  const updateSelection = () => {
    let audioDevices = []
    user.availableDevices.forEach((device) => {
      if(device.kind === 'audioinput' && device.label !== ""){
        audioDevices.push(device)
      }
    })
    setDevices(audioDevices)
  }

  useEffect(() => {
    if(user.availableDevices.length > 0){
      updateSelection()
    }
  }, [user.availableDevices])

  // at first we might only have a device label, we want both label and ID
  useEffect(() => {
    devices.forEach((device) => {
      if(device.label === user.audioDevice.label){
        dispatch(assignAudioDevice({ label: device.label, id: device.deviceId }))
      }
    })
  }, [devices])

  // this is for highlighting the correct device once in-call
  useEffect(() => {
    if(audioSource !== null){
      let audioDevices = []
      user.availableDevices.forEach((device) => {
        if(device.kind === 'audioinput' && device.label !== ""){
          if(device.deviceId === audioSource){
            dispatch(assignAudioDevice({ label: device.label, id: device.deviceId }))
          }
          audioDevices.push(device)
        }
      })
      setDevices(audioDevices)
    }
  }, [user.availableDevices])

  if(devices.length === 0){
    return(
      <Tooltip content="Audio Select">
        <Button aria-label="More options" className="btnRight" alt="Select Audio Device" icon="expand_more"/>
      </Tooltip>
    );
  }
  if(devices.length !== 0){
    return(
      <MenuSurfaceAnchor>
        <Menu
          focusOnOpen={false}
          open={open}
          onSelect={(evt) => handleAction(evt.detail.index)}
          onClose={() => setOpen(false)}
          anchorCorner='bottomRight'
          className='audioVideo'   
        >
          { 
            devices.map((device) => (
              <MenuItem className="device-item" selected={device.label === user.audioDevice.label} key={device.deviceId}>{device.label}</MenuItem>
            ))
          }
        </Menu>
        <Tooltip content="Audio Select">
          <Button aria-label="More options" className="btnRight" alt="Select Audio Device" icon="expand_more" onClick={() => setOpen(!open)} />
        </Tooltip>
      </MenuSurfaceAnchor>
    );
  }
}