import VideoPreview from 'components/VideoPreview';
import{ useParams } from 'react-router-dom';
import { useState } from 'react';
import PreviewHeader from 'aesthetic/PreviewHeader';
export default function Preview(props){
  const params = useParams();
  const [videoPreMuted, setVideoPreMuted] = useState(false);
  const [audioPreMuted, setAudioPreMuted] = useState(false);
  
  return(
    <>
      <div className="page-bg"/>
      <div className="animation-wrapper">
          <div className="particle particle-1"></div>
          <div className="particle particle-2"></div>
          <div className="particle particle-3"></div>
          <div className="particle particle-4"></div>
      </div>
    <div className="flex-container-column">
      <PreviewHeader roomId={params.roomId}/>
      <VideoPreview isUserLogin={true} roomId={params.roomId} setAudioPreMuted={setAudioPreMuted} setVideoPreMuted={setVideoPreMuted} audioPreMuted={audioPreMuted} videoPreMuted={videoPreMuted} videoSize={props.videoSize} validInput={true}/>
    </div>
    </>
  );
}
