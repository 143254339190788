import { Fab } from '@rmwc/fab';
import { Tooltip } from '@rmwc/tooltip';
import './Buttons.css'
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { show } from "app/calendar"

export default function MessagingButton(props) {
   const showCal = useSelector(state => state.calendar.show)
   const dispatch = useDispatch()


   function handleClick() {
      dispatch(show(!showCal));
   }


   return (
      <div className={`calendarButtonWrapper ${showCal ? 'closeButton' : ''}`}>
         <Tooltip content="Calendar">
            <Fab onClick={handleClick}
               // icon={showCal ? 'close' : 'calendar_month'}
               icon='calendar_month'
               mini
               className={`primePillBtn bbtn ${showCal ? 'active' : ''}`}>
            </Fab>
         </Tooltip>
      </div>
   )
}
