/**
 * Lets users enter space
 */
import { Button } from '@rmwc/button'
import { BrowserRouter, useParams } from 'react-router-dom'
import { useEffect, useContext } from 'react';
import { SocketContext } from 'context/socket';
import { useDispatch, useSelector } from 'react-redux';
import { useRef } from 'react';
import { fetcher } from 'net/fetcher';
import { updateGuestDisplayName } from 'app/guest';
import { assignName, updateDisplayName } from 'app/user';

export default function GuestJoinSpaceButton(props) {
   const dispatch = useDispatch();
   // const displayName = useSelector(state => state.user.displayName);
   const displayName = useSelector(state => state.user.name);
   const guestLogin = 'guest-login';
   const tokenPass = 'guest-token';
   const paramsGuest = useParams();
   const room = paramsGuest.roomId
   let repeatGuestLogin;

   const socket = useContext(SocketContext)
   const user = useSelector(state => state.user)

   // I don't want to elevate state to the App level
   // so I am using query parameters to pass inforamtion
   // about which devices to enter with and if they're
   // initially muted or not
   let audioSelection = user.audioDevice.id ? user.audioDevice.id : 'default'
   let videoSelection = user.videoDevice.id ? user.videoDevice.id : 'default'
   let queryParams = new URLSearchParams({
      'audio': !props.audioPreMuted,
      'video': !props.videoPreMuted,
      'audioSource': audioSelection,
      'videoSource': videoSelection
   })

   let url = `/space/${room}?${queryParams.toString()}`
   const urlRef = useRef(url)

   const handleClick = () => {
      let val = props.userInput.current.value;
      // dispatch(updateGuestDisplayName(val));
      // dispatch(assignName(val));
      dispatch(updateDisplayName(val));
      console.warn({ val });
      if (val.length === 0) {
         props.setValidInput(false)
      } else {
         let nameData = { name: val }
         sessionStorage.setItem('guestEmail', val)
         sessionStorage.setItem('guestName', val)
         // emit once without delay on button press
         socket.emit(guestLogin, nameData)
         repeatGuestLogin = setInterval(() => {
            socket.emit(guestLogin, nameData)
         }, 7500)
         props.setLoading(true);
      }
   }

   document.onkeydown = (e) => {
      if (e.key === 'Enter') handleClick()
   }

   useEffect(() => {
      urlRef.current = url
   })

   useEffect(() => {
      socket.on(tokenPass, function (message) {
         clearInterval(repeatGuestLogin)
         // we want to be explicit about this
         if (message.message.admit === false) {
            props.setAdmitMessage('Denied')
            setTimeout(() => {
               window.location.href = `/r/${room}`;
            }, 1500)
         } else {
            props.setAdmitMessage('Admitted')
            window._env_.GUEST_TOKEN = message.message.guestToken;
            sessionStorage.setItem('guestToken', message.message.guestToken)
            sessionStorage.setItem('roomId', room)
            // window.location.href = urlRef.current;
            window.location.href = `${urlRef.current}&displayName=${displayName}`;
         }
      })
   }, [user])

   let disabled = !props.videoInit

   if (props.audioPreMuted && props.videoPreMuted) disabled = true

   return (
      <BrowserRouter forceRefresh={true}>
         <Button className="primebtnColor" label="Join Space" raised onClick={handleClick} disabled={disabled} />
      </BrowserRouter>
   )
}