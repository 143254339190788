import React, { useState, useEffect, useRef, useContext } from 'react';
import FullScreenButton from 'buttons/FullScreenButton';
import CanvasDraw from "react-canvas-draw";
import { Draggable } from "react-beautiful-dnd";
import { SocketContext } from 'context/socket';

export default function WhiteboardTile(props) {
   const socket = useContext(SocketContext);
   const whiteboard = 'whiteboard';
   const whiteboardClick = 'whiteboard-click'

   const [coordinates, setCoordinates] = useState();
   const [clearCanvas, setClearCanvas] = useState();

   const [coordinatesTracker, setCoordinatesTracker] = useState();

   useEffect(() => {
      if (props.accessLevel !== 'owner') {
         socket.on(whiteboard, (message) => {
            if (!message) return;
            setCoordinatesTracker(message.message.drawing)
            if (message.message.drawing) {
               localStorage.setItem('currentSketch', message.message.drawing)
            }
         })
      }
      return () => (
         socket.off(whiteboard)
      )
   }, [props.accessLevel])

   const getDraggableStyleSmall = (snapshot, draggableStyle) => {

      let styleWithProps;
      if (!snapshot.isDragging) {
         styleWithProps = {}
      }
      else {
         styleWithProps = Object.assign({}, draggableStyle)
      }
      if (snapshot.isDropAnimating) {
         styleWithProps.transitionDuration = '0.001s'
      }
      //styleWithProps.backgroundColor = snapshot.isDragging ? '#1e1e1e' : '#292929' 
      styleWithProps.height = snapshot.isDragging ? '33%' : '100%'
      styleWithProps.width = snapshot.isDragging ? '33%' : '100%'
      return styleWithProps
   }

   const getDraggableStyleLarge = (snapshot, draggableStyle) => {
      let styleWithProps;
      if (!snapshot.isDragging) {
         styleWithProps = {}
      }
      else {
         styleWithProps = Object.assign({}, draggableStyle)
      }
      if (snapshot.isDropAnimating) {
         styleWithProps.transitionDuration = '0.001s'
      }
      styleWithProps.height = snapshot.isDragging ? '100%' : '100%'
      styleWithProps.width = snapshot.isDragging ? '100%' : '100%'
      return styleWithProps
   }

   const whiteboardUndo = () => {
      if (clearCanvas) {
         clearCanvas.undo()
      }
   }

   const whiteboardClear = () => {
      if (clearCanvas) {
         clearCanvas.clear()
         localStorage.clear()
         setClearCanvas(null);
      }
   }

   const handleCloseClick = () => {
      if (props.accessLevel === 'owner') {
         socket.emit(whiteboardClick, { inUse: false })
      }
   }

   return (
      <Draggable draggableId={props.id} index={props.index} isDragDisabled={props.accessLevel === 'owner' ? false : true}>
         {(provided, snapshot) => (
            <div
               ref={provided.innerRef}
               {...provided.draggableProps}
               className={`defaultTile ${props.accessLevel === 'owner' ? 'owner' : ''} flex-container-column`}
               style={props.large ? getDraggableStyleLarge(snapshot, provided.draggableProps.style) : getDraggableStyleSmall(snapshot, provided.draggableProps.style)}
            >
               <div {...provided.dragHandleProps} className="flex-container-row defaultTileHeader">
                  <div className="flex-container-row full-width center">
                     <p className="dataTileTitle center">Whiteboard</p>
                  </div>
                  {props.accessLevel === 'owner' && props.large ? (
                     <div className="flex-container-row flex-end closeBtn">
                        {props.large &&
                           <FullScreenButton />
                        }
                     </div>
                  ) : (<></>)
                  }
               </div>
               {props.accessLevel === 'owner' ? (
                  <div className="canvas">
                     <CanvasDraw
                        hideInterface
                        brushRadius={4}
                        canvasWidth="100%"
                        canvasHeight="93%"
                        disabled={props.utility}
                        hideGrid={true}
                        immediateLoading={true}
                        saveData={localStorage.getItem('currentSketch') ? localStorage.getItem('currentSketch') : coordinatesTracker}
                        onChange={(event) => {
                           setCoordinates(event.getSaveData());
                           if (event.getSaveData()) {
                              localStorage.setItem('currentSketch', event.getSaveData())
                           }
                           setCoordinatesTracker(event.getSaveData());
                           setClearCanvas(event)
                           socket.emit(whiteboard, { 'drawing': event.getSaveData() })
                        }}
                     />
                     {
                        props.large ? (
                           <div className="btn-group">
                              <button className="btn" onClick={whiteboardUndo}>
                                 <span className="material-icons">undo</span>
                                 Undo
                              </button>
                              <button className="btn" onClick={whiteboardClear}>
                                 <span className="material-icons">clear</span>
                                 Clear
                              </button>
                           </div>
                        ) : (<></>)
                     }
                  </div>
               ) : (
                  <div className="canvas">
                     <CanvasDraw
                        hideInterface
                        brushRadius={4}
                        hideGrid={true}
                        canvasWidth="100%"
                        canvasHeight="93%"
                        disabled
                        style={{ zIndex: 9999 }}
                        immediateLoading={true}
                        saveData={coordinatesTracker}
                     />
                  </div>
               )}
            </div>
         )}
      </Draggable>
   )
}