import { Fab } from '@rmwc/fab';
import { Tooltip } from '@rmwc/tooltip';
import './Buttons.css'
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateShow as updateVideoShow } from "app/video";
import { openMM } from "app/mattermost"

export default function ToggleVideoButton() {
   const showVid = useSelector(state => state.video.show);
   const dispatch = useDispatch()

   function handleClick() {
      const newShowVid = !showVid;
      dispatch(updateVideoShow(newShowVid));
      (newShowVid) && (dispatch(openMM(false)));
   }

   return (
      // <div className={`videoButtonWrapper ${showVid ? 'closeButton' : ''}`}>
      <div className={`videoButtonWrapper`}>
         <Tooltip content={showVid ? 'Toggle Video Tiles' : 'Toggle Video Tiles'}>
            <Fab onClick={handleClick}
               // icon={showVid ? 'close' : 'group'}
               icon="group"
               mini
               className={`primePillBtn bbtn ${showVid ? 'active' : ''}`}
            ></Fab>
         </Tooltip>
      </div>
   )
}
