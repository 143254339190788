import { createSlice } from "@reduxjs/toolkit";

export const meetingSlice = createSlice({
  name: 'meeting',
  initialState: {
    sharing: undefined
  },
  reducers: {
    assignSharing: (state, action) => {
      return {
        ...state,
        sharing: action.payload
      }
    }
  }
})

export const { assignSharing } = meetingSlice.actions
export default meetingSlice.reducer