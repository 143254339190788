import { createSlice } from "@reduxjs/toolkit";

export const ndrcSlice = createSlice({
   name: 'ndrcpip',
   initialState: {
      show: false,
   },
   reducers: {
      updateShow: (state, action) => {
         const payload = !!(action?.payload);
         return {
            ...state,
            show: payload,
         }
      }
   }
})

export const { updateShow } = ndrcSlice.actions
export default ndrcSlice.reducer