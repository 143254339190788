import { Dialog } from "@rmwc/dialog";
import { useRoom } from "net/rooms";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { getMeetingMembers, muteUser, removeUser } from 'js/webex-controls';
import { Button } from "@rmwc/button";
import copy from 'copy-to-clipboard';
import io from 'socket.io-client'
import { Fab } from "@rmwc/fab";
import { MenuItem, MenuSurfaceAnchor, Menu } from "@rmwc/menu";
import { Markup } from 'interweave';
import { SocketContext } from "context/socket";
import { Divider } from "@react-spectrum/divider";

export default function CalEventModal(props) {
   const params = useParams()
   // const room = useRoom(params.roomId);

   const handleClose = () => {
      props.setIsOpen(false)
      props.resetCalData();
   }

   if (props.calData) {
      let date = new Date(props.calData.event._instance.range.start).toLocaleString()

      // var time = new Date(date.getTime() * 1000);
      // // Hours part from the timestamp
      // var hours = time.getHours();
      // // Minutes part from the timestamp
      // var minutes = "0" + time.getMinutes();
      // // Seconds part from the timestamp
      // var seconds = "0" + time.getSeconds();

      // // Will display time in 10:30:23 format
      // var formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
      // console.log(formattedTime);

      // console.log('START DATE:', date.getTime());
      return (
         <>
            <Dialog open={props.isOpen}
               onClose={handleClose}
               // onOpen={handleOpen}
               className="calInfo">
               <div className="flex-container-row flex-end">
                  <button className="whiteboardClose btn" onClick={handleClose}>
                     <span className="material-icons">clear</span>
                  </button>
               </div>
               <h1 className="spaceInfoTitle">{props.calData ? props.calData.event._def.title : ''}</h1>
               <div className="flex-container-row detailSection">
                  <div style={{ width: '50%', overflow: 'hidden' }}>
                     {props.calData ? <Markup content={`${props.calData.event._def.extendedProps.description}`} /> : ''}
                  </div>
                  <Divider orientation="vertical" />
                  <div className="flex-container-column participantsList">
                     <h3 className="modalSubHeading">Participants</h3>
                     <div className="flex-container-column">
                        {
                           props.calData.event._def.extendedProps.participants.map((participant, index) => {
                              return (
                                 <div className="flex-container-row participantItem">
                                    <div className="flex-container-row userGrouping" style={{ overflow: 'scroll' }}>
                                       <span className="material-icons md-24">account_circle</span>
                                       <h5 className="participantName">{participant.name}</h5>
                                    </div>
                                 </div>
                              )
                           })
                        }
                     </div>
                  </div>
               </div>
            </Dialog>
         </>
      )
   }
   return (<></>)
}
