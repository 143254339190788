import { Fab } from '@rmwc/fab';
import { Tooltip } from '@rmwc/tooltip';
import './Buttons.css'
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateShow } from "app/ndrcpip"

export function NdrcButton(props) {
   const ENV = useSelector(state => state.env);
   const showN = useSelector(state => state.ndrc.show)
   const dispatch = useDispatch()

   function handleClick() {
      dispatch(updateShow(!showN));
   }

   return (
      <div
         className={`ndrcButtonWrapper ${showN ? 'closeButton' : ''}`}
         style={{
            display: ENV.ENTITY === 'ndrc' ? 'block' : 'none',
         }}
      >
         <Tooltip content="Google Meet">
            <Fab
               mini
               className={`primePillBtn bbtn ${showN ? 'active' : ''}`}
               onClick={handleClick}
               // icon={showN ? 'close' : ''}
               style={{
                  color: '#b00020'
               }}
            >
               <img src="https://img.icons8.com/ios-filled/50/ffffff/google-meet.png" alt="" width="25px" height="25px" style={{
                  // display: showN ? 'none' : 'block',
                  color: 'white',
               }} />
            </Fab>
         </Tooltip>
         {props.messageNotification &&
            <span className="badge" />
         }
      </div>
   )
}