import { Dialog } from "@rmwc/dialog";
import { Button } from '@rmwc/button';
import { useRef } from "react";
import { v4 as uuidv4 } from 'uuid'
import { useParams } from "react-router-dom";
import { useContext } from "react";
import { SocketContext } from "context/socket";
import { getVNCAsset, updateTiles } from "net/tiles";
import UploadFile from "buttons/UploadFile";
import CreateRDP from "buttons/CreateRDP";
import RDPModal from "./RDPModal";
import { useState } from "react";
import { queue } from "aesthetic/AlertQueue";
import { useDispatch } from "react-redux";
import { assignVncLoading } from "app/view";
import { useSelector } from "react-redux";
import './Modals.css'
import {
   show as setRDPOpen,
} from "app/rdp";
import AddWebApp from "buttons/AddWebApp";
import {
   updateMode as updateModalMode,
   goBackMode as goBackModalMode,
   resetModal,
} from "app/modal"
import ShareControl from "buttons/ShareControl";
import SingleControl from "buttons/SingleControl";


export default function UploadAssetModal(props) {
   const socket = useContext(SocketContext)
   const sceneUpdate = 'scene-string'
   const dispatch = useDispatch()
   const params = useParams()
   const inputRef = useRef()
   const rdpOpen = useSelector(state => state.rdp.show);
   const modalState = useSelector(state => state.modal);

   // const [rdpOpen, setRDPOpen] = useState(false)
   // for "+" icon button loading animation eventually
   const [vncLoading, setVncLoading] = useState(true)

   const addWebAppClick = () => {
      dispatch(updateModalMode('webapp'));
   }

   const handleClose = () => {
      (inputRef.current) && (inputRef.current.value = "");
      setVncLoading(false)
      props.setUploadAssetOpen(false)
      dispatch(resetModal());
   }

   const handleClose2 = () => {
      setVncLoading(false)
      props.setUploadAssetOpen(false)
      dispatch(resetModal());
   }

   const validURL = (str) => {
      var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
         '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
         '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
         '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
         '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
         '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
      return !!pattern.test(str);
   }

   const isValidUrl = (theUrl) => {
      console.log("The url we are using is " + String(theUrl))
      let urlValue = new URL(theUrl);
      try {
         new URL(theUrl);
         console.log(urlValue)
         return true;
      } catch (err) {
         console.log(err)
         return false;
      }
   }

   const handleUrlSubmitIframe = () => {
      console.log('handleUrlSubmit');
      setVncLoading(true)
      let url = inputRef?.current?.value
      if (validURL(url)) {
         const id = uuidv4()
         dispatch(assignVncLoading({
            id: id,
            done: false,
            initTime: new Date().getTime(),
            progress: 0,
         }))

         let newSmall = props.tileListSmall
         let newLarge = props.tileListLarge
         newSmall.unshift({
            "_id": id,
            "fileName": "iframe",
            "title": "Data Tile",
            "type": "",
            "src": url
         })
         updateTiles(params.roomId, newSmall, newLarge)
         let newString = {
            'dataTile00': newLarge,
            'dataTile01': newSmall
         }
         socket.emit(sceneUpdate, newString);
         props.setTileListSmall(newSmall)

         // getVNCAsset(params.roomId, url).then((res) => {
         //   let newSmall = props.tileListSmall
         //   let newLarge = props.tileListLarge
         //   newSmall.unshift({
         //     "_id": id,
         //     "fileName": "vnc",
         //     "title": "Data Tile",
         //     "src": res.cobrowseSession.url
         //   })
         //   updateTiles(params.roomId, newSmall, newLarge)
         //   let newString = {
         //     'dataTile00': newLarge,
         //     'dataTile01': newSmall
         //   }
         //   socket.emit(sceneUpdate, newString);
         //   props.setTileListSmall(newSmall)
         // }).catch((error) => {
         //   error.then((e) => {
         //     console.error(e)
         //     if(e.horusGov !== undefined){
         //       queue.notify({
         //         title: `Sorry, you\'ve reached the Co-browse session maximum (${e.horusGov}).`
         //       })
         //     } else {
         //       queue.notify({
         //         title: `Unable to create Co-browse session.`
         //       })
         //     }
         //   })
         // })
         handleClose()
      }

   }

   const handleUrlSubmitVNC = () => {
      setVncLoading(true)
      let url = inputRef?.current?.value
      console.log(isValidUrl(url))
      if (isValidUrl(url)) {
         console.log("Input accepted!")
         console.log(url)
         const id = uuidv4()
         dispatch(assignVncLoading({
            id: id,
            done: false,
            initTime: new Date().getTime(),
            progress: 0,
         }))

         getVNCAsset(params.roomId, url).then((res) => {
            let newSmall = props.tileListSmall
            let newLarge = props.tileListLarge
            newSmall.unshift({
               "_id": id,
               "fileName": "vnc",
               "title": "Data Tile",
               "src": res.cobrowseSession.url
            })
            updateTiles(params.roomId, newSmall, newLarge)
            let newString = {
               'dataTile00': newLarge,
               'dataTile01': newSmall
            }
            socket.emit(sceneUpdate, newString);
            props.setTileListSmall(newSmall)
         }).catch((error) => {
            error.then((e) => {
               console.error(e)
               if (e.horusGov !== undefined) {
                  queue.notify({
                     title: `Sorry, you\'ve reached the Co-browse session maximum (${e.horusGov}).`
                  })
               } else {
                  queue.notify({
                     title: `Unable to create Co-browse session.`
                  })
               }
            })
         })
         handleClose2()
      }
      console.log("Input Failed")

   }

   const handleAddTile = () => {
      const tileType = modalState.tileType;
      if (tileType === 'iframe') {
         handleUrlSubmitIframe();
         return;
      }
      handleUrlSubmitVNC();
   }

   const goBackHandler = () => {
      dispatch(goBackModalMode())
   }

   return (
      <>
         <Dialog preventOutsideDismiss={false}
            open={props.uploadAssetOpen}
            onClose={handleClose2}
            className="uploadAsset"
            style={{ paddingRight: props.tileListSmall.length > 3 ? '2rem' : '1rem' }}>
            <div
               className="flex-container-row center full-width headerPadding" style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  alignContent: 'center',
                  marginBottom: '20px',
                  height: '100%',
                  margin: 'auto',
                  fontWeight: 'bold',
               }}
            >
               {
                  (modalState.mode === 'default' || !modalState.mode)
                     ? ''
                     : <button className="goback btn" onClick={goBackHandler}>
                        <span className="material-icons">arrow_back</span>
                     </button>
               }
               <div
                  className="add-asset-title"
                  style={{
                     display: 'flex',
                     justifyContent: 'center',
                     alignItems: 'center',
                     fontSize: '20px',
                     height: '100%',
                     margin: 'auto',
                     marginBottom: '5px',
                  }}>
                  {
                     modalState.mode === 'url'
                        ? 'Input URL'
                        : modalState.mode === 'webapp'
                           ? 'Add Widget'
                           : 'Add Asset'
                  }
               </div>
               <button className="tileClose btn" onClick={handleClose}>
                  <span className="material-icons">clear</span>
               </button>
            </div>
            {
               (modalState.mode === 'webapp')
                  ? (
                     <div
                        className="flex-container-column uploadAssetActions"
                     >
                        <div>
                           <ShareControl />
                        </div>
                        <div>
                           <SingleControl />
                        </div>
                     </div>
                  )
                  : (modalState.mode === 'url')
                     ? (
                        <div
                           className="flex-container-column uploadAssetActions"
                        >
                           <div>
                              <div className="flex-container-row">
                                 <input
                                    type="url"
                                    placeholder="Add WebApp URL..."
                                    ref={inputRef}
                                    className="full-width default-input">
                                 </input>
                                 <Button
                                    onClick={handleAddTile}
                                    className="cobrowse-button primePillBtn"
                                    icon={'add'}
                                 />
                              </div>
                           </div>
                        </div>
                     )
                     : (
                        <div className="flex-container-column uploadAssetActions">

                           <div
                              className="flex-container-row flex-around full-width"
                           >
                              <UploadFile tileListSmall={props.tileListSmall} tileListLarge={props.tileListLarge} setUploadAssetOpen={props.setUploadAssetOpen} />
                           </div>
                           <div>
                              <AddWebApp />
                           </div>
                           <div>
                              <CreateRDP setOpen={setRDPOpen} />
                           </div>
                        </div>
                     )
            }

         </Dialog>
         <RDPModal tileListSmall={props.tileListSmall} tileListLarge={props.tileListLarge} open={rdpOpen} setOpen={setRDPOpen} />
      </>
   )
}