import { Button } from "@rmwc/button";
import { show as updateRDPShow } from "app/rdp";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from '@rmwc/tooltip';

export default function CreateRDP(props) {
   const dispatch = useDispatch();
   const available = useSelector(state => state.rdp.available);

   const handleRDPClick = () => {
      // props.setOpen(true)
      dispatch(updateRDPShow(true));
   }

   return (
      <>
         {/* <Button onClick={handleRDPClick} className="primePillBtn" icon="add">
        Add RDP Session
      </Button> */}
         {/* <Tooltip content={'sdf'}> */}
         <Button
            onClick={handleRDPClick}
            style={{
               width: '100%',
               border: 'thin solid grey',
               display: 'flex',
               flexDirection: 'row',
               alignItems: 'center',
               justifyContent: 'center',
               textAlign: 'center',
               margin: 'auto',
               marginBottom: '15px'
            }}
         // disabled={!available} // !!ENABLE LATER!
         >
            <div
               style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                  fontSize: '15px',
                  margin: 'auto',
               }}
            >
               <span
                  style={{
                     marginRight: '10px'
                  }}
               >ADD RDP</span>
               <span className="material-icons">
                  computer
               </span>
            </div>

         </Button>
         {/* </Tooltip> */}
      </>
   )
}