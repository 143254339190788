import { useSelector } from "react-redux"

export default function SlackMessagingContent(props){
  const messages = useSelector(state => state.messages.slack)
  const slackMessenger = useSelector(state => state.slack.slackMessenger)
  const currentConvo = messages[slackMessenger.channel]

  if(currentConvo === undefined){
    return(
      <>
      </>
    )
  } else {
    return(
      <>
        <div className="slackConversation">
          {
            currentConvo.map((message) => {
              return(
                <>
                  <div className="conversationMessage flex-container-row">
                    <img src={message.pfp} />
                    <div className="flex-container-column messageContent">
                      <div className="flex-container-row">
                        <p className="messageName"><strong>{message.name}</strong></p>
                        <p className="messageTimestamp">{message.timestamp}</p>
                      </div>
                      <p className="messageText">{message.text}</p>
                    </div>
                  </div>
                </>
              )
            })
          }
        </div>
      </>
    )
  }
}