import { Button } from '@rmwc/button';
import { Tooltip } from '@rmwc/tooltip';
import { muteVideoPreview } from 'js/webex-controls';
export default function PreviewMuteVideoButton(props){

  const handleClick = () => {
    props.setVideoPreMuted(!props.videoPreMuted);
    muteVideoPreview(!props.videoPreMuted);
  }

  let toggleColor = {
    color: 'red'
  };

  return(
    <Tooltip content={props.videoPreMuted ? 'Turn Camera On' : 'Turn Camera Off'}>
      <Button
        onClick={handleClick}
        style={props.videoPreMuted ? toggleColor : {}}
        className="btnLeft"
        icon={props.videoPreMuted ? 'videocam_off' : 'videocam'}>
      </Button>
    </Tooltip>
  );
}