import { addElementMedia } from 'js/webex-controls';
import { useContext, useEffect, useLayoutEffect, useState, Component, PureComponent } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { SocketContext } from 'context/socket';
import { useSelector } from 'react-redux';
import ADataTile from 'components/dataTiles/DataTileNew';
import Breadcrumbs from 'aesthetic/Breadcrumbs';
import './TileList.css'

export default function TilePanelSmall(props) {
   const socket = useContext(SocketContext)
   const sceneUpdate = 'scene-string'
   const view = useSelector(state => state.view)
   const [list, setList] = useState(props.tileList)

   /**
    * I want to keep the styles for draggable and droppable
    * in the same place but I need to add dynamic variables
    * after the fact
    */
   const getBgStyle = () => {
      return {
         'overflowY': `${list.length > 3 || view.level !== 'all' ? 'scroll' : 'hidden'}`
      }
   }

   const getDroppableStyle = () => {
      return {
         // we need this for when a user drags a tile so
         // there is still a visible gap where the tile once was
         "minHeight": `${window.innerHeight * 0.25}px`
      }
   }

   // Updated this tile list logic to test if a list was updated and not updating state unless
   // the list has actually changed.
   useEffect(() => {
      socket.on(sceneUpdate, (message) => {
         if (message.message.dataTile01 && JSON.stringify(message.message.dataTile01) === JSON.stringify(props.tileList)) {
            props.setTileList(props.tileList)
         }
         else {
            props.setTileList(message.message.dataTile01)
         }
      })
   }, [])

   useEffect(() => {
      if (view.level !== 'all') {
         for (let i = 0; i < props.tileList.length; i++) {
            if (props.tileList[i]._id === view.level) {
               setList(props.tileList[i].files)
            }
         }
      } else {
         setList(props.tileList)
      }
   }, [view.level, props.tileList])

   useLayoutEffect(() => {
      addElementMedia(props.sharing)
   }, [props.tileList])

   return (
      <>
         <div className="panelBG" style={getBgStyle()}>
            <Breadcrumbs tileList={props.tileList} />
            <div className="inner-panel-div">
               <Droppable
                  droppableId={`small-tile-list`}
                  key={`small-tile-list`}
                  isCombineEnabled={view.level === 'all' ? true : false}
               >
                  {(provided, snapshot) => (
                     <div className="tileListWrapper"
                        ref={provided.innerRef}
                        style={getDroppableStyle(snapshot)}
                        {...provided.droppableProps}>
                        {
                           list.map((item, index) => {
                              return (
                                 <div className="tileWrapper small-tile-wrapper">
                                    <ADataTile
                                       id={item._id}
                                       key={item._id}
                                       item={item}
                                       index={index}
                                       videoSize={props.videoSize}
                                       accessLevel={props.accessLevel}
                                       pdfTracker={props.pdfTracker}
                                       large={false}
                                    />
                                    <span style={{ display: 'none' }}>
                                       {provided.placeholder}
                                    </span>
                                 </div>
                              )
                           })
                        }
                     </div>
                  )}
               </Droppable>
            </div>
         </div>
      </>
   )
}
