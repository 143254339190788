import { useParams } from "react-router"

export default function RedirectToPreview(props){
  const params = useParams()

  window.location.href = `${window.location.origin}/r/${params.roomId}`
  return(
    <>
    </>
  )
}